import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, IconButton, Menu, MenuItem, Popover, TextField } from '@mui/material'
import { TextField as MuiTextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import ImageIcon from '@mui/icons-material/Image';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

import Spacer from 'src/common/components/Spacer'

import { styled } from '@mui/material/styles'

import 'react-quill/dist/quill.snow.css'
import './style.css'
// import Editor from './editor'
import AttachIcon from 'src/common/icons/attach'
import RadioSelect from 'src/common/components/RadioSelect'
import templatesService from 'src/services/templates.service'
import { pdfAttach, xlsAttach } from './constants'
import DeleteIcon from 'src/common/icons/deleteIcon'
// import SignatureIcon from 'src/common/icons/signature'
import {
  BtnBold, BtnItalic, Editor, EditorProvider, Toolbar, useEditorState, EditorContext,
  BtnBulletList,
  BtnClearFormatting,
  BtnStrikeThrough,
  BtnStyles,
  createButton,
  createDropdown,
} from 'react-simple-wysiwyg'
import { parseJwt } from 'src/heplers'
import companyService from 'src/services/company.service'
import useFileUpload from 'react-use-file-upload'
import { QrCode } from '@mui/icons-material';

const SendIcon = ({ disabled }) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path opacity="0.2" d="M19.7099 3.3641L2.24172 8.29104C2.09476 8.33249 1.96396 8.4178 1.86679 8.53558C1.76962 8.65336 1.71071 8.79799 1.69794 8.95015C1.68517 9.1023 1.71915 9.25473 1.79532 9.38706C1.8715 9.51939 1.98626 9.62531 2.12425 9.69068L10.1503 13.4925C10.3068 13.5666 10.4329 13.6927 10.507 13.8492L14.3088 21.8752C14.3742 22.0132 14.4801 22.128 14.6124 22.2041C14.7447 22.2803 14.8972 22.3143 15.0493 22.3015C15.2015 22.2888 15.3461 22.2299 15.4639 22.1327C15.5817 22.0355 15.667 21.9047 15.7084 21.7578L20.6354 4.28953C20.6716 4.16122 20.6729 4.02558 20.6392 3.89658C20.6056 3.76758 20.5381 3.64988 20.4439 3.55561C20.3496 3.46134 20.2319 3.3939 20.1029 3.36024C19.9739 3.32658 19.8383 3.32791 19.7099 3.3641Z" fill={disabled ? '#aaa' : "#6123FF"} />
  <path d="M19.7099 3.3641L2.24172 8.29104C2.09476 8.33249 1.96396 8.4178 1.86679 8.53558C1.76962 8.65336 1.71071 8.79799 1.69794 8.95015C1.68517 9.1023 1.71915 9.25473 1.79532 9.38706C1.8715 9.51939 1.98626 9.62531 2.12425 9.69068L10.1503 13.4925C10.3068 13.5666 10.4329 13.6927 10.507 13.8492L14.3088 21.8752C14.3742 22.0132 14.4801 22.128 14.6124 22.2041C14.7447 22.2803 14.8972 22.3143 15.0493 22.3015C15.2015 22.2888 15.3461 22.2299 15.4639 22.1327C15.5817 22.0355 15.667 21.9047 15.7084 21.7578L20.6354 4.28953C20.6716 4.16122 20.6729 4.02558 20.6392 3.89658C20.6056 3.76758 20.5381 3.64988 20.4439 3.55561C20.3496 3.46134 20.2319 3.3939 20.1029 3.36024C19.9739 3.32658 19.8383 3.32791 19.7099 3.3641Z" stroke={disabled ? '#aaa' : "#6123FF"} strokeLinecap="round" strokeLinejoin="round" />
  <path d="M10.3906 13.602L14.6333 9.35938" stroke={disabled ? '#aaa' : "#6123FF"} strokeLinecap="round" strokeLinejoin="round" />
</svg>


const Input = styled(TextField)(() => ({
  border: '1px solid #E7E7EE',
  padding: '10px 16px',
  fontSize: '16px',
  '& .MuiFormLabel-root': {
    fontSize: '16px',
    left: '16px',
    top: '4px',
  },
  '& .MuiFormLabel-root.MuiFormLabel-filled': {
    top: '10px',
    fontSize: '14px',
  },
  '& .MuiFormLabel-root.Mui-focused': {
    fontSize: '14px',
    color: '#76767F',
    top: '10px',
    // left: '16px',
  },
  '& .MuiInput-root:before': {
    display: 'none'
  },
  '& .MuiInput-root:after': {
    display: 'none'
  },
}))

const SUGGESTIONS = [
  'New policy document alert! Please read and share your feedback. Your insights are invaluable.',
  'Where should our next event be? Your vote decides! Click to choose.',
  'A big surprise is coming from our CEO! Click to add the reveal to your calendar. Don’t miss out!',
  'Win Tickets to the Olympic Games in Paris! Your chance to witness the action live. Stay tuned for how to enter.',
  'Your opinion matters! Please fill out our anonymous survey to help improve our workplace.',
]

export const BtnVars = createDropdown('Variables', [
  ['First name', 'insertText', '[firstName]'],
  ['Last name', 'insertText', '[lastName]'],
  ['Email', 'insertText', '[email]'],
  ['Company short name', 'insertText', '[companyShortName]'],
  ['Company name', 'insertText', '[companyName]'],
]);

export const BtnLinkCustom = createButton('Phishing Link', '🔗', ({ $selection }) => {
  if ($selection?.nodeName === 'A') {
    document.execCommand('unlink');
  } else {
    // eslint-disable-next-line no-alert
    document.execCommand('createLink', false, '[phishingLink]');
  }
});


const ButtonWrapper = (props) => {
  const editorState = useEditorState();
  return !editorState.htmlMode ? props.children : null
}

const ModeSwicher = ({ ...rest }) => {
  const editorState = useEditorState();
  const { update } = useContext(EditorContext)

  // console.log(editorContext);

  function onClick() {
    editorState.update({
      htmlMode: !editorState.htmlMode,
    });
  }

  return (
    <button
      type="button"
      title="HTML mode"
      className="rsw-btn"
      onClick={onClick}
      data-active={editorState.htmlMode}
      {...rest}
    >
      &lt;/&gt;
    </button>
  );
}



const TemplateCreate = ({ onCreate, selectedTemplateId, onClose, totalTemplates, isSms = false }) => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [name, setName] = useState('')
  const [nameOld, setNameOld] = useState('')
  const [sender, setSender] = useState('')
  const [senderMail, setSenderMail] = useState('')
  const [subject, setSubject] = useState('')
  const [value, setValue] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [domain, setDomain] = useState(null);
  const [phone, setPhone] = useState(null);
  const [domains, setDomains] = useState([]);
  const [templateChanged, setTemplateChanged] = useState(false);
  const [templateSent, setTemplateSent] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElVars, setAnchorElVars] = useState(null)
  const [imgUrl, setImgUrl] = useState('')
  const [imgWidth, setImgWidth] = useState(300)

  const nameRef = useRef(null)

  const textEncoder = new TextEncoder();

  const {
    files,
    // handleDragDropEvent,
    setFiles,
    clearAllFiles,
  } = useFileUpload();


  const addImage = () => {
    setAnchorEl(null)
    // const range = document.createRange()
    document.querySelector('.rsw-ce').focus()
    setTimeout(() => {
      if (imgUrl) {
        document.execCommand('insertHTML', false, '<img width="' + imgWidth + 'px" src=' + imgUrl + ' alt="image" />')
        setImgUrl('')
      } else {
        let file = files[0];
        let reader = new FileReader();
        reader.onload = (e) => {
          // console.log(e.target.result)
          document.execCommand('insertHTML', false, '<img width="' + imgWidth + 'px" src=' + e.target.result + ' alt="image" />')
          clearAllFiles()
        }
        reader.readAsDataURL(file);
      }

    }, 100);



  }
  const addQr = () => {
    document.querySelector('.rsw-ce').focus()
    setTimeout(() => {
      document.execCommand('insertHTML', false, '<img width="60px" alt="image" src="/images/qr-in-template.jpg"/>')
    }, 100);
  }

  useEffect(() => {
    setLoading(true)

    if (selectedTemplateId) {
      templatesService.getCustomTemplateById(selectedTemplateId).then(resp => {
        setName(resp.data.name);
        setNameOld(resp.data.name);
        setSender(resp.data.sender);
        setPhone(resp.data.sender);
        setSenderMail(resp.data.emailPrefix);
        setDomain(resp.data.emailDomain);
        setSubject(resp.data.subject);

        setValue(isSms ? resp.data.body : parseEmailBody(resp.data.body))
        if (resp.data.body.includes('data-filename="')) {
          setAttachments(parseEmailFile(resp.data.body))
        }
      }).catch(err => {
        console.error(err);
      })
    }

    setName((isSms ? 'SMS ' : '') + 'Template #' + (parseInt(totalTemplates) + 1))
    setNameOld((isSms ? 'SMS ' : '') + 'Template #' + (parseInt(totalTemplates) + 1))

    templatesService.getCustomTemplatesDomains().then(resp => {
      setLoading(false)
      setDomains(resp.data)
    }).catch(err => {
      console.error(err);
      setLoading(false)
    })

    nameRef.current.focus()
  }, [])

  const generateFilesChunk = () => {
    return attachments.map(el => el.type == 'pdf' ? pdfAttach(el.name) : xlsAttach(el.name)).join('')
  }

  const generateEmailBody = () => {
    return `<html>
    <head>
    <style>p {margin: 0}</style>
    </head>
    <body>
    <table cellSpacing="0" cellPadding="0" border="0" >${attachments.length > 0 ? ('<tr style="margin-bottom: 16px; display: table"><td>' + generateFilesChunk() + '</td></tr>') : ''}<tr><td class='tmplt-body'>${value}</td></tr></table></body></html>`
  }

  const parseEmailBody = (body) => {
    const lastElementIndex = body[body.length - 2] == 'e' ? -18 : -32
    return body.split(`tmplt-body'>`)[1].slice(0, lastElementIndex).replaceAll('alt="image" src="cid:[qrCodeImage]"', 'width="60px" alt="image" src="https://admin.cyberlift.io/images/qr-in-template.jpg"')
  }

  const parseEmailFile = (body) => {
    const filename = body.split('data-filename="')[1].split('.end_file_name')[0]
    return [{ id: filename.split('.')[0], name: filename.split('.')[0], type: filename.split('.')[1] }]
  }

  const handleSave = (sms = false) => {
    const valueBytesSize = textEncoder.encode(value).length
    if (sms) {
      if (name && domain && value && valueBytesSize < 141 && value.includes('[phishingLink]')) {
        const template = {
          name: name,
          sender: phone,
          emailPrefix: 'SIMULATION_SMS',
          emailDomain: domain,
          subject: 'SIMULATION_SMS',
          body: value
        }

        if (selectedTemplateId) {
          templatesService.updateCustomTemplateById(selectedTemplateId, template).then((resp) => {
            onCreate(null, resp.data)
          }).catch((err) => {
            console.error(err);
          })
        } else {
          templatesService.addCustomTemplate(template).then((resp) => {
            onCreate(resp.data)
          }).catch((err) => {
            console.error(err);
          })
        }
      } else {
        setErrors({
          ...(!name && { name: 'Name is required' }),
          ...(!phone && { phone: 'Sender phone is required' }),
          ...(!domain && { domain: 'Domain is required' }),
          ...(!value && { value: 'SMS text is required' }),
          ...(valueBytesSize > 140 && { value: 'A single SMS message supports up to 140 characters' }), //deprecated
          ...(!value.includes('[phishingLink]') && { value: 'Please add a phishing link' }),
        })
      }

      return;
    }
    const requestBody = {
      name: name,
      sender: sender,
      emailPrefix: senderMail,
      emailDomain: domain,
      subject: subject,
      body: generateEmailBody()
        .replaceAll('https://admin.cyberlift.io/images/qr-in-template.jpg', 'cid:[qrCodeImage]')
        .replaceAll('https://admin.cyberlift.ai/images/qr-in-template.jpg', 'cid:[qrCodeImage]')
        .replaceAll('/images/qr-in-template.jpg', 'cid:[qrCodeImage]')
        .replaceAll('width="60px" alt=', 'alt='),
    }

    const hasPhishingLink = (value.includes('[phishingLink]') || attachments.length !== 0 || value.includes('qr-in-template.jpg'))

    if (name && sender && senderMail && domain && subject && value && hasPhishingLink) {
      if (selectedTemplateId) {
        templatesService.updateCustomTemplateById(selectedTemplateId, requestBody).then((resp) => {
          onCreate(null, resp.data)
        }).catch((err) => {
          console.error(err);
        })
      } else {
        templatesService.addCustomTemplate(requestBody).then((resp) => {
          onCreate(resp.data)
        }).catch((err) => {
          console.error(err);
        })
      }
    } else {
      setErrors({
        ...(!name && { name: 'Name is required' }),
        ...(!sender && { sender: 'Sender is required' }),
        ...(!senderMail && { senderMail: 'Email is required' }),
        ...(!domain && { domain: 'Domain is required' }),
        ...(!subject && { subject: 'Subject is required' }),
        ...(!value && { value: 'Body is required' }),
        ...(!hasPhishingLink && { phishingLink: 'Please add at least one phishing QR code, phishing link, or phishing file.' }),
      })
    }
  }

  const handleSendToMe = () => {
    if (templateChanged) {
      const requestBody = {
        name: name,
        sender: sender,
        emailPrefix: senderMail,
        emailDomain: domain,
        subject: subject,
        body: generateEmailBody(),
      }
      templatesService.sendToMeDraft(requestBody).then(() => {
        setTemplateSent(true)
        setTimeout(() => {
          setTemplateSent(false)
        }, 3000);
      })
    } else {
      templatesService.sendToMeCustomTemplate(selectedTemplateId).then(() => {
        setTemplateSent(true)
        setTimeout(() => {
          setTemplateSent(false)
        }, 3000);
      })

    }
  }


  const [showAIDraftForm, setShowAIDraftForm] = useState(false)
  const [aIDraftFormText, setAIDraftFormText] = useState('')
  const [fetching, setFetching] = useState(false)
  const [company, setCompany] = useState(null)

  const showAddImage = Boolean(anchorEl);
  const showAddVariable = Boolean(anchorElVars);
  // const id = open ? 'simple-popover' : undefined;

  const imgButtonRef = useRef(null)
  const inputRef = useRef();



  useEffect(() => {
    const userLocal = JSON.parse(
      localStorage.getItem('user')
    )
    const user = parseJwt(userLocal.accessToken)
    companyService.getCompany(user.companyId).then(resp => {
      setCompany(resp.data)
    })
  }, [])

  const shortCodes = [
    'firstName',
    'lastName',
    'email',
    'companyShortName',
    'companyName',
  ]

  const transformDraft = (text) => {
    var regex = /\[(.*?)\]/g;

    var transformedString = text.replaceAll(regex, function (match, p1) {
      if (shortCodes.includes(p1)) {
        return match;
      } else {
        return '<a href="[phishingLink]" target="_blank">' + p1 + '</a>';
      }
    });

    return transformedString;
  }

  const handleSendPrompt = () => {
    setFetching(true);
    templatesService.sendAiDraftPrompt(aIDraftFormText, company?.language).then((resp) => {
      setFetching(false);
      setShowAIDraftForm(false);
      const newString = resp.data.content || ''
      setValue(transformDraft(newString).replaceAll(/\n/g, ' <br/> '))
      setSubject(resp.data.subject)
      // onGetAiResponse(transformDraft(newString).replaceAll(/\n/g, ' <br/> '), resp.data.subject)
    }).catch(err => {
      setFetching(false);
      // setErrorMessage('Something went wrong')
      console.error(err);
    })
  }


  const onChange = (e) => {
    const valueBytesSize = textEncoder.encode(e.target.value).length

    if (isSms) {
      if (valueBytesSize < 141) {
        setValue(e.target.value)
      }
    } else {
      setValue(e.target.value)
    }

  }

  const addFile = () => {
    setAttachments([...attachments, { id: attachments.length + 1, name: 'filename', type: 'pdf' }])
    setTemplateChanged(true)
    setErrors({ ...errors, phishingLink: '' })
  }



  const formInvalid = (!name || !sender || !senderMail || !domain || !subject || !value)

  const openVars = (event) => {
    setAnchorElVars(event.currentTarget);
  }

  const insetText = (text) => {
    document.querySelector('.rsw-ce').focus()
    setTimeout(() => {
      document.execCommand('insertHTML', false, text)
    }, 100);

  }

  return (
    <div style={{ height: '100%', overflowY: 'auto', width: '100%' }}>
      <div className='column' style={{ padding: '32px', height: '100%' }}>
        <div className='row row-center'>
          <p
            ref={nameRef}
            contentEditable="true"
            style={{ outline: '0' }}
            className="drawer-title"
            onInput={e => {
              setName(e.currentTarget.textContent)
            }}
          >{nameOld}</p>
          {/* <p contentEditable="true" className="drawer-title">{selectedTemplateId ? 'Edit' : 'Create a'} template</p> */}
          <Spacer />
          {templateSent ?
            <div style={{ color: '#00BC62', marginTop: 10 }} className='row row-center'>
              <svg style={{ marginRight: 10 }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.5 4.5L6.5 11.4997L3 8" stroke="#00BC62" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>

              <p>Template sent</p>

            </div>
            :
            (!isSms && <Button
              onClick={handleSendToMe}
              sx={{ color: '#6123FF', textTransform: 'none', fontFamily: 'Plus Jakarta Sans', marginRight: '20px' }}
              startIcon={<SendIcon disabled={formInvalid} />}
              disabled={formInvalid}
            >
              Send to me
            </Button>)
          }
          <IconButton onClick={onClose}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.75 5.25L5.25 18.75" stroke="#1B1B26" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M18.75 18.75L5.25 5.25" stroke="#1B1B26" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

          </IconButton>
        </div>

        {/* <div style={{ width: '100%', marginBottom: 20 }}>
          <TextField
            label='Template Name'
            variant='standard'
            fullWidth
            error={errors?.name}
            value={name || ''}
            onChange={(e) => {
              setName(e.target.value)
              setTemplateChanged(true)
              setErrors({ ...errors, name: '' })
            }}
            sx={{
              backgroundColor: '#fff'
            }}
          />
          {errors?.name && <p className="error-text">{errors?.name}</p>}
        </div> */}

        {isSms ?
          <div className='row' style={{ border: '1px solid #ddd', borderRadius: '20px', marginBottom: '20px', position: 'relative' }}>

            <RadioSelect style={{
              color: errors?.phone && '#B40020',
              backgroundColor: '#fff',
              border: 0,
              marginRight: '16px',
              marginLeft: '16px',
              height: '48px',
              width: 'calc(50% - 10px)'
            }}
              left={true}
              placeholder={'Select Sender number'}
              lowerCase={true}
              value={phone}
              options={['+12566266422', '+48571937206', '+48787412430']}
              onSelectOption={(opt) => {
                setPhone(opt)
                setTemplateChanged(true)
                setErrors({ ...errors, phone: '' })
              }}
            />
            <div style={{ height: '32px', background: '#bebebe', width: '1px', marginTop: '10px' }}></div>
            <RadioSelect style={{
              color: errors?.domain && '#B40020',
              // position: 'absolute',
              // top: 6,
              // right: 0,
              width: 'calc(50% - 10px)',
              backgroundColor: '#fff',
              border: 0,
              marginRight: '16px',
              marginLeft: '16px',
              height: '48px'
            }}
              prefix="@"
              placeholder={'Select Domain'}
              lowerCase={true}
              value={domain}
              options={domains}
              onSelectOption={(opt) => {
                setDomain(opt)
                setTemplateChanged(true)
                setErrors({ ...errors, domain: '' })
              }}
            />
          </div> :
          <>
            <div style={{ width: '100%', position: 'relative' }}>
              <Input
                label='Sender Name  (ex. John Doe)'
                variant='standard'
                fullWidth
                error={errors?.sender}
                value={sender || ''}
                onChange={(e) => {
                  setSender(e.target.value)
                  setTemplateChanged(true)
                  setErrors({ ...errors, sender: '' })
                }}
                sx={{
                  borderRadius: '20px 20px 0px 0px',
                  mb: '-1px',
                  backgroundColor: '#fff',
                  padding: '5px 16px',
                  '.MuiFormLabel-root': {
                    top: 0
                  },
                  ...(errors.sender && { border: '1px solid #d32f2f', zIndex: 2000 })
                }}
              />
            </div>

            <div style={{ width: '100%', position: 'relative' }}>
              <Input
                label='Sender Email Name (ex. john.doe)'
                variant='standard'
                fullWidth
                error={errors?.senderMail}
                value={senderMail || ''}
                onChange={(e) => {
                  setSenderMail(e.target.value.replaceAll(' ', '').replace('@', ''))
                  setTemplateChanged(true)
                  setErrors({ ...errors, senderMail: '' })
                }}
                sx={{
                  borderRadius: '0px',
                  mb: '-1px',
                  backgroundColor: '#fff',
                  padding: '5px 16px',
                  '.MuiFormLabel-root': {
                    top: 0
                  },
                  ...(errors.senderMail && { border: '1px solid #d32f2f', zIndex: 2000 })

                }}
              />


              <RadioSelect style={{
                color: errors?.domain && '#B40020',
                position: 'absolute',
                top: 6,
                right: 0,
                width: '300px',
                backgroundColor: '#fff',
                border: 0,
                marginRight: '16px',
                marginLeft: '16px',
                height: '48px'
              }}
                prefix="@"
                placeholder={'Select Domain'}
                lowerCase={true}
                value={domain}
                options={domains}
                onSelectOption={(opt) => {
                  setDomain(opt)
                  setTemplateChanged(true)
                  setErrors({ ...errors, domain: '' })
                }}
              />
            </div>
            <div style={{ width: '100%', marginBottom: 20 }}>
              <Input
                label='Subject'
                variant='standard'
                fullWidth
                error={errors?.subject}
                value={subject || ''}
                onChange={(e) => {
                  setSubject(e.target.value)
                  setTemplateChanged(true)
                  setErrors({ ...errors, subject: '' })
                }}
                sx={{
                  borderRadius: '0px 0px 20px 20px',
                  mb: '-1px',
                  backgroundColor: '#fff',
                  padding: '5px 16px',
                  '.MuiFormLabel-root': {
                    top: 0
                  },
                  ...(errors.subject && { border: '1px solid #d32f2f', zIndex: 2000 })
                }}
              />
            </div>
          </>}

        <div style={{ ...(errors.value && { border: '1px solid #d32f2f', borderRadius: '20px' }) }}>
          {isSms ? (
            <>
              <EditorProvider>
                <div style={{ padding: '20px', background: '#f5f5f5', border: '1px solid #ddd', borderRadius: '20px 20px 0px 0px', display: 'flex' }}>
                  {/* <BtnVars /> */}
                  <p style={{ cursor: 'pointer' }} onClick={openVars}>Variables
                    <svg style={{ margin: '0 10px', transform: showAddVariable ? 'rotate(180deg)' : 'rotate(0deg)', transition: '300ms' }} width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.5 1L9 8.5L16.5 1" stroke="#666" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </p>
                  <Menu
                    sx={{
                      '& .MuiPaper-root': {
                        color: '#1B1B26',
                        borderRadius: '12px'
                      },
                      '& li': {
                        opacity: '0.7',
                        fontFamily: 'Plus Jakarta Sans',
                      },
                      '& li:hover': {
                        opacity: '1',
                      },
                    }}
                    anchorEl={anchorElVars}
                    size={'sm'}
                    open={showAddVariable}
                    onClose={() => setAnchorElVars(null)}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    <MenuItem onClick={() => {
                      setAnchorElVars(null)
                      insetText('[firstName]')
                    }}>
                      First name
                    </MenuItem>
                    <MenuItem onClick={() => {
                      setAnchorElVars(null)
                      insetText('[lastName]')
                    }}>
                      Last name
                    </MenuItem>
                    <MenuItem onClick={() => {
                      setAnchorElVars(null)
                      insetText('[email]')
                    }}>
                      Email
                    </MenuItem>
                    <MenuItem onClick={() => {
                      setAnchorElVars(null)
                      insetText('[companyShortName]')
                    }}>
                      Company short name
                    </MenuItem>
                    <MenuItem onClick={() => {
                      setAnchorElVars(null)
                      insetText('[companyName]')
                    }}>
                      Company name
                    </MenuItem>
                    <MenuItem onClick={() => {
                      setAnchorElVars(null)
                      insetText('[phishingLink]')
                    }}>
                      Phishing link
                    </MenuItem>

                  </Menu>
                </div>
                <textarea className={'rsw-ce'} value={value} onChange={onChange} style={{ font: 'inherit', width: '100%', height: '300px', border: '1px solid #E7E7EE', borderRadius: '0px 0px 20px 20px', padding: '20px', boxSizing: 'border-box', outline: 'none' }} cols="30" rows="10"></textarea>
              </EditorProvider>
            </>
          ) : (
            <EditorProvider>
              <Editor style={{ background: '#fff' }} className='editorr' value={value} onChange={onChange}>
                <Toolbar>
                  <BtnStyles />
                  <BtnBold />
                  <BtnItalic />
                  <BtnLinkCustom onClick={() => { setValue(value.replace('>[phishingLink]<', '>Link text<')) }} />
                  <BtnBulletList />
                  <BtnClearFormatting />
                  <BtnStrikeThrough />
                  <BtnVars />
                  <ButtonWrapper>
                    <IconButton ref={imgButtonRef} aria-describedby={'add-image'} title='Add image' onClick={(event) => {
                      // console.log(window.getSelection());
                      setAnchorEl(event.currentTarget)
                    }}>
                      <ImageIcon />
                    </IconButton>
                  </ButtonWrapper>
                  <ButtonWrapper>
                    <IconButton title='Add Phishing QR code' onClick={() => {
                      addQr()
                    }}>
                      <QrCode />
                    </IconButton>
                  </ButtonWrapper>
                  <ButtonWrapper>
                    <Button sx={{ color: '#222', textTransform: 'none', }} title='Draft with AI' onClick={() => setShowAIDraftForm(true)} startIcon={<AutoFixHighIcon />} >AI</Button>
                  </ButtonWrapper>
                  <ModeSwicher />

                  <Popover
                    id='kek'
                    open={showAddImage}
                    onClose={() => setAnchorEl(null)}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <div style={{ padding: '20px 20px', width: '350px' }} className='column column-center'>
                      <input
                        ref={inputRef}
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        style={{ display: 'none' }}
                        onChange={(e) => {
                          clearAllFiles()
                          setFiles(e, 'a');
                          inputRef.current.value = null;
                        }}
                      />

                      <p style={{ marginBottom: 10 }}>Choose add image options</p>
                      <div style={{ width: '100%', position: 'relative' }} className="row">
                        <MuiTextField fullWidth disabled={files.length} placeholder='image URL' value={imgUrl} onChange={(e) => setImgUrl(e.target.value)} />
                        {imgUrl &&
                          <div style={{ position: 'absolute', top: '15px', right: '20px', cursor: 'pointer' }} onClick={() => setImgUrl('')}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M18.75 5.25L5.25 18.75" stroke="#1B1B26" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M18.75 18.75L5.25 5.25" stroke="#1B1B26" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </div>

                        }
                      </div>

                      {/* <div className='row row-center' style={{ marginTop: '20px', width: '100%' }}>
                      <div style={{ flex: 1, height: 1, background: '#E7E7EE' }}></div>
                      <p style={{ color: '#76767F', fontSize: '14px', margin: '0px 8px' }}>Or</p>
                      <div style={{ flex: 1, height: 1, background: '#E7E7EE' }}></div>
                    </div> */}

                      <div className='column column-center' style={{ position: 'relative', marginBottom: 20 }}>
                        {/* <div className='row row-center'>
                        {imgUrl
                          ? <p style={{ fontWeight: 600, marginTop: 14, marginBottom: 23, color: '#aeaeae' }}>Upload from PC</p>
                          : <p className='upload-link text-shrink-1' style={{ marginBottom: 20, maxWidth: 270 }}><span onClick={() => inputRef.current.click()}>{files.length ? files[0].name : 'Upload from PC'}</span></p>
                        }
                        {!!files.length && <IconButton onClick={() => clearAllFiles()}>
                          <DeleteIcon />
                        </IconButton>}
                      </div> */}
                        <div className='row row-center' style={{ marginTop: 20 }}>
                          <p style={{ marginRight: 10 }}>Width (px):</p>
                          <MuiTextField placeholder='image width' type='number' value={imgWidth} onChange={(e) => setImgWidth(e.target.value)} />
                        </div>

                      </div>
                      <div className='row'>
                        <Spacer />
                        <Button variant={'contained'}
                          sx={{
                            padding: '6px 25px!important',
                            width: 'unset!important',
                            height: 'unset!important',
                            color: '#ffffff',
                            background: '#6123FF!important',
                            textTransform: 'none',
                            fontFamily: 'Plus Jakarta Sans'
                          }} onClick={addImage}>Add</Button>
                      </div>

                    </div>
                  </Popover>

                  {showAIDraftForm &&
                    <div className="ai-draft-form colunm">
                      <TextField value={aIDraftFormText} onChange={(e) => {
                        setAIDraftFormText(e.target.value)
                      }} placeholder="Prompt here" className="ai-draft-form-input" multiline fullWidth minRows={1} maxRows={1} />
                      <div className="column" style={{
                        borderTop: '1px solid #eeeeee',
                        marginTop: 10,
                        paddingTop: 10,
                        maxHeight: '175px',
                        overflowY: 'auto'
                      }}>
                        <p style={{ color: '#666666', marginBottom: 14 }}><strong>Suggestions:</strong></p>
                        {SUGGESTIONS.map(el => <div
                          key={el.length}
                          className="suggestion-element"
                          onClick={() => setAIDraftFormText(el)}
                        >
                          <p>
                            {el}
                          </p>
                        </div>)}
                      </div>
                      <div className="row " style={{ borderTop: '1px solid #eeeeee', marginTop: 10, paddingTop: 10 }}>
                        <Spacer />
                        <Button
                          onClick={() => setShowAIDraftForm(false)}
                          variant={'outlined'}
                          sx={{
                            padding: '6px 25px!important',
                            width: 'unset!important',
                            height: 'unset!important',
                            color: '#6123FF',
                            textTransform: 'none',
                            fontFamily: 'Plus Jakarta Sans',
                            marginRight: '10px'
                          }}
                        >Cancel</Button>
                        <LoadingButton
                          className="ai-draft-form-loading-button"
                          loading={fetching}
                          onClick={handleSendPrompt}
                          variant={'contained'}
                          sx={{
                            padding: '6px 25px!important',
                            width: 'unset!important',
                            height: 'unset!important',
                            color: '#ffffff',
                            background: '#6123FF!important',
                            textTransform: 'none',
                            fontFamily: 'Plus Jakarta Sans'
                          }}>
                          Generate
                        </LoadingButton>
                      </div>
                    </div>
                  }
                </Toolbar>
              </Editor>
            </EditorProvider>
          )}

        </div>
        {errors?.value && <p className="error-text">{errors?.value}</p>}
        {errors?.phishingLink && <p className="error-text" style={{ marginBottom: 20 }}>{errors?.phishingLink}</p>}


        <div className='row row-center'>

          {Boolean(attachments.length) && <div style={{ marginBottom: '20px', marginTop: '20px' }} className='column'>
            {attachments.map(el => <div key={el.id} className='row row-center'>
              {el.type == 'pdf' && <svg style={{ width: '20px', margin: '10px' }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"><path fill="#fff" d="M16 7h-2.5c-.827 0-1.5-.673-1.5-1.5V2H4v16h12V7z"></path><path fill="#fff" d="M16 6v-.293l-3-3V5.5c0 .275.225.5.5.5H16z"></path><path fill="#605E5C" fillRule="evenodd" d="M16.707 5 13 1.293A1 1 0 0 0 12.293 1H4a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5.707A1 1 0 0 0 16.707 5zM16 5.707V6h-2.5a.501.501 0 0 1-.5-.5V2.707l3 3zM4 2.2v15.6c0 .11.09.2.2.2h11.6a.2.2 0 0 0 .2-.2V7h-2.5A1.5 1.5 0 0 1 12 5.5V2H4.2a.2.2 0 0 0-.2.2z" clipRule="evenodd" opacity=".64"></path><path fill="#C8C6C4" d="M14.5 10h-9a.5.5 0 0 1 0-1h9a.5.5 0 0 1 0 1z"></path><path fill="#D65532" fillRule="evenodd" d="M6 16v-4a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm1.2 0h5.594a.195.195 0 0 0 .2-.196c-.006-.678.002-2.932.006-3.61 0-.11-.09-.194-.2-.194H7.2a.2.2 0 0 0-.2.2v3.6c0 .11.09.2.2.2zm-2.45 0H3a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h1.75a.25.25 0 0 1 .25.25v3.5a.25.25 0 0 1-.25.25zm10.5 0H17a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1h-1.75a.25.25 0 0 0-.25.25v3.5c0 .138.112.25.25.25z" clipRule="evenodd"></path></svg>}
              {el.type == 'xls' && <svg style={{ width: '20px', margin: '10px' }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"><path fill="#fff" d="M18 7h-2.5c-.827 0-1.5-.673-1.5-1.5V2H6v16h12V7z"></path><path fill="#fff" d="M18 6v-.293l-3-3V5.5c0 .275.225.5.5.5H18z"></path><path fill="#605E5C" fillRule="evenodd" d="M18.707 5 15 1.293A1 1 0 0 0 14.293 1H6a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5.707A1 1 0 0 0 18.707 5zM18 5.707V6h-2.5a.501.501 0 0 1-.5-.5V2.707l3 3zM6 2.2v15.6c0 .11.09.2.2.2h11.6a.2.2 0 0 0 .2-.2V7h-2.5A1.5 1.5 0 0 1 14 5.5V2H6.2a.2.2 0 0 0-.2.2z" clipRule="evenodd" opacity=".64"></path><path fill="#134A2C" fillRule="evenodd" d="M12.5 12h1c.275 0 .5.225.5.5s-.225.5-.5.5h-1a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5zm3 0h1c.275 0 .5.225.5.5s-.225.5-.5.5h-1a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5z" clipRule="evenodd"></path><path fill="#21A366" fillRule="evenodd" d="M12.5 10h1c.275 0 .5.225.5.5s-.225.5-.5.5h-1a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5zm3 0h1c.275 0 .5.225.5.5s-.225.5-.5.5h-1a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5z" clipRule="evenodd"></path><path fill="#33C481" d="M16.5 8h-1c-.275 0-.5.225-.5.5s.225.5.5.5h1c.275 0 .5-.225.5-.5s-.225-.5-.5-.5z"></path><path fill="#21A366" d="M13.5 8h-1c-.275 0-.5.225-.5.5s.225.5.5.5h1c.275 0 .5-.225.5-.5s-.225-.5-.5-.5z"></path><path fill="#107C41" d="M2 16h8a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1z"></path><path fill="#fff" d="m4.606 14 3.707-6H7.15l-3.755 6h1.21z"></path><path fill="#fff" d="M7.374 14 3.668 8H4.83l3.754 6h-1.21z"></path></svg>}


              <div className='row row-center' style={{ marginBottom: 10 }}>
                <TextField
                  // label='file name'
                  placeholder='File name'
                  variant='standard'
                  error={errors?.name}
                  value={attachments.find(file => file.id == el.id).name}
                  onChange={(e) => {
                    setAttachments(attachments.map(item => (item.id == el.id ? { ...item, name: e.target.value } : item)))
                    setTemplateChanged(true)
                  }}
                  sx={{
                    padding: '7px 16px',
                    '.MuiFormLabel-root': {
                      top: 0
                    },
                    backgroundColor: '#fff'
                  }}
                />

                <RadioSelect style={{
                  width: '100px',
                  backgroundColor: '#fff',
                  border: '1px solid #E7E7EE',
                  borderRadius: '14px',
                  marginRight: '16px',
                  marginLeft: '16px',
                  height: '48px'
                }}
                  // disabled
                  value={attachments.find(file => file.id == el.id).type}
                  placeholder={'file type'}
                  options={['pdf', 'xls']}
                  onSelectOption={(opt) => {
                    setAttachments(attachments.map(item => (item.id == el.id ? { ...item, type: opt } : item)))
                    setTemplateChanged(true)
                  }}
                />
              </div>

              <IconButton onClick={() => {
                setAttachments([])
                setTemplateChanged(true)
              }}>
                <DeleteIcon color={'#8D8D94'} />
              </IconButton>
            </div>)}
          </div>}

          {!isSms && attachments.length == 0 && < div style={{ marginBottom: '20px', marginTop: '20px' }} >
            <Button
              onClick={addFile}
              startIcon={<AttachIcon />}
              sx={{
                textTransform: 'none', color: '#6123FF'
              }}
            > Add phishing file</Button>
          </div>}


          <Spacer />
          <div className='row row-center' style={{ marginBottom: '20px', marginTop: '20px' }}>
            <Spacer />
            <Button onClick={onClose} sx={{ color: '#7843ff', textTransform: 'none', height: '42px', borderRadius: '14px', marginRight: '20px' }}>Cancel</Button>
            {isSms}
            {isSms ? (<Button
              size={'large'}
              onClick={() => handleSave(true)}
              disabled={!name || !domain || !phone || loading}

              variant={'contained'}
              sx={{ color: '#fff', backgroundColor: '#6123FF', textTransform: 'none', height: '42px', borderRadius: '14px', '&:hover': { backgroundColor: '#7843ff' } }}
            >{selectedTemplateId ? 'Save' : 'Add '} Template</Button>) : (
              <Button
                size={'large'}
                onClick={() => handleSave(false)}
                disabled={errors.name || loading}

                variant={'contained'}
                sx={{ color: '#fff', backgroundColor: '#6123FF', textTransform: 'none', height: '42px', borderRadius: '14px', '&:hover': { backgroundColor: '#7843ff' } }}
              >Save {selectedTemplateId ? '' : 'New '}Template</Button>)}
          </div>
        </div>

      </div>

    </div >
  )
}

export default TemplateCreate
