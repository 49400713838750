import axios from 'axios';
import authHeader from './auth-header';
import api from './api.service';

const API_URL = window._env_.REACT_APP_API_HOST;

class SettingsService {
  getlocations() {
    return api.get(API_URL + '/v1/countries', { headers: authHeader() });
  }
  getlanguages() {
    return api.get(API_URL + '/v1/languages', { headers: authHeader() });
  }
  getdepartments() {
    return api.get(API_URL + '/v1/departments', { headers: authHeader() });
  }

  getConnectors() {
    return axios.get(API_URL + '/v1/connectors', { headers: authHeader() });
  }
  disconnect(provider) {
    return axios.post(API_URL + '/v1/connectors/' + provider + '/disconnect', {}, { headers: authHeader() });
  }

  getGoogleAuthUrl() {
    return axios.get(API_URL + '/v1/google/authorization-url', { headers: authHeader() });
  }
  getSlackAuthUrl() {
    return axios.get(API_URL + '/v1/slack/authorization-url', { headers: authHeader() });
  }
  getGoogleAuthSsoUrl() {
    return axios.get(API_URL + '/v1/google/sso/authorization-url?redirect=ADMIN', { headers: {} });
  }
  getOutlookAuthUrl() {
    return axios.get(API_URL + '/v1/outlook/authorization-url', { headers: authHeader() });
  }
  getOutlookAuthSsoUrl() {
    return axios.get(API_URL + '/v1/outlook/sso/authorization-url?redirect=ADMIN', { headers: {} });
  }
  getADHealth() {
    return axios.get(API_URL + '/v1/ad/health', { headers: authHeader() });
  }
  getOutlookWorkflowsAuthUrl() {
    return axios.get(API_URL + '/v1/microsoft-alerts/authorization', { headers: authHeader() });
  }

  getOnboardingStatus() {
    return axios.get(API_URL + '/v1/company-configurations/self-onboarding', { headers: authHeader() });
  }
  setOnboardingStatus(status) {
    return axios.put(API_URL + '/v1/company-configurations/self-onboarding', status, { headers: authHeader() });
  }

  getPluginStatus() {
    return axios.get(API_URL + '/v1/company-configurations/plugin', { headers: authHeader() });
  }
  setPluginStatus(status) {
    return axios.put(API_URL + '/v1/company-configurations/plugin', { copilotEnabled: status }, { headers: authHeader() });
  }
  getIntegrations() {
    return axios.get(API_URL + '/v1/integrations', { headers: authHeader() });
  }
  disconnectSlack() {
    return axios.delete(API_URL + '/v1/integrations', { headers: authHeader() });
  }

}

export default new SettingsService();
