import React, { useContext } from "react";
import { SortHeader } from "..";
import UserAvatar from "src/common/components/UserAvatar";
import { useState } from "react";
import { useEffect } from "react";
// import groupsService from "src/services/groups.service";
import dayjs from "dayjs";
// import { Tooltip } from "@mui/material";
import workflowsService from "src/services/workflows.service";
import LoadingIndicator from "src/common/components/LoadingIndicator";
import { UserViewContext } from "src/App";
import utc from "dayjs/plugin/utc";

const actionOptions = {
  WEBHOOK: { name: 'Send Webhook' },
  TRAINING_PACKAGE: { name: 'Enroll to Training Package' },
  NOTIFICATION: { name: 'Send notification' },
  SLACK: { name: 'Send training in Slack' },
}

const HistoricalLogs = ({ id }) => {
  dayjs.extend(utc)
  const [sortBy, setSortBy] = useState(null)
  const [loading, setLoading] = useState(false)

  const [logs, setLogs] = useState([])
  const [logsSorted, setLogsSorted] = useState([])

  const [, setUserViewId] = useContext(UserViewContext)

  const handleSort = (field) => {
    const fieldSet = {
      'Email & Name': 'userFirstName',
      'Actions': 'workflowActionType',
      'Date & Time': 'createdAt',
    }

    const fieldName = fieldSet[field]

    setSortBy({ name: field, asc: field == sortBy?.name ? !sortBy.asc : true })

    setLogsSorted(logs.sort((a, b) => {
      if (fieldName == 'createdAt') {

        if (dayjs(a[fieldName]).unix() < dayjs(b[fieldName]).unix()) {
          return sortBy?.asc ? -1 : 1;
        }
        if (dayjs(a[fieldName]).unix() > dayjs(b[fieldName]).unix()) {
          return sortBy?.asc ? 1 : -1;
        }
        return 0;
      }
      if (a[fieldName] < b[fieldName]) {
        return sortBy?.asc ? -1 : 1;
      }
      if (a[fieldName] > b[fieldName]) {
        return sortBy?.asc ? 1 : -1;
      }
      return 0;
    }))
  }

  useEffect(() => {
    setLoading(true)
    workflowsService.getWorkflowLogs(id).then(resp => {
      setLoading(false)
      setLogs(resp.data)
      setLogsSorted(resp.data)
    }).catch(err => {
      setLoading(false)
      console.error(err);
    })
  }, [])

  return (
    <>
      <h2 style={{ fontSize: '24px', fontFamily: 'Satoshi', marginBottom: '20px' }}>Historical logs</h2>

      <div className='row row-center groups-list-heading'>
        <SortHeader name='Email & Name' sort={sortBy} onSort={handleSort} width={'55%'} />
        <SortHeader name='Actions' sort={sortBy} onSort={handleSort} width={'20%'} />
        <SortHeader name='Date & Time' sort={sortBy} onSort={handleSort} width={'15%'} />
      </div>
      <div style={{ height: 'calc(100vh - 360px)', overflow: 'auto' }}>
        {loading && <LoadingIndicator />}
        {logsSorted?.length == 0
          ? <div style={{ width: '100%', marginTop: '50px', color: 'rgb(118, 118, 127)' }} className="column column-center"> <p>no records yet</p></div>
          : (
            logsSorted && logsSorted.map(el => {
              const {
                createdAt,
                userFirstName,
                userLastName,
                userEmail,
                workflowActionType,
                id,
                userId,
              } = el

              return (
                <div key={id} className="groups-list-item row row-center">
                  <div onClick={() => setUserViewId(userId)} style={{ width: '55%', cursor: 'pointer' }} className="row row-center">
                    <UserAvatar style={{ background: "#fff" }} firstName={userFirstName} lastName={userLastName} />
                    <div className="column" style={{ maxWidth: '320px' }}>
                      <p className="one-sthing" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{userFirstName + ' ' + userLastName}</p>
                      <p style={{ color: '#76767F', fontSize: '14px', marginBottom: '6px' }}>{userEmail}</p>
                    </div>
                  </div>

                  <div style={{ width: '20%', fontSize: 14 }}>{actionOptions[workflowActionType].name}</div>
                  <div style={{ width: '15%', color: '#76767F' }}>{dayjs.utc(createdAt).local().format('D MMM HH:mm')}</div>
                </div>

              )
            })
          )}

      </div>

    </>
  )
}

export default HistoricalLogs