import React from "react"

export const highlightSearch = (string, search) => {
  return <>
    {string.split(search)[0]}
    <span style={{ backgroundColor: '#DFD3FF', color: '#6123FF' }}>
      {search}
    </span>
    {string.split(search)[1]}
    {string.split(search)[2] && <span style={{ backgroundColor: '#DFD3FF', color: '#6123FF' }}>
      {search}
    </span>}
    {string.split(search)[2]}
  </>
}


export const compactObject = val => {
  const data = Array.isArray(val) ? val.filter(Boolean) : val;
  return Object.keys(data).reduce(
    (acc, key) => {
      const value = data[key];
      if (value)
        acc[key] = typeof value === 'object' ? compactObject(value) : value;
      return acc;
    },
    Array.isArray(val) ? [] : {}
  );
};

export const toggleElement = (arr = [], el) => {
  return arr.includes(el) ? arr.filter(item => item !== el) : [...arr, el]
}
export const toggleElementById = (arr = [], el) => {
  return arr.map(item => item.id).includes(el.id) ? arr.filter(item => item.id !== el.id) : [...arr, el]
}

export function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export function getPercent(total, count) {
  return Math.round((count / total) * 100) || 0
}