import api from './api.service';
import authHeader from './auth-header';

class SimulationCampaignsService {
  getSimulationCampaigns(search, statuses = []) {
    return api.get(`/v1/simulation-campaigns?searchText=${search ? search : ''}${(statuses && statuses.length) ? '&statuses=' + statuses.join(',') : ''}`, { headers: authHeader() });
  }
  getSimulationCampaign(id) {
    return api.get(`/v1/simulation-campaigns/${id}`, { headers: authHeader() });
  }
  getSimulationCampaignStats(id) {
    return api.get(`/v1/simulation-campaigns/${id}/simulation-stats`, { headers: authHeader() });
  }
  addSimulationCampaign(campaign) {
    return api.post('/v1/simulation-campaigns/', campaign, { headers: authHeader() });
  }
  updateSimulationCampaignById(id, campaign) {
    return api.put('/v1/simulation-campaigns/' + id, campaign, { headers: authHeader() });
  }
  archiveSimulationCampaign(id) {
    return api.post('/v1/simulation-campaigns/' + id + '/archive', {}, { headers: authHeader() });
  }
  unarchiveSimulationCampaign(id) {
    return api.post('/v1/simulation-campaigns/' + id + '/unarchive', {}, { headers: authHeader() });
  }
  deleteSimulationCampaign(id) {
    return api.delete('/v1/simulation-campaigns/' + id, {}, { headers: authHeader() });
  }
}

export default new SimulationCampaignsService();
