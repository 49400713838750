import React, { useEffect, useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";

const TimePeriodSelect = ({ options, onSelectOption }) => {
  const [option, setOption] = useState(options[0]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [form, setForm] = useState({})
  // const [openDatePicker, setOpenDatePicker] = useState(false)
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (s) => {
    if (s !== 'Custom') {
      onSelectOption({ timePeriod: s.toUpperCase().replace(' ', '_') })
      setAnchorEl(null)
      setOption(s)
    } else {
      if (form.startDate || form.endDate) {
        onSelectOption({ timePeriod: 'CUSTOM', startDate: form.startDate, endDate: form.endDate })
      }
      setOption(s)
    }
  }

  useEffect(() => {
    onSelectOption({ timePeriod: 'CUSTOM', startDate: form.startDate, endDate: form.endDate })
  }, [form.startDate, form.endDate])

  return (<>
    <div onClick={handleClick} className='row row-center select-type'>
      <p style={{ marginRight: '10px', fontSize: '13px', fontWeight: '600' }}>{
        option == 'Custom'
          ? (form.startDate && form.endDate)
            ? form.startDate + ' - ' + form.endDate
            : option
          : option}</p>
      <svg style={{ transition: '0.4s', transform: open && 'rotate(180deg)' }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 5.33337L8 10.3334L13 5.33337" stroke="#1B1B26" strokeLinecap="round" strokeLinejoin="round" />
      </svg>


    </div>
    <Menu
      sx={{
        '& .MuiPaper-root': {
          color: '#1B1B26',
          borderRadius: '12px'
        },
        '& li': {
          fontFamily: 'Plus Jakarta Sans',
        },
        '& li:hover': {
        },
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={() => setAnchorEl(null)}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {options.map(el =>
        <MenuItem key={el} onClick={() => handleSelect(el)}>
          <div style={{ marginRight: '8px' }}>
            {el == option ? (
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10.0004" cy="10" r="6.36364" fill="#6123FF" />
                <circle cx="10" cy="10" r="9.5" fill="white" stroke="#6123FF" />
                <circle cx="10" cy="10" r="6" fill="#6123FF" />
              </svg>

            ) : (
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="10" r="9.5" fill="white" stroke="#A3A4AB" />
              </svg>

            )}

          </div>

          {el}
        </MenuItem>
      )}
      <div className="column">
        <DatePicker
          format={'DD/MM/YYYY'}
          maxDate={dayjs(form.endDate)}
          size="small"
          disabled={option !== 'Custom'}
          sx={{ margin: '20px', '& .MuiOutlinedInput-root': { borderRadius: '20px' } }}
          value={dayjs(form.startDate)}
          label="Start Date"
          onChange={(e) => setForm({ ...form, startDate: dayjs(e).format('YYYY-MM-DD') })}
        />
        <DatePicker
          format={'DD/MM/YYYY'}
          maxDate={dayjs()}
          minDate={dayjs(form.startDate)}
          size="small"
          disabled={option !== 'Custom'}
          sx={{ margin: '0px 20px 20px 20px', '& .MuiOutlinedInput-root': { borderRadius: '20px' } }}
          value={dayjs(form.endDate)}
          label="End Date"
          onChange={(e) => {
            setForm({ ...form, endDate: dayjs(e).format('YYYY-MM-DD') })
            setAnchorEl(null)
          }}
        />
      </div>

    </Menu>
  </>
  )
}

export default TimePeriodSelect