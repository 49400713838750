import React from 'react'

import Switch from '@mui/material/Switch'
import { styled } from '@mui/material/styles'

const CyberSwitch = styled(props => <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />)(
  ({ theme }) => ({
    width: 56,
    height: 32,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(24px)',
        '& .MuiSwitch-thumb': {
          color: 'transparrent',
          background: 'linear-gradient(264.92deg, #00BA97 4.82%, #00BC62 73.44%)',
          boxShadow: 'inset 0px -1.5px 0px #01A26D'
        },
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.1876 1.06274L5.31254 8.9377L1.375 5.0004" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>')`
        },

        '& + .MuiSwitch-track': {
          background: 'linear-gradient(264.92deg, #00BA97 4.82%, #00BC62 73.44%)',
          opacity: 0.2,
          border: 0
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
          background: 'linear-gradient(264.92deg, #414141 4.82%, #676767 73.44%)',
        }
      },
      '&.Mui-disabled': {
        '& .MuiSwitch-thumb': {
          color: 'transparrent',
          background: '#cccccc',
          boxShadow: 'inset 0px -1.5px 0px #9f9f9f'
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: 'transparrent',
        border: '6px solid #fff'
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
      }
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 24,
      height: 24,
      color: '#BABBC2',
      transform: 'translateX(2px) translateY(2px)',
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }
    },
    '& .MuiSwitch-track': {
      height: 32,
      borderRadius: 32 / 2,
      background: '#FFFFFF',
      // border: '2px solid #E7E7EE',
      boxShadow: 'inset 0px 0px 0px 2px #E7E7EE',

      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500
      })
    }
  })
)

export default CyberSwitch
