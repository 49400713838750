import React from 'react'

const ManualSimIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.2" d="M20.25 9H15V3.74925L20.25 9Z" fill="#1B1B26" />
    <path d="M9 15H15" stroke="#1B1B26" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9 12H15" stroke="#1B1B26" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9 9H12" stroke="#1B1B26" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.6893 3.75H4.5C4.30109 3.75 4.11032 3.82902 3.96967 3.96967C3.82902 4.11032 3.75 4.30109 3.75 4.5V19.5C3.75 19.6989 3.82902 19.8897 3.96967 20.0303C4.11032 20.171 4.30109 20.25 4.5 20.25H19.5C19.6989 20.25 19.8897 20.171 20.0303 20.0303C20.171 19.8897 20.25 19.6989 20.25 19.5V9.31066C20.25 9.21217 20.2306 9.11464 20.1929 9.02365C20.1552 8.93265 20.1 8.84997 20.0303 8.78033L15.2197 3.96967C15.15 3.90003 15.0673 3.84478 14.9764 3.80709C14.8854 3.7694 14.7878 3.75 14.6893 3.75V3.75Z" stroke="#1B1B26" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20.1825 9H15V3.81722" stroke="#1B1B26" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

)

export default ManualSimIcon
