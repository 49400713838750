import React, { useState } from 'react'
import './styles.css'
// import { Button } from '@mui/material'
// import BackIcon from 'src/common/icons/back'
import { Button } from '@mui/material'

import CyberSwitch from 'src/common/components/Switch'
import Spacer from 'src/common/components/Spacer'
import BackIcon from 'src/common/icons/back'
import GlobeIcon from 'src/common/icons/globe'


const ModuleView = ({ modules, onBack }) => {

  return (
    <>
      <div className='column' style={{ padding: '32px' }}>
        <div className='row' style={{ width: '900px' }}>
          <Button onClick={onBack} sx={{ color: '#6123FF', textTransform: 'none', }} startIcon={<BackIcon />} >Back to Create New Package</Button>
        </div>
        <div className="row row-center">
          <p className="drawer-title"></p>
          <Spacer />
          <p style={{ marginRight: '10px' }}>Microtraining On</p>
          <CyberSwitch />
        </div>

        <div className="column">
          {modules.map((el, index) =>
            <div key={el.trainingModuleId + 'module'} className='row row-center' style={{ height: '72px', background: '#fff', borderRadius: '10px', padding: '0 24px', marginBottom: '10px' }}>
              <p style={{ color: '#76767F' }}>{index < 8 ? '0' + (index + 1) : index}. </p>
              <p>{el.module}</p>
              <Spacer />

              <Button sx={{ color: '#6123FF', textTransform: 'none', }} startIcon={<GlobeIcon />}> Preview</Button>
            </div>

          )}
        </div>
      </div>


    </>
  )
}

export default ModuleView
