import React from 'react'

const DoneIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.25 6.75L9.75 17.2495L4.5 12" stroke="#00BC62" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

)

export default DoneIcon
