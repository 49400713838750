import React, { useContext, useEffect, useState } from 'react'

import './styles.css'

import PieChart from './components/PieChart'
import MicroProgress from './components/MicroProgress'
import Spacer from 'src/common/components/Spacer'
import { Button, Dialog, DialogContent, DialogTitle, Divider, Drawer } from '@mui/material'
import Info from 'src/common/components/Info'

import statsService from 'src/services/stats.service'
import { useNavigate } from 'react-router-dom'
import userService from 'src/services/user.service'
import WiseChart from './components/WiseChart'
import TimePeriodSelect from 'src/common/components/TimePeriodSelect'
import LoadingIndicator from 'src/common/components/LoadingIndicator'
import TemplateView from 'src/views/TemplateView'
import { SettingsContext, UserViewContext } from 'src/App'
import { CloseDrawer } from 'src/common/icons/closeDrawer'

export const ValueAndUnit = ({ val, unit, style }) => (
  <div style={style}>
    <p style={{
      fontFamily: 'Satoshi',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '24px',
      color: '#1B1B26',
    }}>{val}
      <span style={{
        marginLeft: '8px',
        fontSize: '14px',
        color: '#76767F'
      }}>{unit}</span></p>
  </div>
)

const Overview = () => {
  const navigate = useNavigate()
  const [showUsersDialog, setShowUsersDialog] = useState(false)
  const [openTemplateDrawer, setOpenTemplateDrawer] = useState(false)
  const [selectedTemplateId, setSelectedTemplateId] = useState(null)
  const [stats, setStats] = useState(null)
  const [topThemes, setTopThemes] = useState(null)
  const [microStats, setMicroStats] = useState(null)
  const [frequency, setFrequency] = useState(null)
  const [timeToReport, setTimeToReport] = useState(null)
  const [departmentsStats, setDepartmentsStats] = useState([])
  const [repeatFail, setRepeatFail] = useState(null)
  const [repeatFailUsers, setRepeatFailUsers] = useState([])
  const [totalUserCount, setTotalUserCount] = useState(null)
  const [loading, setLoading] = useState(false)

  const [timePeriod, setTimeperiod] = useState(null)

  const { departments } = useContext(SettingsContext)

  const [userViewId, setUserViewId] = useContext(UserViewContext)

  const viewUsers = () => {
    setShowUsersDialog(true)
  }

  useEffect(() => {
    setLoading(true)
    Promise.all([
      statsService.getCurrentCompanySimulationStats(),
      statsService.getTopPhishingThemes(),
      statsService.getCurrentCompanyMicroTrainingStats(),
      statsService.getFrequency(),
      statsService.getTimeToReport(),
      statsService.getDepatrmentsSimulationStats(),
      statsService.getRepeatFail(),
      statsService.getCurrentCompanyStats()


    ])
      .then((values) => {
        const [simStatsResp, topThemesResp, microStatsResp, frequencyResp, timeToReportResp, departmentsStatsResp, repeatFailResp, companyStatsResp] = values
        setStats(simStatsResp.data)
        setTopThemes(topThemesResp.data)
        setMicroStats(microStatsResp.data)
        setFrequency(frequencyResp.data)
        setTimeToReport(timeToReportResp.data)
        setDepartmentsStats(departmentsStatsResp.data)
        setRepeatFail(repeatFailResp.data)
        setTotalUserCount(companyStatsResp.data)

        setLoading(false)
      }, (err) => {
        if (err.response.status == 401) {
          navigate('/login')
        }
      })
  }, [])

  useEffect(() => {
    let userIds = ""
    if (repeatFail?.length) {

      userIds = repeatFail.map(row => row.userId).join(", ");
      userService.getUsers({ size: repeatFail.length, userIds: userIds }).then((usersResp) => {
        // setTotalUserCount(usersResp.headers['x-total-count'])
        setRepeatFailUsers(usersResp.data)
      })
    }
  }, [repeatFail])

  useEffect(() => {
    if (timePeriod) {
      setLoading(true)
      Promise.all([
        statsService.getCurrentCompanySimulationStats(timePeriod),
        statsService.getTopPhishingThemes(timePeriod),
        statsService.getCurrentCompanyMicroTrainingStats(timePeriod),
        statsService.getFrequency(timePeriod),
        statsService.getTimeToReport(timePeriod),
        statsService.getDepatrmentsSimulationStats(timePeriod),
        statsService.getRepeatFail(timePeriod),
        statsService.getCurrentCompanyStats(timePeriod)


      ])
        .then((values) => {
          const [simStatsResp, topThemesResp, microStatsResp, frequencyResp, timeToReportResp, departmentsStatsResp, repeatFailResp, companyStatsResp] = values
          setStats(simStatsResp.data)
          setTopThemes(topThemesResp.data)
          setMicroStats(microStatsResp.data)
          setFrequency(frequencyResp.data)
          setTimeToReport(timeToReportResp.data)
          setDepartmentsStats(departmentsStatsResp.data)
          setRepeatFail(repeatFailResp.data)
          setTotalUserCount(companyStatsResp.data)

          setLoading(false)
        }, (err) => {
          if (err.response.status == 401) {
            navigate('/login')
          }
        })


    }
  }, [timePeriod])

  const handleChangePeriod = (period) => {
    setTimeperiod(period)
  }

  return (
    <div style={{ backgroundColor: '#F8F8FB', padding: '24px', height: 'calc(100vh)', overflowY: 'auto' }}>
      <div className='row row-center'>
        <h1>Overview</h1>
        <div className='spacer'></div>
        <TimePeriodSelect options={['All Time', 'Last Year', 'Last Month', 'Custom']} onSelectOption={handleChangePeriod} />

      </div>
      {loading
        ? <LoadingIndicator />
        : <>

          <div className='row'>
            <div className='column white-box' style={{ width: 'calc( 50% - 8px)' }}>
              <h2>Phishing Simulation</h2>
              <div style={{ marginBottom: '-12px' }} className='row'>
                <p style={{ marginRight: '32px' }} className='big-num big-num-green'>{stats?.reportedCount}</p>
                <PieChart data={Math.round((stats?.reportedCount / stats?.totalCount) * 100) || 0} />
              </div>
              <div style={{ marginTop: '32px' }} className='row'>
                <ValueAndUnit val={stats?.totalCount} unit={'Total sent'} />
                <Spacer />
                <ValueAndUnit val={(Math.round((stats?.phishedCount / stats?.totalCount) * 100) || 0) + '%'} unit={stats?.phishedCount + ' Phished'} />
                <Spacer />
                <ValueAndUnit val={(Math.round((stats?.openedCount / stats?.totalCount) * 100) || 0) + '%'} unit={(stats?.openedCount || 0) + ' Opened'} />
              </div>
            </div>
            <Spacer />
            <div className='column white-box' style={{ width: 'calc( 50% - 8px)' }}>
              <h2>Micro-Training  </h2>
              <div className='row'>
                <p className='big-num big-num-green'>{Math.round((microStats?.completedCount / microStats?.totalCount) * 100) || 0}% </p>
                <Spacer />
                <MicroProgress data={Math.round((microStats?.completedCount / microStats?.totalCount) * 100) || 0} total={microStats?.completedCount} />
              </div>
              <div style={{ marginTop: '32px' }} className='row'>
                <ValueAndUnit val={microStats?.totalCount} unit={'Total sent'} />
                <Spacer />
                <ValueAndUnit val={(Math.round((microStats?.failedCount / microStats?.totalCount) * 100) || 0) + '%'} unit={microStats?.failedCount + ' Fail'} />
                <Spacer />
                <ValueAndUnit val={(Math.round((microStats?.missedCount / microStats?.totalCount) * 100) || 0) + '%'} unit={microStats?.missedCount + ' Miss'} />
              </div>

            </div>
          </div>

          <div className='row'>
            <div className='column white-box' style={{ width: 'calc( 50% - 8px)' }}>
              <div className='row row-center'>
                <h2 style={{ marginBottom: '0px' }}>Simulation Frequency</h2>
                <Info text={'Average number of phishing simulation emails an employee receives per month.'} />
                <Spacer />
                <svg style={{ marginLeft: '8px', marginRight: '14px' }} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z" stroke="#8D8D94" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M9.63532 22.3634C8.79868 21.5281 8.13493 20.5362 7.68206 19.4442C7.2292 18.3522 6.99609 17.1816 6.99609 15.9994C6.99609 14.8172 7.2292 13.6466 7.68206 12.5546C8.13493 11.4626 8.79868 10.4707 9.63532 9.63544" stroke="#8D8D94" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M22.3633 9.63544C23.1999 10.4707 23.8637 11.4626 24.3165 12.5546C24.7694 13.6466 25.0025 14.8172 25.0025 15.9994C25.0025 17.1816 24.7694 18.3522 24.3165 19.4442C23.8637 20.5362 23.1999 21.5281 22.3633 22.3634" stroke="#8D8D94" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M6.10155 25.9032C4.80011 24.604 3.76762 23.0609 3.06316 21.3622C2.3587 19.6635 1.99609 17.8426 1.99609 16.0037C1.99609 14.1647 2.3587 12.3438 3.06316 10.6452C3.76762 8.9465 4.80011 7.40341 6.10155 6.10419" stroke="#8D8D94" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M25.9023 6.10419C27.2038 7.40341 28.2363 8.9465 28.9407 10.6452C29.6452 12.3438 30.0078 14.1647 30.0078 16.0037C30.0078 17.8426 29.6452 19.6635 28.9407 21.3622C28.2363 23.0609 27.2038 24.604 25.9023 25.9032" stroke="#8D8D94" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <ValueAndUnit style={{ width: '115px' }} val={frequency?.frequency || '0'} unit={'per month'} />
              </div>

              <Divider sx={{ mt: '15px', mb: '15px' }} />
              <div className='row row-center'>
                <h2 style={{ marginBottom: '0px' }}>Time to Report</h2>
                <Info text={'Average time employees take to report a phishing simulation using the Cyberlift phishing button.'} />
                <Spacer />
                <svg style={{ marginLeft: '8px', marginRight: '14px' }} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 27C22.0751 27 27 22.0751 27 16C27 9.92487 22.0751 5 16 5C9.92487 5 5 9.92487 5 16C5 22.0751 9.92487 27 16 27Z" stroke="#8D8D94" strokeWidth="1.5" strokeMiterlimit="10" />
                  <path d="M16 16.0018L20.9497 11.0521" stroke="#8D8D94" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M13 1H19" stroke="#8D8D94" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

                <ValueAndUnit style={{ width: '115px' }} val={timeToReport?.minutesToReport || '0'} unit={'min'} />
              </div>

              <Divider sx={{ mt: '15px', mb: '15px' }} />
              <div className='row row-center'>
                <h2 style={{ marginBottom: '0px' }}>Repeat Fail</h2>
                <Info text={'Percentage of users who have failed a phishing simulation two times or more in a row.'} />
                {!!repeatFail?.length &&
                  <Button onClick={viewUsers} sx={{ color: '#6123FF', textTransform: 'none', fontFamily: 'Plus Jakarta Sans' }} >View Users</Button>
                }
                <Spacer />
                <svg style={{ marginLeft: '8px', marginRight: '14px' }} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.98047 12.4583H3.98047V6.45831" stroke="#8D8D94" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M23.7795 8.22183C22.758 7.20038 21.5454 6.39013 20.2108 5.83733C18.8762 5.28452 17.4458 5 16.0013 5C14.5567 5 13.1263 5.28452 11.7918 5.83733C10.4572 6.39013 9.24455 7.20038 8.22311 8.22183L3.98047 12.4645" stroke="#8D8D94" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M22.0195 19.5417H28.0195V25.5417" stroke="#8D8D94" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M8.21875 23.7843C9.24019 24.8058 10.4528 25.616 11.7874 26.1688C13.122 26.7216 14.5524 27.0062 15.9969 27.0062C17.4415 27.0062 18.8719 26.7216 20.2064 26.1688C21.541 25.616 22.7537 24.8058 23.7751 23.7843L28.0177 19.5417" stroke="#8D8D94" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

                <ValueAndUnit style={{ width: '125px' }} val={(Math.round((repeatFailUsers?.length / totalUserCount?.totalCount) * 100) || 0) + '%'} unit={repeatFailUsers?.length + ' users'} />
              </div>

            </div>
            <Spacer />
            <div className='column white-box' style={{ width: 'calc( 50% - 8px)' }}>
              <h2>Top Phishing Themes</h2>
              {topThemes?.length ? topThemes?.map((el, index) => (
                <p
                  onClick={() => {
                    setOpenTemplateDrawer(true)
                    setSelectedTemplateId(el.templateId)
                  }}
                  key={el.templateId}
                  className='top-themes-element'
                ><span>0{index + 1}.</span> {el?.subject} </p>

              )) : <p style={{ color: '#666' }}>No data for this time period</p>}
            </div>
          </div>

          <div className='row white-box'>
            <div className='column' style={{ width: '70%' }}>
              <div className='row row-center'>
                <h2>Wise performance</h2>
                <Spacer />
                {/* <RadioSelect options={['Phishing Simulation by Departments ', 'Phishing Simulation by Country ']} onSelectOption={() => { }} /> */}

              </div>
              <div className='row row-center'>
                <div style={{ width: '20px', height: '20px', borderRadius: '4px', background: 'linear-gradient(264.92deg, #00BA97 4.82%, #00BC62 73.44%)' }}></div>
                <p style={{ marginLeft: '10px', fontSize: '14px', color: '#1B1B26', marginRight: '20px' }}>Reported</p>

                <div style={{ width: '20px', height: '20px', borderRadius: '4px', background: 'linear-gradient(264.92deg, rgba(240, 40, 76, 0.3) 4.82%, rgba(240, 40, 143, 0.3) 73.44%), #FFFFFF' }}></div>
                <p style={{ marginLeft: '10px', fontSize: '14px', color: '#1B1B26', marginRight: '20px' }}>Phished</p>

                <div style={{ width: '20px', height: '20px', borderRadius: '4px', background: '#E7E7EE' }}></div>
                <p style={{ marginLeft: '10px', fontSize: '14px', color: '#1B1B26', marginRight: '20px' }}>Opened</p>
              </div>
              <div style={{ marginTop: '20px', flex: '1', overflowX: 'auto', overflowY: 'hidden' }}>
                <WiseChart data={departmentsStats} />
              </div>

            </div>

            {/* side stats */}
            <div className='column' style={{ width: '30%', maxHeight: '500px', overflowY: 'auto', marginLeft: '32px', borderLeft: '1px solid #E7E7EE', paddingLeft: '32px' }}>
              <h2>All Departments</h2>

              {departmentsStats.length
                ? departmentsStats.map(el => (
                  <div key={el.department}>
                    <p style={{ fontSize: '14px', lineHeight: '22px', marginBottom: '8px', marginTop: '16px', fontWeight: '600' }}>{el.department}</p>
                    {el.phishedPercentage > 75 && (

                      <div style={{ margin: '4px 0px 10px 0px' }} className='row row-center'>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.2" d="M13.25 2.5H9.75V13H13.25V2.5Z" fill="#B40020" />
                          <path d="M14.25 13H1.75" stroke="#B40020" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M6.25 13V5.5H9.75" stroke="#B40020" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M13.25 2.5H9.75V13H13.25V2.5Z" stroke="#B40020" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M2.75 13V8.5H6.25" stroke="#B40020" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <p style={{ color: '#B40020' }}>High risk</p>
                      </div>
                    )}
                    <p style={{ fontSize: '14px', lineHeight: '22px', marginBottom: '4px', color: '#76767F' }}><span style={{ color: '#1B1B26', marginRight: '8px' }}>{el.reportedPercentage}% Reported</span>{el.reportedCount}</p>
                    <p style={{ fontSize: '14px', lineHeight: '22px', marginBottom: '4px', color: '#76767F' }}><span style={{ color: '#1B1B26', marginRight: '8px' }}>{el.phishedPercentage}% Phished </span>{el.phishedCount}</p>
                    <p style={{ fontSize: '14px', lineHeight: '22px', marginBottom: '4px', color: '#76767F' }}><span style={{ color: '#1B1B26', marginRight: '8px' }}>{el.openedPercentage}% Opened </span>{el.openedCount}</p>
                  </div>
                ))
                : departments?.map(el => (
                  <div key={el}>
                    <p style={{ fontSize: '14px', lineHeight: '22px', marginBottom: '8px', marginTop: '16px', fontWeight: '600' }}>{el}</p>
                    <p style={{ fontSize: '14px', lineHeight: '22px', marginBottom: '4px', color: '#76767F' }}><span style={{ color: '#1B1B26', marginRight: '8px' }}>0% Reported</span>0</p>
                    <p style={{ fontSize: '14px', lineHeight: '22px', marginBottom: '4px', color: '#76767F' }}><span style={{ color: '#1B1B26', marginRight: '8px' }}>0% Phished </span>0</p>
                    <p style={{ fontSize: '14px', lineHeight: '22px', marginBottom: '4px', color: '#76767F' }}><span style={{ color: '#1B1B26', marginRight: '8px' }}>0% Opened </span>0</p>
                  </div>
                )

                )}

            </div>

          </div>
        </>
      }

      <Dialog sx={{ '& .MuiPaper-root': { borderRadius: '22px', maxWidth: 'unset', width: '900px' } }} open={showUsersDialog} onClose={() => setShowUsersDialog(false)}>
        <DialogTitle sx={{
          fontFamily: 'Satoshi',
          fontStyle: 'normal',
          fontWeight: '900',
          fontSize: '35px',
          lineHeight: '110%',
          color: '#1B1B26',
        }}>Repeat Fail: {repeatFailUsers.length} users</DialogTitle>
        <DialogContent>

          <p style={{ fontSize: '14px', marginBottom: '32px' }}>Employees who fail multiple simulations need more cybersecurity training and awareness</p>

          <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
            {repeatFailUsers?.map(row => <div key={row.id} style={{ height: '80px', borderBottom: '1px solid #F1F1F5' }} className='row row-center'>
              <div className='row' style={{ alignItems: 'center', width: '35%' }}>
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    borderRadius: '20px',
                    overflow: 'hidden',
                    backgroundColor: '#F4F5F9',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {row.img ? (
                    <img width={'100%'} src={row.img} alt='' />
                  ) : (
                    <div
                      style={{
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '24px',
                        textAlign: 'center',
                        color: '#5F5F68'
                      }}
                    >
                      {row.firstName[0] + row.lastName[0]}
                    </div>
                  )}
                </div>
                <div onClick={() => {
                  setShowUsersDialog(false)
                  setUserViewId(row.id)
                }} style={{ marginLeft: '20px', cursor: 'pointer' }} className='column'>
                  <p className='employee-name' style={{ fontWeight: '600', fontSize: '16px', marginBottom: '8px' }}>
                    {row.firstName + ' ' + row.lastName}</p>
                  <p style={{ fontSize: '13px', color: '#76767F' }}>
                    {row.email}
                  </p>
                </div>
              </div>

              <div style={{ width: '20%' }}><p>{row.country}</p></div>
              <div style={{ width: '28%' }}><p>{row.department}</p></div>

              <div style={{ width: '17%' }} className='row row-center'>
                <svg style={{ marginRight: '10px' }} width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.2" d="M2.8125 8V3.875C2.8125 3.72582 2.87176 3.58274 2.97725 3.47725C3.08274 3.37176 3.22582 3.3125 3.375 3.3125H14.625C14.7742 3.3125 14.9173 3.37176 15.0227 3.47725C15.1282 3.58274 15.1875 3.72582 15.1875 3.875V8C15.1875 13.9073 10.1738 15.8645 9.1727 16.1964C9.06079 16.2349 8.93921 16.2349 8.8273 16.1964C7.8262 15.8645 2.8125 13.9073 2.8125 8Z" fill="#B40020" />
                  <path d="M2.8125 8V3.875C2.8125 3.72582 2.87176 3.58274 2.97725 3.47725C3.08274 3.37176 3.22582 3.3125 3.375 3.3125H14.625C14.7742 3.3125 14.9173 3.37176 15.0227 3.47725C15.1282 3.58274 15.1875 3.72582 15.1875 3.875V8C15.1875 13.9073 10.1738 15.8645 9.1727 16.1964C9.06079 16.2349 8.93921 16.2349 8.8273 16.1964C7.8262 15.8645 2.8125 13.9073 2.8125 8Z" stroke="#B40020" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

                <p style={{ marginRight: '30px', fontWeight: 500, fontSize: '24px', lineHeight: '120%' }}> {Math.round(row.failRate * 100)}%</p></div>
            </div>)}
          </div>


        </DialogContent>
      </Dialog>

      <Drawer anchor='right' open={openTemplateDrawer} onClose={() => setOpenTemplateDrawer(false)}>
        <div
          style={{ position: 'fixed', top: '24px', right: '1182px', cursor: 'pointer' }}
          onClick={() => setOpenTemplateDrawer(false)}
        >
          <CloseDrawer />
        </div>
        <TemplateView id={selectedTemplateId} />
      </Drawer>

    </div>)
}

export default Overview
