import React from 'react'

const GetStartedIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.2" d="M8.81893 17.3088C7.75827 20.4908 3.51562 20.4908 3.51562 20.4908C3.51562 20.4908 3.51562 16.2482 6.69761 15.1875L8.81893 17.3088Z" fill="#6123FF" />
    <path opacity="0.2" d="M17.3033 10.9375V16.9908C17.3033 17.0893 17.2839 17.1868 17.2462 17.2778C17.2085 17.3688 17.1533 17.4515 17.0836 17.5211L14.0516 20.5532C13.9556 20.6492 13.8354 20.7173 13.7037 20.7503C13.572 20.7833 13.4338 20.78 13.3039 20.7407C13.174 20.7013 13.0572 20.6275 12.9659 20.5269C12.8747 20.4264 12.8124 20.303 12.7858 20.1699L12 16.2408L17.3033 10.9375Z" fill="#6123FF" />
    <path opacity="0.2" d="M13.0619 6.69531H7.0086C6.91011 6.69531 6.81258 6.71471 6.72159 6.7524C6.6306 6.79009 6.54792 6.84534 6.47827 6.91498L3.44623 9.94702C3.35024 10.043 3.28211 10.1633 3.24909 10.2949C3.21607 10.4266 3.21941 10.5648 3.25874 10.6947C3.29808 10.8246 3.37195 10.9414 3.47246 11.0327C3.57297 11.1239 3.69636 11.1862 3.82948 11.2128L7.7586 11.9986L13.0619 6.69531Z" fill="#6123FF" />
    <path d="M8.81893 17.3088C7.75827 20.4908 3.51562 20.4908 3.51562 20.4908C3.51562 20.4908 3.51562 16.2482 6.69761 15.1875" stroke="#6123FF" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.3644 9.87742L12.0005 16.2414L7.75781 11.9987L14.1218 5.63478C16.5315 3.22501 18.9413 3.24852 19.969 3.40185C20.1272 3.42544 20.2737 3.49923 20.3868 3.61236C20.5 3.72548 20.5738 3.87195 20.5973 4.03019C20.7507 5.05789 20.7742 7.46765 18.3644 9.87742Z" stroke="#6123FF" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17.3033 10.9375V16.9908C17.3033 17.0893 17.2839 17.1868 17.2462 17.2778C17.2085 17.3688 17.1533 17.4515 17.0836 17.5211L14.0516 20.5532C13.9556 20.6492 13.8354 20.7173 13.7037 20.7503C13.572 20.7833 13.4338 20.78 13.3039 20.7407C13.174 20.7013 13.0572 20.6275 12.9659 20.5269C12.8747 20.4264 12.8124 20.303 12.7858 20.1699L12 16.2408" stroke="#6123FF" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.0619 6.69531H7.0086C6.91011 6.69531 6.81258 6.71471 6.72159 6.7524C6.6306 6.79009 6.54792 6.84534 6.47827 6.91498L3.44623 9.94702C3.35024 10.043 3.28211 10.1633 3.24909 10.2949C3.21607 10.4266 3.21941 10.5648 3.25874 10.6947C3.29808 10.8246 3.37195 10.9414 3.47246 11.0327C3.57297 11.1239 3.69636 11.1862 3.82948 11.2128L7.7586 11.9986" stroke="#6123FF" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

)

export default GetStartedIcon
