import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { styled } from '@mui/material/styles'
import { FormControl, InputLabel, ListItemText, MenuItem, Select } from "@mui/material";
import { LoginInput } from "src/pages/auth-link";
import { INDUSTRIES, InputSelect } from "src/pages/registration";

const SelectStyled = styled(Select)(() => ({
  border: '1px solid #E7E7EE',
  borderRadius: 20,
  padding: '0px 16px',
  fontSize: '16px',
  backgroundColor: '#fff',
  '.MuiSelect-select': {
    padding: '13.5px 14px'
  },
  '& .MuiFormLabel-root': {
    fontSize: '16px',
    left: '16px',
    top: '4px',
  },
  '& .MuiFormLabel-root.MuiFormLabel-filled': {
    top: '10px',
    fontSize: '14px',
  },
  '& .MuiFormLabel-root.Mui-focused': {
    fontSize: '14px',
    color: '#76767F',
    top: '10px',
    // left: '16px',
  },
  '& .MuiInput-root:before': {
    display: 'none'
  },
  '& .MuiInput-root:after': {
    display: 'none'
  },
}))

export const Workspace = () => {
  const { companyName, setCompanyName, setShowSaveButton, industry, setIndustry, languages, companyLanguage, setCompanyLanguage, companyNameError, setCompanyNameError } = useOutletContext()

  return (
    <div className='row settings-section'>
      <div className='column'>

        <h2>Workspace</h2>

        <p>Here is some basic information about your company that will be visible to other users on the platform</p>

        <LoginInput
          sx={{ borderRadius: '20px', mb: '20px', mt: '20px' }}
          error={companyNameError}
          label='Name of the company'
          variant='standard'
          value={companyName}
          onChange={(e) => {
            setCompanyName(e.target.value)
            setShowSaveButton(true)
            setCompanyNameError(null)
          }}
        />
        {(companyNameError) &&
          <p className='error-text' style={{ margin: '-10px 0px 30px 20px' }}>{companyNameError}</p>
        }
        {/* <LoginInput
          sx={{ borderRadius: '20px', mb: '10px' }}
          label='Industry'
          variant='standard'
          value={industry}
          onChange={(e) => {
            setIndustry(e.target.value)
            setShowSaveButton(true)
          }}
        /> */}

        <FormControl fullWidth>
          <InputLabel id="Industry">Industry</InputLabel>
          <InputSelect
            required
            MenuProps={{
              style: {
                maxHeight: 200,
              },
            }}
            sx={{ borderRadius: '20px', mb: '18px' }}
            error={null}
            labelId="Industry"
            value={industry}
            label="Industry"
            onChange={(e) => {
              setIndustry(e.target.value)
              setShowSaveButton(true)
            }}
          >
            {INDUSTRIES.map(el => (
              <MenuItem key={el} value={el}>{el}</MenuItem>
            ))}
          </InputSelect>
        </FormControl>

        <p style={{ margin: '20px 0px' }}>Company Language</p>

        <SelectStyled
          value={companyLanguage}
        >
          {languages.map(el =>
            <MenuItem onClick={() => { setCompanyLanguage(el); setShowSaveButton(true) }} key={el} value={el}>
              <ListItemText primary={el} />
            </MenuItem>
          )}
        </SelectStyled>
      </div>

    </div>
  )
}