import React from 'react'

const TrainingIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g>
      <path
        opacity='0.2'
        d='M20.25 4.5H6.75C6.65151 4.5 6.55398 4.5194 6.46299 4.55709C6.37199 4.59478 6.28931 4.65002 6.21967 4.71967C6.15002 4.78931 6.09478 4.87199 6.05709 4.96299C6.0194 5.05398 6 5.15151 6 5.25V7.5H17.25C17.3485 7.5 17.446 7.5194 17.537 7.55709C17.628 7.59478 17.7107 7.65002 17.7803 7.71967C17.85 7.78931 17.9052 7.87199 17.9429 7.96299C17.9806 8.05398 18 8.15151 18 8.25V16.5H20.25C20.3485 16.5 20.446 16.4806 20.537 16.4429C20.628 16.4052 20.7107 16.35 20.7803 16.2803C20.85 16.2107 20.9052 16.128 20.9429 16.037C20.9806 15.946 21 15.8485 21 15.75V5.25C21 5.15151 20.9806 5.05398 20.9429 4.96299C20.9052 4.87199 20.85 4.78931 20.7803 4.71967C20.7107 4.65002 20.628 4.59478 20.537 4.55709C20.446 4.5194 20.3485 4.5 20.25 4.5Z'
        fill='#1B1B26'
      />
      <path
        d='M17.25 7.5H3.75C3.33579 7.5 3 7.83579 3 8.25V18.75C3 19.1642 3.33579 19.5 3.75 19.5H17.25C17.6642 19.5 18 19.1642 18 18.75V8.25C18 7.83579 17.6642 7.5 17.25 7.5Z'
        stroke='#1B1B26'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6 7.5V5.25C6 5.05109 6.07902 4.86032 6.21967 4.71967C6.36032 4.57902 6.55109 4.5 6.75 4.5H20.25C20.4489 4.5 20.6397 4.57902 20.7803 4.71967C20.921 4.86032 21 5.05109 21 5.25V15.75C21 15.9489 20.921 16.1397 20.7803 16.2803C20.6397 16.421 20.4489 16.5 20.25 16.5H18'
        stroke='#1B1B26'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M3 10.5H18' stroke='#1B1B26' strokeLinecap='round' strokeLinejoin='round' />
    </g>
  </svg>
)

export default TrainingIcon
