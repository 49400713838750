import axios from "axios";
import { parseJwt } from "src/heplers";
import authHeader from "./auth-header";

const API_URL = window._env_.REACT_APP_API_HOST;

class AuthService {
  login(email, password) {
    return axios
      .post(API_URL + "/auth/login", {
        email,
        password
      })
      .then(response => {
        if (parseJwt(response.data.accessToken).role == 'USER') {
          return { error: 'Access Denied' }
        } else {
          if (response.data.accessToken) {
            localStorage.setItem("user", JSON.stringify(response.data));
          }
        }

        return response.data;
      });
  }

  authLink(email) {
    return axios
      .post(API_URL + "/auth/admin/auth-link-request", {
        email
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    return axios.post(API_URL + '/auth/logout', {}, { headers: authHeader() })
  }

  validateToken(token) {
    return axios
      .post(API_URL + "/auth/token-login", {
        token,
      })
      .then(response => {
        if (parseJwt(response.data.accessToken).role == 'USER') {
          return { error: 'Access Denied' }
        } else {
          if (response.data.accessToken) {
            localStorage.setItem("user", JSON.stringify(response.data));
          }
        }

        return response.data;
      });
  }

  // validateGoogle(code, domain) {
  //   return axios
  //     .post(API_URL + "/v1/google/callback", {
  //       code,
  //       domain
  //     })
  // }

  // validateOutlook(code, domain) {
  //   return axios
  //     .post(API_URL + "/v1/outlook/callback", {
  //       code,
  //       domain
  //     })
  // }

  authorizeGoogle(code, domain) {
    return axios
      .post(API_URL + "/v1/google/authorize", {
        code,
        domain
      }, { headers: authHeader(), })
  }

  authorizeOutlook(code) {
    return axios
      .post(API_URL + "/v1/outlook/authorize", {
        code
      }, { headers: authHeader(), })
  }

  authorizeSlack(code) {
    return axios
      .post(API_URL + "/v1/slack/authorize", {
        code
      }, { headers: authHeader(), })
  }

  authorizeOutlookWorkflows(code) {
    return axios.post(API_URL + "/v1/microsoft-alerts/token/" + code, {}, { headers: authHeader(), })
  }

  authorizeGoogleSso(code) {
    return axios
      .post(API_URL + "/v1/google/sso/authorize", {
        code,
        redirect: 'ADMIN'
      })
      .then(response => {
        if (['COMPANY_ADMIN', 'ADMIN'].includes(parseJwt(response.data.accessToken).role)) {
          if (response.data.accessToken) {
            localStorage.setItem("user", JSON.stringify(response.data));
          }
        }

        return response.data;
      });
  }

  authorizeOutlookSso(code) {
    return axios
      .post(API_URL + "/v1/outlook/sso/authorize", {
        code,
        redirect: 'ADMIN'
      })
      .then(response => {
        if (['COMPANY_ADMIN', 'ADMIN'].includes(parseJwt(response.data.accessToken).role)) {
          if (response.data.accessToken) {
            localStorage.setItem("user", JSON.stringify(response.data));
          }
        }

        return response.data;
      });
  }

  authorizeAD(clientId, secretId, token) {
    return axios
      .post(API_URL + "/v1/ad/authorize", {
        clientId,
        clientSecret: secretId,
        tenantId: token
      }, { headers: authHeader(), })
  }

  healthAD(clientId, secretId, token) {
    return axios
      .post(API_URL + "/v1/ad/health", {
        clientId: clientId,
        clientSecret: secretId,
        tenantId: token
      }, { headers: authHeader(), })
  }

  healthGoogleAlerts(googleClientId, mainContact) {
    return axios
      .post(API_URL + "/v1/google-alerts/authorization", {
        googleClientId, mainContact
      }, { headers: authHeader(), })
  }
}

export default new AuthService();
