import React from "react"

const TabPill = ({ active, checked, text, amount = 0, onSelect = () => { } }) => {
  return (
    <div onClick={amount !== 0 ? () => onSelect(text) : () => { }} className='row row-center' style={{
      backgroundColor: amount == 0 ? '#fff' : checked ? '#F7F4FF' : '#F4F5F9',
      borderRadius: '40px',
      height: '40px',
      padding: '0px 16px',
      marginRight: '8px',
      border: amount == 0 ? '1px solid #E7E7EE' : active && '1px solid #6123FF',
      cursor: amount !== 0 && 'pointer'
    }}>
      {checked && amount !== 0 &&
        <svg style={{ marginRight: '8px' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.25 6.75L9.75 17.2495L4.5 12" stroke="#6123FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      }
      <p style={{
        fontWeight: '600',
        color: amount == 0 ? '#8D8D94' : checked && '#6123FF',
        marginRight: '8px',
      }}>{text}</p>
      <div style={{
        width: '24px',
        height: '24px',
        borderRadius: '24px',
        background: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <p style={{
          fontWeight: '600',
          fontSize: '12px',
          color: amount == 0 && '#8D8D94'
        }}>{amount}</p>
      </div>
    </div>
  )
}

export default TabPill