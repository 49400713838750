import React from 'react'

const TeamIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g>
      <path
        opacity='0.2'
        d='M8.25003 15C10.9424 15 13.125 12.8174 13.125 10.125C13.125 7.43261 10.9424 5.25 8.25003 5.25C5.55764 5.25 3.37503 7.43261 3.37503 10.125C3.37503 12.8174 5.55764 15 8.25003 15Z'
        fill='#1B1B26'
      />
      <path
        d='M8.25003 15C10.9424 15 13.125 12.8174 13.125 10.125C13.125 7.43261 10.9424 5.25 8.25003 5.25C5.55764 5.25 3.37503 7.43261 3.37503 10.125C3.37503 12.8174 5.55764 15 8.25003 15Z'
        stroke='#1B1B26'
        strokeMiterlimit='10'
      />
      <path
        d='M14.5703 5.43271C15.2408 5.24379 15.9441 5.20075 16.6326 5.3065C17.3212 5.41224 17.9791 5.66431 18.562 6.04572C19.1449 6.42714 19.6393 6.92904 20.012 7.51763C20.3846 8.10622 20.6268 8.76783 20.7221 9.45789C20.8175 10.148 20.764 10.8504 20.565 11.5181C20.366 12.1857 20.0263 12.8029 19.5687 13.3281C19.1111 13.8534 18.5463 14.2745 17.9123 14.5631C17.2782 14.8517 16.5897 15.001 15.8931 15.0011'
        stroke='#1B1B26'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.5 18.5059C2.26138 17.4229 3.27215 16.539 4.44698 15.9288C5.62182 15.3186 6.92623 15.0001 8.25008 15C9.57393 14.9999 10.8784 15.3184 12.0532 15.9285C13.2281 16.5386 14.239 17.4225 15.0004 18.5054'
        stroke='#1B1B26'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.8926 15C17.2166 14.999 18.5213 15.3171 19.6963 15.9273C20.8713 16.5375 21.8819 17.4218 22.6427 18.5054'
        stroke='#1B1B26'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
)

export default TeamIcon
