import React, { useEffect, useRef, useState } from 'react'
import './styles.css'
import { styled } from '@mui/material/styles'
import { Button, ButtonGroup, Collapse, Drawer, TextField } from '@mui/material'

import CustomCheckbox from 'src/common/components/CustomCheckbox'
import Spacer from 'src/common/components/Spacer'

import dayjs from 'dayjs'
import EditIcon from 'src/common/icons/editIcon'
import { ValueAndUnit } from 'src/pages/overview'
import { toggleElement, toggleElementById } from 'src/heplers'

import PlusIcon from 'src/common/icons/plusIcon'
import templatesService from 'src/services/templates.service'
import simulationCampaignsService from 'src/services/simulation-campaigns.service'
import RadioSelect from 'src/common/components/RadioSelect'
import { CloseDrawer } from 'src/common/icons/closeDrawer'
import TemplateCreate from 'src/pages/templates/components/templateCreate'
import CustomTemplatePreview from '../templatePreview'
import ViewIcon from 'src/common/icons/viewIcon'
import TemplateView from 'src/views/TemplateView'
import useDebounce, { useOnScreen } from 'src/hooks'
import LoadingIndicator from 'src/common/components/LoadingIndicator'
import { Letter } from 'react-letter'
import { SearchInput } from 'src/common/components/DataGrid'
import { ChooseUsersGroups } from 'src/common/components/ChooseUsersGroups'
import { DatePicker } from '@mui/x-date-pickers'
import Info from 'src/common/components/Info'
import companyService from 'src/services/company.service'
import { InputText } from 'src/pages/settings/components/phishingSimulations'


export const TableBigNum = ({ color, num }) => (
  < span style={{
    fontFamily: 'Satoshi',
    fontSize: '24px',
    fontWeight: '500',
    color: color ? color : num < 50 && '#DB0F34'
  }}>
    {num ? num : '0'}
  </span >
)


export const SortHeader = ({ name, sort, onSort, width }) => {
  return (
    <div onClick={() => onSort(name)} className='row row-center groups-list-table-sorter' style={{ width: width }}>
      <span style={{ color: name == sort?.name && '#6123FF', marginRight: '6px' }}>{name}</span>
      {sort?.name == name && (
        <svg style={{ transform: !sort.asc && 'rotate(180deg)', transition: '.3s' }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.83325 3.33594V12.8359" stroke="#6123FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M3.33325 8.33594L7.83325 12.8359L12.3333 8.33594" stroke="#6123FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

      )}
    </div>
  )
}

const LoadNextPage = ({ onHandleNextPage }) => {
  const ref = useRef(null)
  const isVisible = useOnScreen(ref)


  useEffect(() => {
    if (isVisible) {
      onHandleNextPage()
    }
  }, [isVisible])

  return <div ref={ref} className='column column-center'><LoadingIndicator size='small' /></div>
}

const InternalTemplatesItem = ({ el, selectedInternalTemplates, setSelectedInternalTemplates, onPreviewTemplate }) => {

  return (
    <div className={`templates-grid-item column ${selectedInternalTemplates.includes(el.id) && ' active'}`}>
      <div className='templates-grid-item-img'>
        <div className='template-thumbbail' >
          <div onClick={(e) => { e.preventDefault() }}><Letter html={el?.body} /></div>
        </div>
        <Button
          variant={'text'}
          sx={{
            color: '#76767F',
            textTransform: 'none',
            fontSize: '13px',
            position: 'absolute',
            top: '6px',
            right: '4px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
          startIcon={<ViewIcon color='#76767F' size='18' />}
          onClick={() => onPreviewTemplate(el.id, true)}
        ><span>View</span> </Button>
      </div>
      <div className='row row-start'>
        <CustomCheckbox checked={selectedInternalTemplates.map(item => item.id).includes(el.id)} onClick={() => { setSelectedInternalTemplates(toggleElementById(selectedInternalTemplates, el)) }} />
        <div className='column' style={{ marginLeft: '8px' }}>
          <p onClick={() => onPreviewTemplate(el.id, true)} className='templates-grid-item-title text-shrink-2'>{el.subject}</p>
          <p className='templates-grid-item-domain'>{el.sender}</p>
        </div>
      </div>
    </div>
  )
}



const Input = styled(TextField)(() => ({
  border: '1px solid #E7E7EE',
  padding: '10px 16px',
  fontSize: '16px',
  '& .MuiFormLabel-root': {
    fontSize: '16px',
    left: '16px',
    top: '4px',
  },
  '& .MuiFormLabel-root.MuiFormLabel-filled': {
    top: '10px',
    fontSize: '14px',
  },
  '& .MuiFormLabel-root.Mui-focused': {
    fontSize: '14px',
    color: '#76767F',
    top: '10px',
    // left: '16px',
  },
  '& .MuiInput-root:before': {
    display: 'none'
  },
  '& .MuiInput-root:after': {
    display: 'none'
  },
}))

export const timelineOptions = [
  {
    name: 'Immediately',
    value: 'IMMEDIATELY',
  },
  {
    name: 'Throughout the Day',
    value: 'THROUGHOUT_DAY',
  },
  {
    name: 'Throughout the Week',
    value: 'THROUGHOUT_WEEK',
  },
  {
    name: 'Throughout the Month',
    value: 'THROUGHOUT_MONTH',
  },
  {
    name: 'Throughout 3 Months',
    value: 'THROUGHOUT_3_MONTHS',
  },
  {
    name: 'Throughout 6 Months',
    value: 'THROUGHOUT_6_MONTHS',
  },
  {
    name: 'Throughout the Year',
    value: 'THROUGHOUT_YEAR',
  },
]


const CampaignCreate = ({ selectedId, isEdit = false, handleClose = () => { }, campaignsCount = 0 }) => {
  const [form, setForm] = useState({})

  const [includeHeader, setIncludeHeader] = useState(false)
  const [header, setHeader] = useState('')
  const [headerName, setHeaderName] = useState('')

  const [loading, setLoading] = useState(true)
  const [openTemplatePreview, setOpenTemplatePreview] = useState(false)
  const [openInternalTemplatePreview, setOpenInternalTemplatePreview] = useState(false)

  const [allCustomTemplates, setAllCustomTemplates] = useState(null)
  const [allTemplates, setAllTemplates] = useState([])

  // const [pack, setPack] = useState(null)
  const anchorRef = useRef(null);
  const [errors, setErrors] = useState(null);

  const [openMainInfo, setOpenMainInfo] = useState(true);
  const [openModulesForm, setOpenModulesForm] = useState(false);
  const [openUsersStep, setOpenUsersStep] = useState(false);

  // const [search, setSearch] = useState('')
  const [selectedCustomTemplates, setSelectedCustomTemplates] = useState([])
  const [selectedTemplates, setSelectedTemplates] = useState([])
  const [selectedTemplateId, setSelectedTemplateId] = useState(null)
  const [openCreateTemplate, setOpenCreateTemplate] = useState(false)

  const [disableLoadMore, setDisableLoadMore] = useState(false)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)

  const [selectedUsers, setSelectedUsers] = useState([]);

  const debouncedSearchTerm = useDebounce(search, 500);

  const initialTemplatesFilter = { active: true, includeBody: true, size: 8 }

  const onFilter = (filter) => {
    templatesService.getTemplates({ ...initialTemplatesFilter, ...filter }).then(resp => {
      setAllTemplates([...selectedTemplates, ...resp.data.filter(el => !selectedTemplates.map(item => item.id).includes(el.id))])
      setPage(1)
    })
  }

  useEffect(
    () => {
      if (debouncedSearchTerm) {
        onFilter({ searchText: search })
      } else {
        onFilter({ searchText: '' })
        setDisableLoadMore(false)
      }
    },
    [debouncedSearchTerm]
  );


  useEffect(() => {
    if (selectedId && isEdit) {
      simulationCampaignsService.getSimulationCampaign(selectedId).then(resp => {
        let preselectedTemplates = []

        setForm({ ...resp.data })
        if (resp.data.headers) {
          setIncludeHeader(true)
          setHeader(resp.data.headers[0].value || '')
          setHeaderName(resp.data.headers[0].name || '')
        }
        setSelectedCustomTemplates(resp.data?.customTemplates?.map(el => el.id) || [])
        setSelectedTemplates(resp.data?.internalTemplates || [])
        setSelectedUsers(resp.data?.userIds)

        preselectedTemplates = resp.data?.internalTemplates || []

        Promise.all([
          templatesService.getCustomTemplates(''),
          templatesService.getTemplates(initialTemplatesFilter),
          companyService.getCompanyConfigsSim()
        ]).then((values) => {
          setLoading(false)
          const [customTemplatesData, templatesData, configsResp] = values
          if (!resp.data.headers) {
            setHeader(configsResp.data.headerValue || '')
            setHeaderName(configsResp.data.headerName || '')
          }
          setAllCustomTemplates(customTemplatesData.data)
          setAllTemplates([...preselectedTemplates, ...templatesData.data.filter(el => !preselectedTemplates.map(item => item.id).includes(el.id))])
        }).catch(err => {
          console.error(err);
        })

      }).catch(err => {
        setLoading(false)
        console.error(err);
      })
    } else {
      setForm({ name: 'Campaign #' + (campaignsCount + 1), groupIds: [] })
      setLoading(false)
      Promise.all([
        templatesService.getCustomTemplates(''),
        templatesService.getTemplates(initialTemplatesFilter),
        companyService.getCompanyConfigsSim()
      ]).then((values) => {
        setLoading(false)

        const [customTemplatesData, templatesData, configsResp] = values
        setHeader(configsResp.data.headerValue || '')
        setHeaderName(configsResp.data.headerName || '')
        setAllCustomTemplates(customTemplatesData.data)
        setAllTemplates(templatesData.data)
      }).catch(err => {
        console.error(err);
      })
    }
  }, [])

  const handleNextPage = () => {
    templatesService.getTemplates({ ...initialTemplatesFilter, page: page + 1 }).then(resp => {
      if (resp.data?.length < 8) {
        setDisableLoadMore(true)
      }
      if (disableLoadMore) {
        setDisableLoadMore(false)
      }
      setAllTemplates([...allTemplates, ...resp.data.filter(el => !selectedTemplates.map(item => item.id).includes(el.id))])
      setPage(page + 1)
    })
  }

  const onOpenCreateTemplate = () => {
    setOpenCreateTemplate(true)
  }

  const updateTemplatesList = () => {
    templatesService.getCustomTemplates('').then(resp => {
      setAllCustomTemplates(resp.data)
    })
  }

  const handleSaveForm = () => {
    const campaignBody = {
      name: form.name.replace(/^\s+/, ''),
      startDate: form.startDate,
      timeline: form.timeline,
      customTemplateIds: selectedCustomTemplates || [],
      internalTemplateIds: selectedTemplates.map(item => item.id) || [],
      ...((form.groupIds && form.groupIds.length) && { groupIds: form.groupIds }),
      userIds: selectedUsers,
      ...(includeHeader && {
        headers: [{
          name: headerName,
          value: header,
        }]
      })
    }

    if (!campaignBody.name) {
      setErrors(errors ? { ...errors, name: 'Name is required' } : { name: 'Name is required' })
      return;
    }


    if (isEdit) {
      simulationCampaignsService.updateSimulationCampaignById(selectedId, campaignBody).then(() => {
        handleClose('updated')
      })
    } else {
      simulationCampaignsService.addSimulationCampaign(campaignBody).then(() => {
        handleClose('created')
      })

    }
  }

  const handleMainNextStep = () => {
    setOpenMainInfo(false)
    setOpenModulesForm(true)
  }

  const handleEditMainInfo = () => {
    setOpenMainInfo(true)
    setOpenModulesForm(false)
  }
  const handleEditModules = () => {
    setOpenMainInfo(false)
    setOpenModulesForm(true)
    setOpenUsersStep(false)
  }
  const handleEditGroups = () => {
    setOpenMainInfo(false)
    setOpenModulesForm(false)
    setOpenUsersStep(true)
  }


  const handleModulesPrevStep = () => {
    setOpenMainInfo(true)
    setOpenModulesForm(false)
  }
  const handleModulesNextStep = () => {
    setOpenMainInfo(false)
    setOpenModulesForm(false)
    setOpenUsersStep(true)
  }
  const handleGroupsPrevStep = () => {
    setOpenModulesForm(true)
    setOpenUsersStep(false)
  }
  const handleGroupsNextStep = () => {
    setOpenUsersStep(false)
  }

  const handleGroupSelect = (id, bulk) => {
    if (Array.isArray(id)) {

      let newItems = form.groupIds
      if (typeof bulk !== 'undefined') {
        id.forEach(element => {
          newItems = bulk ? newItems.includes(element) ? newItems.filter(el => el !== element) : newItems : [...newItems, element]
        });
      }

      setForm({ ...form, groupIds: newItems })
    } else {
      setForm({ ...form, groupIds: toggleElement(form.groupIds, id) })
    }
  }
  const handleUserSelect = (id, bulk) => {
    if (Array.isArray(id)) {

      let newItems = selectedUsers
      if (typeof bulk !== 'undefined') {
        id.forEach(element => {
          newItems = bulk ? newItems.includes(element) ? newItems.filter(el => el !== element) : newItems : [...newItems, element]
        });
      }

      setSelectedUsers(newItems)
    } else {
      setSelectedUsers(toggleElement(selectedUsers, id))
    }
  }

  const hanldeSelectTimeline = (el) => {
    setForm({ ...form, timeline: timelineOptions.find(opt => opt.name == el).value })
  }

  const handleTemplateCreate = () => {
    setOpenCreateTemplate(false)
    updateTemplatesList()

  }

  const onEditTemplate = (id) => {
    setSelectedTemplateId(id)
    setOpenCreateTemplate(true)
  }

  const onPreviewTemplate = (id, isInternal) => {
    setSelectedTemplateId(id)
    if (isInternal) {
      setOpenInternalTemplatePreview(true)
    } else {

      setOpenTemplatePreview(true)
    }
  }

  const onCancel = () => {
    handleClose()
  }

  const templatesFormInvalid = !selectedCustomTemplates?.length && !selectedTemplates?.length
  const mainFormInvalid = !form.name || !form.timeline
  const groupsFormInvalid = !form.groupIds?.length && !selectedUsers?.length

  const formInvalid = groupsFormInvalid || templatesFormInvalid || !form.name

  return (
    <>
      <div style={{ height: 'calc(100vh - 110px)', overflowY: 'auto' }}>
        <div className='column' style={{ padding: '32px' }}>
          <div className='row' style={{ width: '900px' }}>
            {/* <Button startIcon={<BackIcon />} >Back to Create New Package</Button> */}
          </div>
          <p className="drawer-title">{isEdit ? 'Edit' : 'Create new'} campaign</p>
          {loading ? <LoadingIndicator /> : <>

            <Collapse sx={{
              background: '#fff',
              borderRadius: '10px',
              padding: '12px 32px 32px 32px',
              marginBottom: '16px'
            }} in={openMainInfo} collapsedSize={105}>
              <div className='column'>
                {!openMainInfo &&
                  <div className='row' style={{ height: '120px', padding: '10px 0px' }}>
                    <div style={{
                      width: '24px',
                      height: '24px',
                      borderRadius: '24px',
                      background: 'linear-gradient(264.92deg, #00BA97 4.82%, #00BC62 73.44%)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginRight: '16px',
                    }}>
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.1876 5.05469L7.31254 12.7267L3.375 8.89086" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>


                    <div style={{ marginRight: '36px', width: '430px' }} className='column'>
                      <p style={{ fontFamily: 'Satoshi', fontSize: '24px', marginBottom: '10px' }}>{form.name}</p>
                      <p style={{ color: '#76767F', marginBottom: '12px' }}>Timeline: {form?.timeline && timelineOptions.find(el => el.value == form.timeline).name}, {dayjs(form.startDate).format('MMM DD, YYYY')}</p>

                    </div>


                    {/* <div className='row'>
                    <svg style={{ margin: '5px 10px' }} width="8" height="39" viewBox="0 0 8 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4 4V32" stroke="#C7EFF4" />
                      <circle cx="4" cy="4" r="4" fill="#C7EFF4" />
                      <circle cx="4" cy="35" r="4" fill="#C7EFF4" />
                    </svg>

                    <div className='column'>
                      <p style={{ marginBottom: '10px' }}>{dayjs(form.startDate).format('MMM DD, YYYY')}</p>
                      <p>{dayjs(form.endDate).format('MMM DD, YYYY')}</p>
                    </div>
                  </div> */}

                    <Spacer />


                    <div>
                      <Button
                        variant='outlined'
                        onClick={handleEditMainInfo}
                        sx={{
                          borderColor: '#E7E7EE',
                          borderRadius: '12px',
                          color: '#6123FF',
                          textTransform: 'none',
                          flex: '1',
                          mr: '12px'
                        }}
                        startIcon={<EditIcon />}
                      >
                        Edit
                      </Button>
                    </div>

                  </div>}
                <p className='drawer-subtitle'>Main info</p>
                <Input
                  error={errors?.name}
                  disabled={form.status == 'ARCHIVED'}
                  sx={{ borderRadius: '20px', mb: '12px' }}
                  label='Name'
                  variant='standard'
                  value={form?.name || ''}
                  onChange={(e) => {
                    setForm({ ...form, name: e.target.value })
                    setErrors(null)
                  }}
                />
                {errors && errors?.name && <p style={{ color: '#B40020', margin: '-4px 0px 10px 16px' }}>{errors.name}</p>}

                <div className='row row-center'>

                  <p className='drawer-subtitle' style={{ marginRight: 20 }}>Campaign duration</p>

                  <div className='row row-center'>
                    <DatePicker
                      format={'DD/MM/YYYY'}
                      disabled={form.status == 'ARCHIVED'}
                      sx={{ marginRight: '20px', '& .MuiOutlinedInput-root': { borderRadius: '10px' }, '& .MuiOutlinedInput-input': { padding: '12.5px 14px' } }}
                      minDate={dayjs(new Date())}
                      value={dayjs(form.startDate)}
                      label="Start Date"
                      onChange={(e) => setForm({ ...form, startDate: dayjs(e).format('YYYY-MM-DD') })}
                    />
                    {/* <DatePicker minDate={dayjs(form.startDate)} disabled={form.status == 'ARCHIVED'} sx={{ marginRight: '20px', '& .MuiOutlinedInput-root': { borderRadius: '10px' }, '& .MuiOutlinedInput-input': { padding: '12.5px 14px' } }} value={dayjs(form.endDate)} label="End Date" onChange={(e) => setForm({ ...form, endDate: dayjs(e).format('YYYY-MM-DD') })} /> */}
                    <RadioSelect style={{
                      width: '300px',
                      backgroundColor: '#fff',
                      border: '1px solid #E7E7EE',
                      borderRadius: '14px',
                      marginRight: '16px',
                      height: '48px'
                    }}
                      options={timelineOptions.map(el => el.name)}
                      value={form.timeline ? timelineOptions.find(opt => opt.value == form.timeline)?.name : ''}
                      placeholder={'Timeline'}
                      onSelectOption={hanldeSelectTimeline}
                    />
                  </div>

                </div>

                <div className="column" style={{ margin: '10px 0px 40px 0px' }}>
                  <div className='row row-center' style={{ marginBottom: 20 }}>
                    <CustomCheckbox checked={includeHeader} onClick={() => setIncludeHeader(!includeHeader)} />
                    <p style={{ marginRight: 20 }}>Include header</p>
                  </div>
                  {includeHeader && <div className='row row-center' style={{ position: 'relative', width: '400px' }}>
                    <InputText
                      fullWidth
                      sx={{ borderRadius: '20px' }}
                      label={`Header (${headerName})`}
                      variant='standard'
                      onChange={(e) => {
                        setHeader(e.target.value)
                      }}
                      value={header}
                    />
                    <div style={{ position: 'absolute', top: 25, right: 10 }}>
                      <Info text={'Define the email header for internal identification of our phishing simulations by your security team.'} />
                    </div>
                  </div>}
                </div>

                {form?.timeline && <div className='row row-center' style={{ marginTop: 10 }}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.2" d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#1B1B26" />
                    <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#1B1B26" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11.25 11.25H12V16.5H12.75" stroke="#1B1B26" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M12 9C12.6213 9 13.125 8.49632 13.125 7.875C13.125 7.25368 12.6213 6.75 12 6.75C11.3787 6.75 10.875 7.25368 10.875 7.875C10.875 8.49632 11.3787 9 12 9Z" fill="#1B1B26" />
                  </svg>


                  {form.timeline == 'IMMEDIATELY' ? <p style={{ maxWidth: 700 }}>All attacks will be sent right now.</p>
                    : <p style={{ maxWidth: 700 }}>All selected simulations will be sent out at equal intervals during the chosen period</p>}
                </div>
                }


                <div style={{ height: '1px', width: '100%', background: '#F1F1F5', margin: '20px 0px' }}></div>

                <div className='row' style={{ justifyContent: 'flex-end' }}>
                  <Button
                    disabled={mainFormInvalid}
                    variant={'contained'}
                    onClick={handleMainNextStep}
                    sx={{ background: '#6123FF', borderRadius: '12px', fontFamily: 'Plus Jakarta Sans', color: '#fff', textTransform: 'none', width: 270 }}
                  >Next Step</Button>
                </div>
              </div>


            </Collapse>


            <Collapse sx={{
              background: '#fff',
              borderRadius: '10px',
              padding: '12px 32px 32px 32px',
              marginBottom: '16px'
            }} in={openModulesForm} collapsedSize={80}>
              {!openModulesForm && templatesFormInvalid && <>
                <div className='row row-center'>
                  <div style={{
                    width: '24px',
                    height: '24px',
                    borderRadius: '24px',
                    background: '#6123FF',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '16px',
                    color: '#fff',
                    fontWeight: '600'
                  }}>
                    2
                  </div>
                  <p className='drawer-subtitle'>Choose templates</p>
                </div>
              </>}
              {!openModulesForm && !templatesFormInvalid && <>
                <div className='row row-center'>
                  <div style={{ width: '430px' }} className='row row-center'>
                    <div style={{
                      width: '24px',
                      height: '24px',
                      borderRadius: '24px',
                      background: 'linear-gradient(264.92deg, #00BA97 4.82%, #00BC62 73.44%)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginRight: '16px',

                    }}>
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.1876 5.05469L7.31254 12.7267L3.375 8.89086" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>
                    <p className='drawer-subtitle'>Templates</p>

                  </div>
                  <div>
                    <ValueAndUnit val={selectedCustomTemplates.length + selectedTemplates.length} unit={'Includes'} />
                  </div>
                  <Spacer />


                  <div>
                    <Button
                      variant='outlined'
                      onClick={handleEditModules}
                      sx={{
                        borderColor: '#E7E7EE',
                        borderRadius: '12px',
                        color: '#6123FF',
                        textTransform: 'none',
                        flex: '1',
                        mr: '12px'
                      }}
                      startIcon={<EditIcon />}
                    >
                      Edit
                    </Button>
                  </div>
                </div>
              </>}
              <div className='column'>
                <div className='row row-center'>
                  <p className='drawer-subtitle' style={{ marginRight: '10px' }}>Choose templates</p>
                  <SearchInput sx={{ width: '250px', margin: '0' }} onChange={(e) => setSearch(e.target.value)} value={search} size='small' placeholder='Search' />
                  <Spacer />
                  <Button
                    onClick={onOpenCreateTemplate}
                    startIcon={<PlusIcon />}
                    sx={{
                      borderColor: '#E7E7EE',
                      borderRadius: '12px',
                      color: '#6123FF',
                      textTransform: 'none',
                      flex: '1',
                      mr: '12px',
                      width: '300px',
                      flexShrink: 0,
                    }}
                    variant={'outlined'}
                  >
                    New Template
                  </Button>

                </div>

                <div style={{
                  maxHeight: 'calc(100vh - 520px)',
                  overflow: 'auto'
                }}>
                  {/* <SearchInput sx={{ margin: '20px 0px', width: '100%' }} onChange={(e) => setSearch(e.target.value)} value={search} size='small' placeholder='Search' /> */}

                  <div className='row templates-grid'>
                    {allCustomTemplates && allCustomTemplates.map(el =>
                      <div key={el.id} className={`templates-grid-item column ${selectedCustomTemplates.includes(el.id) && ' active'}`}>
                        <div className='templates-grid-item-img'>
                          <div onClick={(e) => { e.preventDefault() }}>
                            <div className='template-thumbbail' dangerouslySetInnerHTML={{ __html: el.body }} ></div>
                          </div>
                          {/* to fix editor first */}
                          <Button
                            variant={'text'}
                            sx={{
                              color: '#76767F',
                              textTransform: 'none',
                              fontSize: '13px',
                              position: 'absolute',
                              top: '4px',
                              right: '4px',
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center'
                            }}
                            startIcon={<EditIcon color='#76767F' size='18' />}
                            onClick={() => onEditTemplate(el.id)}
                          ><span>Edit</span> </Button>
                          <Button
                            variant={'text'}
                            sx={{
                              color: '#76767F',
                              textTransform: 'none',
                              fontSize: '13px',
                              position: 'absolute',
                              top: '36px',
                              right: '4px',
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center'
                            }}
                            startIcon={<ViewIcon color='#76767F' size='18' />}
                            onClick={() => onPreviewTemplate(el.id)}
                          ><span>View</span> </Button>
                        </div>
                        <div className='row row-start'>
                          <CustomCheckbox checked={selectedCustomTemplates.includes(el.id)} onClick={() => { setSelectedCustomTemplates(toggleElement(selectedCustomTemplates, el.id)) }} />
                          <div className='column' style={{ marginLeft: '8px' }}>
                            <p onClick={() => onPreviewTemplate(el.id)} className='templates-grid-item-title text-shrink-2'>{el.name}</p>
                            <p className='templates-grid-item-domain'>{el.sender}</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div style={{ width: '100%', marginBottom: 20 }} className='row row-center'>
                    <p style={{ color: '#aaa', marginRight: 20 }}>System templates</p>
                    <div style={{ flex: 1, height: '1px', background: '#bbb' }}></div>
                  </div>
                  <div className='row templates-grid'>

                    {allTemplates && allTemplates.map(el =>
                      <InternalTemplatesItem
                        key={el.id}
                        el={el}
                        onPreviewTemplate={onPreviewTemplate}
                        selectedInternalTemplates={selectedTemplates}
                        setSelectedInternalTemplates={setSelectedTemplates} />
                    )}
                  </div>

                  {!disableLoadMore && allTemplates?.length > 4 && <LoadNextPage onHandleNextPage={handleNextPage} />}


                </div>


                <div style={{ height: '1px', width: '100%', background: '#F1F1F5', margin: '20px 0px' }}></div>

                <div className='row row-center'>
                  <Button
                    variant={'outlined'}
                    onClick={handleModulesPrevStep}
                    sx={{ borderRadius: '12px', fontFamily: 'Plus Jakarta Sans', textTransform: 'none', width: 270 }}
                  >Previous Step</Button>
                  <Spacer />
                  <Button
                    disabled={templatesFormInvalid}
                    variant={'contained'}
                    onClick={handleModulesNextStep}
                    sx={{ background: '#6123FF', borderRadius: '12px', fontFamily: 'Plus Jakarta Sans', color: '#fff', textTransform: 'none', width: 270 }}
                  >Next Step</Button>
                </div>


              </div>
            </Collapse>



            <Collapse sx={{
              background: '#fff',
              borderRadius: '10px',
              padding: '12px 32px 32px 32px'

            }} in={openUsersStep} collapsedSize={80}>

              {!openUsersStep && groupsFormInvalid && <>
                <div className='row row-center'>
                  <div style={{
                    width: '24px',
                    height: '24px',
                    borderRadius: '24px',
                    background: '#6123FF',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '16px',
                    color: '#fff',
                    fontWeight: '600'
                  }}>
                    3
                  </div>
                  <p className='drawer-subtitle'>Groups/Users</p>
                </div>
              </>}
              {!openUsersStep && !groupsFormInvalid && <>
                <div className='row row-center'>
                  <div style={{ width: '430px' }} className='row row-center'>

                    <div style={{
                      width: '24px',
                      height: '24px',
                      borderRadius: '24px',
                      background: 'linear-gradient(264.92deg, #00BA97 4.82%, #00BC62 73.44%)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginRight: '16px',

                    }}>
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.1876 5.05469L7.31254 12.7267L3.375 8.89086" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>
                    <p className='drawer-subtitle'>Groups/Users</p>
                  </div>
                  <div className='row'>
                    {form.groupIds.length !== 0 && <ValueAndUnit val={(form.groupIds.length || 0)} unit={'Groups'} />}
                    {(form.groupIds.length !== 0 && selectedUsers.length !== 0) && <div style={{ width: 10 }}></div>}
                    {selectedUsers.length !== 0 && <ValueAndUnit val={(selectedUsers.length)} unit={'Users'} />}
                  </div>
                  <Spacer />


                  <div>
                    <Button
                      variant='outlined'
                      onClick={handleEditGroups}
                      sx={{
                        borderColor: '#E7E7EE',
                        borderRadius: '12px',
                        color: '#6123FF',
                        textTransform: 'none',
                        flex: '1',
                        mr: '12px'
                      }}
                      startIcon={<EditIcon />}
                    >
                      Edit
                    </Button>
                  </div>
                </div>
              </>}



              <div className='column'>
                <ChooseUsersGroups selectedGroups={form.groupIds} selectedUsers={selectedUsers} onSelectGroup={handleGroupSelect} onSelectUser={handleUserSelect} />

                <div style={{ height: '1px', width: '100%', background: '#F1F1F5', margin: '20px 0px' }}></div>

                <div className='row row-center'>
                  <Button
                    variant={'outlined'}
                    onClick={handleGroupsPrevStep}
                    sx={{ borderRadius: '12px', fontFamily: 'Plus Jakarta Sans', textTransform: 'none', width: 270 }}
                  >Previous Step</Button>
                  <Spacer />
                  <Button
                    disabled={groupsFormInvalid}
                    variant={'contained'}
                    onClick={handleGroupsNextStep}
                    sx={{ background: '#6123FF', borderRadius: '12px', fontFamily: 'Plus Jakarta Sans', color: '#fff', textTransform: 'none', width: 270 }}
                  >Next Step</Button>
                </div>


              </div>
            </Collapse>
          </>}



        </div>

      </div>

      <div style={{ bottom: '0', right: '0', background: '#fff', padding: '25px 32px' }} className='row'>
        <div style={{ width: '50%', padding: '0px 10px' }}>
          <Button sx={{ color: '#6123FF', borderColor: '#E7E7EE', textTransform: 'none', height: '60px', width: '100%' }} onClick={onCancel} variant={'outlined'}>Cancel</Button>
        </div>
        <div style={{ width: '50%', padding: '0px 10px' }}>
          <ButtonGroup sx={{ color: '#fff', textTransform: 'none', height: '60px', width: '100%' }} variant="contained" ref={anchorRef} aria-label="split button">
            <Button sx={{ color: '#fff', background: '#6123FF', textTransform: 'none', width: '100%' }} disabled={formInvalid} onClick={() => handleSaveForm(false)}>Save</Button>
          </ButtonGroup>

        </div>
      </div>

      <Drawer sx={{ '& .MuiDrawer-paper': { background: '#F8F8FB' } }} anchor='right' open={openCreateTemplate} onClose={() => {
        setOpenCreateTemplate(false)
        setSelectedTemplateId(null)
      }}>
        <div
          style={{ position: 'fixed', top: '24px', right: '922px', cursor: 'pointer' }}
          onClick={() => {
            setOpenCreateTemplate(false)
            setSelectedTemplateId(null)
          }}
        >
          <CloseDrawer />
        </div>
        <TemplateCreate selectedTemplateId={selectedTemplateId} onCreate={handleTemplateCreate} />
      </Drawer >

      <Drawer anchor='right' open={openTemplatePreview} onClose={() => {
        setOpenTemplatePreview(false)
        setSelectedTemplateId(null)
      }}>
        <div
          style={{ position: 'fixed', top: '24px', right: '1182px', cursor: 'pointer' }}
          onClick={() => {
            setOpenTemplatePreview(false)
            setSelectedTemplateId(null)
          }}
        >
          <CloseDrawer />
        </div>
        <CustomTemplatePreview id={selectedTemplateId} />
      </Drawer>
      <Drawer anchor='right' open={openInternalTemplatePreview} onClose={() => {
        setOpenInternalTemplatePreview(false)
        setSelectedTemplateId(null)
      }}>
        <div
          style={{ position: 'fixed', top: '24px', right: '1182px', cursor: 'pointer' }}
          onClick={() => {
            setOpenInternalTemplatePreview(false)
            setSelectedTemplateId(null)
          }}
        >
          <CloseDrawer />
        </div>
        <TemplateView id={selectedTemplateId} />
      </Drawer>
    </>
  )
}

export default CampaignCreate
