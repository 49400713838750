import React from 'react'

const AddUserIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        opacity='0.2'
        d='M12 15C15.3137 15 18 12.3137 18 9C18 5.68629 15.3137 3 12 3C8.68629 3 6 5.68629 6 9C6 12.3137 8.68629 15 12 15Z'
        fill='white'
      />
      <path d='M16.5 5.25H21' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M18.75 3V7.5' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M2.9043 20.2491C3.82638 18.6531 5.15225 17.3278 6.74869 16.4064C8.34513 15.485 10.1559 15 11.9992 15C13.8424 15 15.6532 15.4851 17.2497 16.4065C18.8461 17.3279 20.1719 18.6533 21.094 20.2493'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.6302 11.0796C17.2291 12.1625 16.5239 13.1066 15.5993 13.7984C14.6747 14.4902 13.5701 14.9004 12.418 14.9796C11.266 15.0588 10.1155 14.8037 9.10495 14.245C8.09437 13.6862 7.26659 12.8476 6.72108 11.8298C6.17557 10.812 5.93553 9.65831 6.02978 8.5074C6.12403 7.35648 6.54857 6.25726 7.2524 5.34177C7.95623 4.42629 8.90942 3.73348 9.99745 3.34659C11.0855 2.9597 12.2621 2.89518 13.3859 3.16077'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default AddUserIcon
