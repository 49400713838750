import React from "react";

import './style.css'

export function SearchInput({ value, onChange = () => { }, width = 470, placeholder = 'Search' }) {
  const handleChange = (e) => {
    onChange(e.target.value)
  }

  return (
    <div style={{ position: 'relative', width: width }}>
      <input
        className="searchInput"
        type="text"
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
      />

      <svg style={{ position: "absolute", right: 13, top: 8 }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.0161 8.87639C16.0161 12.6266 12.9759 15.6668 9.22563 15.6668C5.47537 15.6668 2.43518 12.6266 2.43518 8.87639C2.43518 5.12612 5.47537 2.08594 9.22563 2.08594C12.9759 2.08594 16.0161 5.12612 16.0161 8.87639Z" stroke="#1B1B26" />
        <path d="M13.8688 13.8438L17.3357 17.3107" stroke="#1B1B26" strokeLinecap="round" strokeLinejoin="round" />
      </svg>

    </div>
  )
}