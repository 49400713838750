import axios from 'axios';
import authHeader from './auth-header';
import { compactObject } from 'src/heplers';
import api from './api.service';

const API_URL = window._env_.REACT_APP_API_HOST;

class UserService {
  getUser(id) {
    return api.get(API_URL + '/v1/users/' + id, { headers: authHeader() });
  }
  getAccountData() {
    return axios.get(API_URL + '/v1/account', { headers: authHeader() });
  }
  updateAccountData(accountData) {
    return axios.put(API_URL + '/v1/account', accountData, { headers: authHeader() });
  }
  onboarding(accountData) {
    return axios.put(API_URL + '/v1/account/onboarding', accountData, { headers: authHeader() });
  }
  getUsers(filters) {
    const params = new URLSearchParams(compactObject(filters))
    return axios.get(API_URL + '/v1/users', {
      headers: authHeader(), params
    });
  }
  getUsersByIds(ids, perPage = 50, page = 0, search, status) {
    return axios.post(API_URL + `/v1/users/search?status=ACTIVE&size=${perPage}&page=${page}`, { userIds: ids, ...(search && { searchText: search }), ...(status && { status: status }) }, { headers: authHeader(), });
  }
  delete(id) {
    return axios.delete(API_URL + '/v1/users/' + id, { headers: authHeader() });
  }
  deactivate(id) {
    return axios.post(API_URL + '/v1/users/' + id + '/deactivate', {}, { headers: authHeader() });
  }
  activate(id) {
    return axios.post(API_URL + '/v1/users/' + id + '/activate', {}, { headers: authHeader() });
  }
  deleteMany(ids) {
    return axios.delete(API_URL + '/v1/users/', { headers: authHeader(), data: ids },);
  }
  update(id, payload) {
    return axios.put(API_URL + '/v1/users/' + id, payload, { headers: authHeader() });
  }
  bulkAdd(payload) {
    return axios.post(API_URL + '/v1/users/bulk', payload, { headers: authHeader() });
  }
  manualAddUser(payload) {
    return axios.post(API_URL + '/v1/users', payload, { headers: authHeader() });
  }
  inviteAdmin(payload) {
    return axios.post(API_URL + '/v1/users/company-admin', payload, { headers: authHeader() });
  }
  updatePassword(newPassword) {
    return axios.post(API_URL + '/v1/account/change-password', { newPassword }, { headers: authHeader() });
  }
  finishUdatePassword(token, password) {
    return axios.post(API_URL + '/v1/account/reset-password/finish', { token, password }, { headers: authHeader() });
  }
  sendInvite(id) {
    return axios.post(API_URL + '/v1/users/' + id + '/welcome-emails', {}, { headers: authHeader() });
  }
  sendInvites(ids) {
    return axios.post(API_URL + '/v1/users/welcome-emails', ids, { headers: authHeader() });
  }
  bulkActivate(ids) {
    return axios.post(API_URL + '/v1/users/activate', ids, { headers: authHeader() });
  }
  bulkDeactivate(ids) {
    return axios.post(API_URL + '/v1/users/deactivate', ids, { headers: authHeader() });
  }
  sendInviteForAll() {
    return axios.post(API_URL + '/v1/users/welcome-emails/company', {}, { headers: authHeader() });
  }
}

export default new UserService();
