import React, { useContext, useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { styled } from '@mui/material/styles'
// import { SettingsContext } from "src/App";
import userService from "src/services/user.service";
import Spacer from "src/common/components/Spacer";
import DeleteIcon from "src/common/icons/deleteIcon";
import { SettingsContext } from "src/App";

const InputSelect = styled(Select)(() => ({
  // border: '1px solid #E7E7EE',
  padding: '10px 16px',
  fontSize: '16px',

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E7E7EE'
  },

  '& .MuiFormLabel-root.MuiFormLabel-filled': {
    top: '10px',
    fontSize: '14px',
  },
  '& .MuiFormLabel-root.Mui-focused': {
    fontSize: '14px',
    color: '#76767F',
    top: '10px',
    // left: '16px',
  },
  '& .MuiInput-root:before': {
    display: 'none'
  },
  '& .MuiInput-root:after': {
    display: 'none'
  },

  '& .MuiOutlinedInput-input': {
    padding: '7px 14px'
  },

}))

const InputText = styled(TextField)(() => ({
  border: '1px solid #E7E7EE',
  padding: '4px 16px',
  fontSize: '16px',
  marginBottom: '14px',
  '& .MuiFormLabel-root': {
    fontSize: '16px',
    left: '16px',
    top: '-2px',
  },
  '& .MuiFormLabel-root.MuiFormLabel-filled': {
    top: '10px',
    fontSize: '14px',
  },
  '& .MuiFormLabel-root.Mui-focused': {
    fontSize: '14px',
    color: '#76767F',
    top: '10px',
    // left: '16px',
  },
  '& .MuiInput-root:before': {
    display: 'none'
  },
  '& .MuiInput-root:after': {
    display: 'none'
  },

}))

export const ManualAddUserPopup = ({ open, onClose, onSuccess }) => {
  const [wasAdded, setWasAdded] = useState(false)
  const [form, setForm] = useState({})
  // const [formInitial, setFormInitial] = useState(true)
  // const [users, setUsers] = useState([])
  const [errors, setErrors] = useState(false)
  const [uploadError, setUploadError] = useState(false)

  const settings = useContext(SettingsContext)

  const validateForm = () => {
    let errors = {};

    if (!form.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(form.email)) {
      errors.email = 'Email is invalid';
    } else if (form.email.length > 100) {
      errors.email = 'The field can be no longer than 100 characters.';
    }

    if (!form.firstName) {
      errors.firstName = 'First Name is required';
    } else if (!/^[A-Za-zĄąĆćĘęŁłŃńÓóŚśŹźŻż-]+$/.test(form.firstName)) {
      errors.firstName = 'First Name can contain only Latin characters and "-" ';
    } else if (form.firstName.length > 40) {
      errors.firstName = 'The field can be no longer than 40 characters.';
    }

    if (!form.lastName) {
      errors.lastName = 'Last Name is required';
    } else if (!/^[A-Za-zĄąĆćĘęŁłŃńÓóŚśŹźŻż-]+$/.test(form.lastName)) {
      errors.lastName = 'Last Name can contain only Latin characters and "-" ';
    } else if (form.lastName.length > 40) {
      errors.lastName = 'The field can be no longer than 40 characters.';
    }


    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const resetForm = () => {
    setForm({})
    setErrors({})
    setUploadError(false)
  }

  // const addOne = () => {
  //   if (users.map(el => el.email).includes(form.email)) {
  //     setErrors({ email: 'This email already exists' })
  //     return
  //   }
  //   if (validateForm()) {
  //     setUsers([...users, form])
  //     resetForm()
  //     setFormInitial(true)
  //   }
  // }

  const hanldeSave = () => {
    const formIsEmpty = !form.email && !form.firstName && !form.lastName
    setWasAdded(true)

    if ((!formIsEmpty && validateForm())) {
      userService.manualAddUser(form).then(() => {
        // onClose()
        onSuccess()
        resetForm()
      }).catch(err => {
        console.error(err);
        setUploadError(err?.response?.data?.description || 'Something went wrong')
        if (err?.response?.data?.code == 'USER_ERROR_006') {
          setErrors({ email: 'Email already exists' })
        }
      })
      return
    }

    if (formIsEmpty) {
      validateForm()
    }

  }


  return (
    <Dialog sx={{ '& .MuiPaper-root': { borderRadius: '22px' } }} open={open} onClose={() => {
      onClose(wasAdded)
      resetForm()
      setWasAdded(false)

    }}>
      <DialogTitle sx={{
        fontFamily: 'Satoshi',
        fontStyle: 'normal',
        fontWeight: '900',
        fontSize: '35px',
        lineHeight: '110%',
        color: '#1B1B26',
      }}>Add Users Manually</DialogTitle>
      <DialogContent sx={{ width: '600px' }} >
        <p style={{ marginBottom: '25px' }}>Adding multiple users by their name and email</p>


        <InputText
          // disabled
          fullWidth
          sx={{ borderRadius: '20px', mb: '18px', ...(!!errors?.email && { borderColor: '#d32f2f' }) }}
          label='Email'
          variant='standard'
          error={!!errors?.email}
          onChange={(e) => {
            setForm({ ...form, email: e.target.value })
            setErrors({ ...errors, email: null })
          }}
          value={form?.email || ''}
        />
        {errors.email && <p style={{ color: '#d32f2f', fontSize: 12, marginLeft: '18px', marginTop: '-13px', marginBottom: 10 }}>{errors.email}</p>}
        <InputText
          fullWidth
          sx={{ borderRadius: '20px', mb: '18px', ...(!!errors?.firstName && { borderColor: '#d32f2f' }) }}
          label='First name'
          variant='standard'
          error={!!errors?.firstName}
          onChange={(e) => {
            setForm({ ...form, firstName: e.target.value })
            setErrors({ ...errors, firstName: null })
          }}
          value={form?.firstName || ''}
        />
        {errors.firstName && <p style={{ color: '#d32f2f', fontSize: 12, marginLeft: '18px', marginTop: '-13px', marginBottom: 10 }}>{errors.firstName}</p>}
        <InputText
          fullWidth
          sx={{ borderRadius: '20px', mb: '18px', ...(!!errors?.lastName && { borderColor: '#d32f2f' }) }}
          label='Last name'
          variant='standard'
          error={!!errors?.lastName}
          onChange={(e) => {
            setForm({ ...form, lastName: e.target.value })
            setErrors({ ...errors, lastName: null })
          }}
          value={form?.lastName || ''}
        />
        {errors.lastName && <p style={{ color: '#d32f2f', fontSize: 12, marginLeft: '18px', marginTop: '-13px', marginBottom: 10 }}>{errors.lastName}</p>}
        <FormControl fullWidth>
          <InputLabel sx={{ top: '2px' }} id="Department">Department (optional)</InputLabel>
          <InputSelect
            sx={{ borderRadius: '20px', mb: '18px' }}
            // error={onboardingError.department}
            labelId="Department"
            value={form?.department || ''}
            label="Department (optional)"
            fullWidth
            placeholder="Department"
            onChange={(e) => {
              setForm({ ...form, department: e.target.value })
            }}
          >
            {settings?.departments?.map(el => (
              <MenuItem key={el} value={el}>{el}</MenuItem>
            ))}
          </InputSelect>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel sx={{ top: '2px' }} id="Location">Location (optional)</InputLabel>
          <InputSelect
            sx={{ borderRadius: '20px', mb: '18px' }}
            // error={onboardingError.department}
            labelId="Location"
            value={form?.country || ''}
            label="Location (optional)"
            fullWidth
            placeholder="Location"
            onChange={(e) => {
              setForm({ ...form, country: e.target.value })
            }}
          >
            {settings?.countries?.map(el => (
              <MenuItem key={el} value={el}>{el}</MenuItem>
            ))}
          </InputSelect>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel sx={{ top: '2px' }} id="Language">Language (optional)</InputLabel>
          <InputSelect
            sx={{ borderRadius: '20px', mb: '18px' }}
            // error={onboardingError.department}
            labelId="Language"
            value={form?.language || ''}
            label="Language (optional)"
            fullWidth
            placeholder="Language"
            onChange={(e) => {
              setForm({ ...form, language: e.target.value })
            }}
          >
            {settings?.languages?.map(el => (
              <MenuItem key={el} value={el}>{el}</MenuItem>
            ))}
          </InputSelect>
        </FormControl>

        <p className="error-text">{uploadError}</p>


      </DialogContent>
      <Divider />
      <div className='row'>
        <Button onClick={() => {
          onClose(wasAdded)
          resetForm()
          setWasAdded(false)
        }} sx={{ height: '60px', borderRadius: '14px', color: '#6123FF', borderColor: '#6123FF', flex: '1', margin: '24px 16px 24px 32px' }} variant={'outlined'} >Cancel</Button>
        <Button
          // disabled={users.length == 0}
          onClick={hanldeSave}
          sx={{ height: '60px', borderRadius: '14px', backgroundColor: '#6123FF', flex: '1', margin: '24px 32px 24px 0px' }}
          variant={'contained'} >Save</Button>
      </div>
    </Dialog>
  )
}