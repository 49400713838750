import React from 'react'

const ExpandIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g opacity='0.6'>
      <path opacity='0.2' d='M18.75 19.5L11.25 12L18.75 4.5V19.5Z' fill='#666' />
      <path d='M18.75 19.5L11.25 12L18.75 4.5V19.5Z' stroke='#666' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11.25 19.5L3.75 12L11.25 4.5' stroke='#666' strokeLinecap='round' strokeLinejoin='round' />
    </g>
  </svg>
)

export default ExpandIcon
