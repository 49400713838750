import api from './api.service';
import authHeader from './auth-header';

class WorkflowsService {
  getWorkflows() {
    return api.get('/v1/workflows', { headers: authHeader() });
  }

  getWorkflow(id) {
    return api.get('/v1/workflows/' + id, { headers: authHeader() });
  }

  deleteWorkflow(id) {
    return api.delete('/v1/workflows/' + id, { headers: authHeader() });
  }

  getWorkflowLogs(id) {
    return api.get('/v1/workflows/' + id + '/logs', { headers: authHeader() });
  }

  getWorkflowUsers(id) {
    return api.get('/v1/workflows/' + id + '/users', { headers: authHeader() });
  }

  getTriggers() {
    return api.get('/v1/workflows/triggers', { headers: authHeader() });
  }

  addWorkflow(data) {
    return api.post('/v1/workflows', data, { headers: authHeader() });
  }

  updateWorkflow(id, data) {
    return api.put('/v1/workflows/' + id, data, { headers: authHeader() });
  }

  updateStatus(id, status) {
    return api.put('/v1/workflows/' + id + '/status', { status: status }, { headers: authHeader() });
  }
}

export default new WorkflowsService();
