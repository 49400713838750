import { Button, TextField, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { styled } from '@mui/material/styles'
import React, { useContext, useEffect, useState } from "react";
import { SnackBarContext } from "src/App";
import CyberSwitch from "src/common/components/Switch";
import companyService from "src/services/company.service";
import settingsService from "src/services/settings.service";
import Info from "src/common/components/Info";

import { ExternalLink } from "..";
import CustomCheckbox from "src/common/components/CustomCheckbox";

export const InputText = styled(TextField)(() => ({
  border: '1px solid #E7E7EE',
  padding: '10px 16px',
  fontSize: '16px',
  '& .MuiFormLabel-root': {
    fontSize: '16px',
    left: '16px',
    top: '4px',
  },
  '& .MuiFormLabel-root.MuiFormLabel-filled': {
    top: '10px',
    fontSize: '14px',
  },
  '& .MuiFormLabel-root.Mui-focused': {
    fontSize: '14px',
    color: '#76767F',
    top: '10px',
    // left: '16px',
  },
  '& .MuiInput-root:before': {
    display: 'none'
  },
  '& .MuiInput-root:after': {
    display: 'none'
  },

}))

const PauseIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.2" d="M18.75 3.75H15.375C14.9608 3.75 14.625 4.08579 14.625 4.5V19.5C14.625 19.9142 14.9608 20.25 15.375 20.25H18.75C19.1642 20.25 19.5 19.9142 19.5 19.5V4.5C19.5 4.08579 19.1642 3.75 18.75 3.75Z" fill="#6123FF" />
    <path opacity="0.2" d="M8.625 3.75H5.25C4.83579 3.75 4.5 4.08579 4.5 4.5V19.5C4.5 19.9142 4.83579 20.25 5.25 20.25H8.625C9.03921 20.25 9.375 19.9142 9.375 19.5V4.5C9.375 4.08579 9.03921 3.75 8.625 3.75Z" fill="#6123FF" />
    <path d="M18.75 3.75H15.375C14.9608 3.75 14.625 4.08579 14.625 4.5V19.5C14.625 19.9142 14.9608 20.25 15.375 20.25H18.75C19.1642 20.25 19.5 19.9142 19.5 19.5V4.5C19.5 4.08579 19.1642 3.75 18.75 3.75Z" stroke="#6123FF" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.625 3.75H5.25C4.83579 3.75 4.5 4.08579 4.5 4.5V19.5C4.5 19.9142 4.83579 20.25 5.25 20.25H8.625C9.03921 20.25 9.375 19.9142 9.375 19.5V4.5C9.375 4.08579 9.03921 3.75 8.625 3.75Z" stroke="#6123FF" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

)


export const PhishingSimulations = () => {
  const [enabled, setEnabled] = useState(false);
  const [header, setHeader] = useState('');
  const [headerName, setHeaderName] = useState('');
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [showSaveThreatsButton, setShowSaveThreatsButton] = useState(false);
  const [loading, setLoading] = useState(false);


  const [form, setForm] = useState({
    ceo: '',
    it: '',
    marketing: '',
    hr: '',
    finance: '',
    legalEmployeeName: '',
  })
  const [confirmed, setConfirmed] = useState(false)
  const [showPauseDialog, setShowPauseDialog] = useState(false)
  const [spoofingCampaignEnabled, setSpoofingCampaignEnabled] = useState(false)
  const [domains, setDomains] = useState([])
  const [threatsEmail, setThreatsEmail] = useState('')

  const inProcess = false
  const unavailable = false



  const { setErrorMessage, setSuccessMessage } = useContext(SnackBarContext)

  useEffect(() => {
    setLoading(true)
    Promise.all([
      companyService.getCompanyConfigsSim(),
      companyService.getCompanyConfigs(),
      companyService.getCompanyConfigsThreats(),
    ]).then((values) => {
      setLoading(false)
      const [configsRespSim, configsResp, threatsResp] = values
      setEnabled(configsRespSim.data.enabled || false)
      setHeader(configsRespSim.data.headerValue || '')
      setHeaderName(configsRespSim.data.headerName || '')
      setThreatsEmail(threatsResp.data.notificationEmails || '')

      setForm({
        ceo: configsResp.data.ceoEmployeeName,
        it: configsResp.data.itEmployeeName,
        marketing: configsResp.data.marketingEmployeeName,
        hr: configsResp.data.hrEmployeeName,
        finance: configsResp.data.financeEmployeeName,
        legalEmployeeName: configsResp.data.legalEmployeeName,
      })
      setDomains(configsResp.data.domains || [])
      setSpoofingCampaignEnabled(configsResp.data.spoofingCampaignEnabled)
      setConfirmed(configsResp.data.domainsWhitelisted)

    }).catch(() => {
      setErrorMessage('Something went wrong')
    })

  }, [])

  const enableSimulations = () => {
    setLoading(true)
    companyService.updateCompanyConfigsSim({ enabled: !enabled, headerValue: header }).then(() => {
      setLoading(false)
      setEnabled(!enabled)
    }).catch((err) => {
      console.error(err);
    })
  }
  const updateHeader = () => {
    setLoading(true)
    companyService.updateCompanyConfigsSim({ enabled: enabled, headerValue: header }).then(() => {
      setLoading(false)
      setShowSaveButton(false)
      setSuccessMessage('Phishing emails header updated')
    }).catch((err) => {
      console.error(err);
    })
  }
  const updateThreatsEail = () => {
    setLoading(true)
    companyService.updateCompanyConfigsThreats(threatsEmail).then(() => {
      setLoading(false)
      setShowSaveThreatsButton(false)
      setSuccessMessage('Threats email updated')
    }).catch((err) => {
      setErrorMessage('Something went wrong')
      console.error(err);
    })
  }

  const onConfirm = () => {
    setConfirmed(!confirmed)
  }

  const handleSendForm = () => {
    companyService.updateCompanyConfigs(
      {
        domainsWhitelisted: true,
        itEmployeeName: form.it,
        hrEmployeeName: form.hr,
        ceoEmployeeName: form.ceo,
        financeEmployeeName: form.finance,
        legalEmployeeName: form.legalEmployeeName,
        marketingEmployeeName: form.marketing,
        spoofingCampaignEnabled: true,
      }
    ).then(() => {
      setSpoofingCampaignEnabled(true)
      setSuccessMessage('The Spoofing Campaign has been started')
    })
  }

  const handlePauseCampaigns = () => {
    setShowPauseDialog(true)
  }
  const onPauseSpoofing = () => {
    companyService.updateCompanyConfigs(
      {
        domainsWhitelisted: true,
        itEmployeeName: form.it,
        hrEmployeeName: form.hr,
        ceoEmployeeName: form.ceo,
        financeEmployeeName: form.finance,
        legalEmployeeName: form.legalEmployeeName,
        marketingEmployeeName: form.marketing,
        spoofingCampaignEnabled: false,
      }
    ).then(() => {
      setSpoofingCampaignEnabled(false)
      setShowPauseDialog(false)
      setSuccessMessage('The Spoofing Campaign is on pause')
    })
  }


  return (
    <div className='settings-section'>
      <h2 >Phishing Simulations</h2>
      <div className="row row-center" style={{ margin: '10px 0px 40px 0px' }}>
        <CyberSwitch checked={enabled} onChange={enableSimulations} />

        <p style={{ marginLeft: 10, color: '#444444' }}>{enabled ? 'Enabled' : 'Disabled'}</p>
      </div>
      <h2 style={{ marginTop: 20 }}>Header</h2>
      <p style={{ marginBottom: 10 }}>Configure the Header for Phishing Simulation Emails</p>
      <div className="row row-center" style={{ margin: '10px 0px 40px 0px' }}>
        <div className='row row-center' style={{ position: 'relative', width: '400px' }}>
          <InputText
            disabled={!enabled}
            fullWidth
            sx={{ borderRadius: '20px' }}
            label={`Header (${headerName})`}
            variant='standard'
            onChange={(e) => {
              setHeader(e.target.value)
              setShowSaveButton(true)
            }}
            value={header}
          />
          <div style={{ position: 'absolute', top: 25, right: 10 }}>
            <Info text={'Define the email header for internal identification of our phishing simulations by your security team.'} />
          </div>
        </div>
        {showSaveButton && <Button sx={{ textTransform: 'none', color: '#6123FF', ml: '20px' }} onClick={updateHeader} >Update</Button>}
      </div>




      <h2>Internal Phishing Simulations</h2>
      <p style={{ maxWidth: '730px', lineHeight: '24px', marginBottom: 32 }}>The domains listed below will be used in the phishing simulation campaigns to create realistic spoofing scenarios, helping your team recognize and prevent actual phishing attempts.</p>
      <div style={{ position: 'relative' }} className="column">
        {(inProcess || unavailable) &&
          <div style={{ position: 'absolute', width: '100%', height: '100%', background: 'linear-gradient(180deg, #ffffffb0 0%, #ffffffd0 100%)' }}></div>
        }
        <p style={{ fontWeight: 600, marginBottom: 16 }}>Domain Information</p>
        <p style={{ maxWidth: '730px', lineHeight: '24px' }}>Please add the below domains to your whitelist to prevent simulation emails from being marked as spam. Confirm that you have completed this step before initiating the simulation.</p>
        <ExternalLink to={'https://helpcenter.cyberlift.ai/'} text={'Learn how to whitelist domains'} />
      </div>

      {unavailable && <div className="row" style={{ margin: '30px 0px' }}>
        <div>
          <svg style={{ marginRight: '35px' }} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.2" d="M39 16.5H9C8.60218 16.5 8.22064 16.658 7.93934 16.9393C7.65803 17.2206 7.5 17.6022 7.5 18V39C7.5 39.3978 7.65803 39.7794 7.93934 40.0607C8.22064 40.342 8.60218 40.5 9 40.5H39C39.3978 40.5 39.7794 40.342 40.0607 40.0607C40.342 39.7794 40.5 39.3978 40.5 39V18C40.5 17.6022 40.342 17.2206 40.0607 16.9393C39.7794 16.658 39.3978 16.5 39 16.5ZM24 30C23.2583 30 22.5333 29.7801 21.9166 29.368C21.2999 28.956 20.8193 28.3703 20.5355 27.6851C20.2516 26.9998 20.1774 26.2458 20.3221 25.5184C20.4667 24.791 20.8239 24.1228 21.3483 23.5983C21.8728 23.0739 22.541 22.7167 23.2684 22.5721C23.9958 22.4274 24.7498 22.5016 25.4351 22.7855C26.1203 23.0693 26.706 23.5499 27.118 24.1666C27.5301 24.7833 27.75 25.5083 27.75 26.25C27.75 27.2446 27.3549 28.1984 26.6516 28.9016C25.9484 29.6049 24.9946 30 24 30Z" fill="#8D8D94" />
            <path d="M24 30C26.0711 30 27.75 28.3211 27.75 26.25C27.75 24.1789 26.0711 22.5 24 22.5C21.9289 22.5 20.25 24.1789 20.25 26.25C20.25 28.3211 21.9289 30 24 30Z" stroke="#8D8D94" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M24 30V34.5" stroke="#8D8D94" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M39 16.5H9C8.17157 16.5 7.5 17.1716 7.5 18V39C7.5 39.8284 8.17157 40.5 9 40.5H39C39.8284 40.5 40.5 39.8284 40.5 39V18C40.5 17.1716 39.8284 16.5 39 16.5Z" stroke="#8D8D94" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.25 16.5V9.75C17.25 7.95979 17.9612 6.2429 19.227 4.97703C20.4929 3.71116 22.2098 3 24 3C25.7902 3 27.5071 3.71116 28.773 4.97703C30.0388 6.2429 30.75 7.95979 30.75 9.75V16.5" stroke="#8D8D94" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>

        </div>
        <div className="column column-start">
          <p style={{ lineHeight: '24px', marginBottom: '16px' }}>
            This functionality is not available in your current plan. <br />To activate it, please contact admin@cyberlift.ai.
          </p>
          <a className="row row-center" style={{ color: '#6123FF', textDecoration: 'none', border: '1px solid #E7E7EE', padding: '5px 34px', borderRadius: '12px' }} href="mailto:info@cyberlift.io">
            <svg style={{ marginRight: '8px' }} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.2" d="M21.5 5.25L12.5 13.5L3.5 5.25H21.5Z" fill="#6123FF" />
              <path d="M21.5 5.25L12.5 13.5L3.5 5.25" stroke="#6123FF" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M3.5 5.25H21.5V18C21.5 18.1989 21.421 18.3897 21.2803 18.5303C21.1397 18.671 20.9489 18.75 20.75 18.75H4.25C4.05109 18.75 3.86032 18.671 3.71967 18.5303C3.57902 18.3897 3.5 18.1989 3.5 18V5.25Z" stroke="#6123FF" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M10.8628 12L3.73047 18.538" stroke="#6123FF" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M21.2692 18.5381L14.1367 12" stroke="#6123FF" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            Contact us</a>
        </div>
      </div>}

      {inProcess && <div className="row" style={{ margin: '30px 0px' }}>
        <div>
          <svg style={{ marginRight: '35px' }} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.2" d="M24.0011 24.0009L33.9241 16.5H14L24.0011 24.0009Z" fill="#8D8D94" />
            <path d="M24 24L12.6 15.45C12.4137 15.3103 12.2625 15.1291 12.1584 14.9208C12.0542 14.7125 12 14.4829 12 14.25V7.5C12 7.10218 12.158 6.72064 12.4393 6.43934C12.7206 6.15804 13.1022 6 13.5 6H34.5C34.8978 6 35.2794 6.15804 35.5607 6.43934C35.842 6.72064 36 7.10218 36 7.5V14.1827C36 14.4146 35.9462 14.6434 35.8429 14.851C35.7396 15.0586 35.5895 15.2395 35.4045 15.3793L24 24Z" stroke="#8D8D94" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M24 24L12.6 32.55C12.4137 32.6897 12.2625 32.8709 12.1584 33.0792C12.0542 33.2875 12 33.5171 12 33.75V40.5C12 40.8978 12.158 41.2794 12.4393 41.5607C12.7206 41.842 13.1022 42 13.5 42H34.5C34.8978 42 35.2794 41.842 35.5607 41.5607C35.842 41.2794 36 40.8978 36 40.5V33.8173C36 33.5854 35.9462 33.3566 35.8429 33.149C35.7396 32.9414 35.5895 32.7605 35.4045 32.6207L24 24Z" stroke="#8D8D94" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M24 31.4992V24" stroke="#8D8D94" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 16.5H33.9242" stroke="#8D8D94" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>


        </div>
        <div className="column column-start">
          <p style={{ lineHeight: '24px', marginBottom: '16px', maxWidth: '453px' }}>
            We are in the process of acquiring domains similar to yours for the phishing simulation exercises. This process can take up to 7 business days.
          </p>
        </div>
      </div>}

      {!(inProcess || unavailable) && <>
        <div style={{ marginTop: 24, marginBottom: 48 }}>
          {domains.map((el, index) => (
            <div key={el} className='row row-center' style={{ marginBottom: 10 }}>
              <div style={{ width: 24, height: 24, borderRadius: 24, background: '#E7E7EE', justifyContent: 'center', marginRight: 12 }} className='column column-center row-center'>
                <span style={{ textAlign: 'center' }}>{index + 1}</span>
              </div>
              <p>{el}</p>
            </div>
          ))}
        </div>

        <p style={{ fontWeight: 600, marginBottom: 16 }}>Spoofing Details Form</p>

        <div className='row'>
          <div className='column' style={{ width: 375, marginRight: 16 }}>
            <div className='row row-center' style={{ position: 'relative' }}>
              <InputText
                disabled={spoofingCampaignEnabled}
                fullWidth
                sx={{ borderRadius: '20px', mb: '18px' }}
                label='CEO Employee F&L Name'
                variant='standard'
                onChange={(e) => { setForm({ ...form, ceo: e.target.value }) }}
                value={form?.ceo}
              />
              {form?.ceo && <div style={{ position: 'absolute', top: 25, right: 10 }}>
                <Info text={(`${form?.ceo.split(' ')[0]}${form?.ceo.split(' ')[1] ? ('.' + form?.ceo.split(' ')[1]) : ''}@${domains[0]}`).toLowerCase()} />
              </div>}
            </div>
            <div className='row row-center' style={{ position: 'relative' }}>
              <InputText
                disabled={spoofingCampaignEnabled}
                fullWidth
                sx={{ borderRadius: '20px', mb: '18px' }}
                label='IT Employee F&L Name'
                variant='standard'
                onChange={(e) => { setForm({ ...form, it: e.target.value }) }}
                value={form?.it}
              />
              {form?.it && <div style={{ position: 'absolute', top: 25, right: 10 }}>
                <Info text={(`${form?.it.split(' ')[0]}${form?.it.split(' ')[1] ? ('.' + form?.it.split(' ')[1]) : ''}@${domains[0]}`).toLowerCase()} />
              </div>}
            </div>
            <div className='row row-center' style={{ position: 'relative' }}>
              <InputText
                disabled={spoofingCampaignEnabled}
                fullWidth
                sx={{ borderRadius: '20px', mb: '18px' }}
                label='Marketing Employee F&L Name'
                variant='standard'
                onChange={(e) => { setForm({ ...form, marketing: e.target.value }) }}
                value={form?.marketing}
              />
              {form?.marketing && <div style={{ position: 'absolute', top: 25, right: 10 }}>
                <Info text={(`${form?.marketing.split(' ')[0]}${form?.marketing.split(' ')[1] ? ('.' + form?.marketing.split(' ')[1]) : ''}@${domains[0]}`).toLowerCase()} />
              </div>}
            </div>
          </div>
          <div className='column' style={{ width: 375 }}>
            <div className='row row-center' style={{ position: 'relative' }}>
              <InputText
                disabled={spoofingCampaignEnabled}
                fullWidth
                sx={{ borderRadius: '20px', mb: '18px' }}
                label='HR Employee F&L Name'
                variant='standard'
                onChange={(e) => { setForm({ ...form, hr: e.target.value }) }}
                value={form?.hr}
              />
              {form?.hr && <div style={{ position: 'absolute', top: 25, right: 10 }}>
                <Info text={(`${form?.hr.split(' ')[0]}${form?.hr.split(' ')[1] ? ('.' + form?.hr.split(' ')[1]) : ''}@${domains[0]}`).toLowerCase()} />
              </div>}
            </div>
            <div className='row row-center' style={{ position: 'relative' }}>
              <InputText
                disabled={spoofingCampaignEnabled}
                fullWidth
                sx={{ borderRadius: '20px', mb: '18px' }}
                label='Finance Employee F&L Name'
                variant='standard'
                onChange={(e) => { setForm({ ...form, finance: e.target.value }) }}
                value={form?.finance}
              />
              {form?.finance && <div style={{ position: 'absolute', top: 25, right: 10 }}>
                <Info text={(`${form?.finance.split(' ')[0]}${form?.finance.split(' ')[1] ? ('.' + form?.finance.split(' ')[1]) : ''}@${domains[0]}`).toLowerCase()} />
              </div>}
            </div>
            <div className='row row-center' style={{ position: 'relative' }}>
              <InputText
                disabled={spoofingCampaignEnabled}
                fullWidth
                sx={{ borderRadius: '20px', mb: '18px' }}
                label='Legal Employee F&L Name'
                variant='standard'
                onChange={(e) => { setForm({ ...form, legalEmployeeName: e.target.value }) }}
                value={form?.legalEmployeeName}
              />
              {form?.legalEmployeeName && <div style={{ position: 'absolute', top: 25, right: 10 }}>
                <Info text={(`${form?.legalEmployeeName.split(' ')[0]}${form?.legalEmployeeName.split(' ')[1] ? ('.' + form?.legalEmployeeName.split(' ')[1]) : ''}@${domains[0]}`).toLowerCase()} />
              </div>}
            </div>
          </div>
        </div>

        {!spoofingCampaignEnabled &&
          <div className='row row-center mb32'> <CustomCheckbox onClick={onConfirm} checked={confirmed} /><p>I confirm that the domains have been added to the whitelist</p></div>
        }

        {!spoofingCampaignEnabled ?
          <Button
            disabled={!confirmed}
            variant={'contained'}
            sx={{ background: '#6123FF', borderRadius: '12px', fontFamily: 'Plus Jakarta Sans', color: '#fff', textTransform: 'none', width: 270, mb: '40px' }}
            onClick={handleSendForm}
          >
            Start Spoofing Campaign
          </Button>
          :
          <div className="row row-center" style={{ marginBottom: '40px' }}>
            <Button
              variant={'outlined'}
              startIcon={<PauseIcon />}
              sx={{ color: '#6123FF', borderColor: '#E7E7EE', textTransform: 'none', borderRadius: '12px', fontFamily: 'Plus Jakarta Sans', padding: '6px 40px' }}
              onClick={handlePauseCampaigns}
            >
              Pause Spoofing Campaigns
            </Button>

            {/* <p style={{ color: '#00BC62', fontSize: '13px', fontWeight: 600, marginLeft: 24 }}>Start date: September 25, 2023</p> */}
            <p style={{ color: '#00BC62', fontSize: '13px', fontWeight: 600, marginLeft: 24 }}>Campaign is running</p>

          </div>

        }

        <h2>Threats Settings</h2>
        <p style={{ marginBottom: 10 }}>Configure Threats Email</p>
        <div className="row row-center" style={{ margin: '10px 0px 40px 0px' }}>
          <div className='row row-center' style={{ position: 'relative', width: '400px' }}>
            <InputText
              disabled={!enabled}
              fullWidth
              sx={{ borderRadius: '20px' }}
              label={`Threats email`}
              variant='standard'
              onChange={(e) => {
                setThreatsEmail(e.target.value)
                setShowSaveThreatsButton(true)
              }}
              value={threatsEmail}
            />
            {/* <div style={{ position: 'absolute', top: 25, right: 10 }}>
              <Info text={'Define the email for ...'} />
            </div> */}
          </div>
          {showSaveThreatsButton && <Button sx={{ textTransform: 'none', color: '#6123FF', ml: '20px' }} onClick={updateThreatsEail} >Update</Button>}
        </div>
      </>}


      <Dialog sx={{ '& .MuiPaper-root': { borderRadius: '22px', paddingTop: '8px', maxWidth: 'unset', width: '684px' } }} open={showPauseDialog} onClose={() => setShowPauseDialog(false)} >
        <DialogTitle sx={{
          fontFamily: 'Satoshi',
          fontStyle: 'normal',
          fontWeight: '900',
          fontSize: '35px',
          lineHeight: '110%',
          color: '#1B1B26',
        }}>Pause Spoofing Campaigns</DialogTitle>
        <DialogContent>
          <p style={{ fontSize: '14px', marginBottom: '80px', lineHeight: '22px' }}>Are you sure you want to stop the Internal Phishing Simulations?</p>
          <div className='row'>
            <Button sx={{ padding: '10px 0px', fontFamily: 'Plus Jakarta Sans', textTransform: 'none', borderRadius: '8px', borderColor: '#D0D0D8', color: '#1B1B26' }} variant={'outlined'} fullWidth onClick={() => setShowPauseDialog(false)}>Cancel</Button>
            <div style={{ width: '20px' }}></div>
            <Button onClick={onPauseSpoofing} sx={{ padding: '10px 0px', fontFamily: 'Plus Jakarta Sans', textTransform: 'none', borderRadius: '8px', background: '#6123FF' }} variant={'contained'} fullWidth >Pause Spoofing</Button>
          </div>
        </DialogContent>
      </Dialog>


    </div>
  )
}