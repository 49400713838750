import React from "react";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";


const ArchiveDialog = ({ str, open, description, onClose, onAction = () => { }, unarchive }) => {
  return (
    <Dialog sx={{ '& .MuiPaper-root': { borderRadius: '22px', paddingTop: '8px', maxWidth: 'unset', width: '684px' } }} open={open} onClose={onClose} >
      <DialogTitle sx={{
        fontFamily: 'Satoshi',
        fontStyle: 'normal',
        fontWeight: '900',
        fontSize: '35px',
        lineHeight: '110%',
        color: '#1B1B26',
      }}>{unarchive ? 'Unarchive:' : 'Archive:'} <span style={{ fontWeight: '400' }}>{str}</span></DialogTitle>
      <DialogContent>
        <p style={{ fontSize: '14px', marginBottom: '80px', lineHeight: '22px' }}>{description}</p>
        <div className='row'>
          <Button sx={{ padding: '10px 0px', fontFamily: 'Plus Jakarta Sans', textTransform: 'none', borderRadius: '8px', borderColor: '#D0D0D8', color: '#1B1B26' }} variant={'outlined'} fullWidth onClick={onClose}>Cancel</Button>
          <div style={{ width: '20px' }}></div>
          <Button onClick={onAction} sx={{ padding: '10px 0px', fontFamily: 'Plus Jakarta Sans', textTransform: 'none', borderRadius: '8px', background: unarchive ? '#6123FF' : 'linear-gradient(279.5deg, #BF0000 9.52%, #EB3600 92.83%)' }} variant={'contained'} fullWidth >Yes, {unarchive ? 'unarchive' : 'archive'}</Button>
        </div>
      </DialogContent>
    </Dialog>

  )
}

export default ArchiveDialog