import React from 'react'

const DownloadIcon = ({ color = '#6123FF' }) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      opacity='0.2'
      d='M21 12H3C2.58579 12 2.25 12.3358 2.25 12.75V18.75C2.25 19.1642 2.58579 19.5 3 19.5H21C21.4142 19.5 21.75 19.1642 21.75 18.75V12.75C21.75 12.3358 21.4142 12 21 12Z'
      fill={color}
    />
    <path
      d='M16.5 12H21C21.1989 12 21.3897 12.079 21.5303 12.2197C21.671 12.3603 21.75 12.5511 21.75 12.75V18.75C21.75 18.9489 21.671 19.1397 21.5303 19.2803C21.3897 19.421 21.1989 19.5 21 19.5H3C2.80109 19.5 2.61032 19.421 2.46967 19.2803C2.32902 19.1397 2.25 18.9489 2.25 18.75V12.75C2.25 12.5511 2.32902 12.3603 2.46967 12.2197C2.61032 12.079 2.80109 12 3 12H7.5'
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M12 2.25V12' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
    <path d='M7.5 7.5L12 12L16.5 7.5' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M17.625 16.875C18.2463 16.875 18.75 16.3713 18.75 15.75C18.75 15.1287 18.2463 14.625 17.625 14.625C17.0037 14.625 16.5 15.1287 16.5 15.75C16.5 16.3713 17.0037 16.875 17.625 16.875Z'
      fill={color}
    />
  </svg>
)

export default DownloadIcon
