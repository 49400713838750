import axios from 'axios';
import authHeader from './auth-header';
import { compactObject } from 'src/heplers';
import api from './api.service';

const API_URL = window._env_.REACT_APP_API_HOST;

class TemplatesService {
  getTemplates(filters = {}) {
    const params = new URLSearchParams(compactObject(filters))
    return axios.get(API_URL + '/v1/templates', { headers: authHeader(), params });
  }

  getTemplatesTypes() {
    return axios.get(API_URL + '/v1/templates/types', { headers: authHeader() });
  }
  getTemplate(name, lang) {
    return axios.get(API_URL + '/v1/templates/names/' + name + '/languages/' + lang, { headers: authHeader() });
  }
  getTemplateById(id) {
    return axios.get(API_URL + '/v1/templates/' + id, { headers: authHeader() });
  }
  publishTemplate(active, ids) {
    return axios.post(API_URL + '/v1/templates/enable', { active, templateIds: ids }, { headers: authHeader() });
  }
  sendTemplate(id) {
    return axios.post(API_URL + '/v1/templates/' + id + '/send', {}, { headers: authHeader() });
  }
  getServices() {
    return axios.get(API_URL + '/v1/templates/services', { headers: authHeader() });
  }
  setServices() {
    return axios.put(API_URL + '/v1/templates/services', { services: ['Twitter'] }, { headers: authHeader() });
  }

  //custom templates

  getCustomTemplateById(id) {
    return axios.get(API_URL + '/v1/custom-templates/' + id, { headers: authHeader() });
  }
  getCustomTemplates(text, pageCustom, size) {
    return axios.get(API_URL + '/v1/custom-templates?searchText=' + text + (pageCustom ? '&page=' + pageCustom : '&page=0') + (size ? '&size=' + size : ''), { headers: authHeader() });
  }
  getCustomTemplatesDomains() {
    return axios.get(API_URL + '/v1/custom-templates/domains', { headers: authHeader() });
  }
  addCustomTemplate(template) {
    return axios.post(API_URL + '/v1/custom-templates/', template, { headers: authHeader() });
  }
  updateCustomTemplateById(id, template) {
    return axios.put(API_URL + '/v1/custom-templates/' + id, template, { headers: authHeader() });
  }
  sendToMeDraft(template) {
    return api.post('/v1/custom-templates/send-draft', template, { headers: authHeader() });
  }
  sendToMeCustomTemplate(id) {
    return axios.post(API_URL + '/v1/custom-templates/' + id + '/send', {}, { headers: authHeader() });
  }
  sendAiDraftPrompt(prompt, lang = 'English') {
    return api.post('/v1/chat/template/', { message: prompt, language: lang }, { headers: authHeader() })
  }

}

export default new TemplatesService();
