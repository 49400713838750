import React, { useContext, useEffect, useRef, useState } from 'react'

import dayjs from "dayjs";
import updateLocale from 'dayjs/plugin/updateLocale'

import { Popover } from '@mui/material';
import { DateCalendar, PickersDay } from '@mui/x-date-pickers';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import { styled } from '@mui/material/styles'
import Spacer from 'src/common/components/Spacer';
import { DashBoardDateContext } from 'src/App';

dayjs.extend(isBetweenPlugin);
dayjs.extend(isoWeek);
dayjs.extend(updateLocale)

dayjs.updateLocale('en', {
  weekStart: 1,
})

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
})(({ theme, isSelected, isHovered, day }) => {
  return {
    borderRadius: 0,
    ...(isSelected && {
      backgroundColor: '#6123FF',
      color: theme.palette.primary.contrastText,
      '&:hover, &:focus': {
        backgroundColor: '#6123FF',
      },
    }),
    ...(isHovered && {
      color: theme.palette.primary.contrastText,
      backgroundColor: '#6123FFe0',
      '&:hover, &:focus': {
        color: theme.palette.primary.contrastText,
        backgroundColor: '#6123FFe0',
      },
    }),
    ...(day.day() === 1 && {
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
    }),
    ...(day.day() === 0 && {
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
    }),
  }
});

const isInSameWeek = (dayA, dayB) => {
  if (dayB == null) {
    return false;
  }

  return dayA.isSame(dayB, 'week');
};

function Day(props) {
  const { day, selectedDay, hoveredDay, ...other } = props;

  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={{ px: 2.5 }}
      disableMargin
      selected={false}
      isSelected={isInSameWeek(day, selectedDay)}
      isHovered={isInSameWeek(day, hoveredDay)}
    />
  );
}

function getPeriod(selectedDate, mode) {
  const date = dayjs(selectedDate);
  let startDate, endDate;

  console.log(mode);


  switch (mode) {
    case 'weekly':
      startDate = date.isoWeekday(1).startOf('day').format('YYYY-MM-DD');
      endDate = date.isoWeekday(7).endOf('day').format('YYYY-MM-DD');
      break;
    case 'monthly':
      startDate = date.startOf('month').format('YYYY-MM-DD');
      endDate = date.endOf('month').format('YYYY-MM-DD');
      break;
    case 'yearly':
      startDate = date.startOf('year').format('YYYY-MM-DD');
      endDate = date.year() == dayjs().year() ? dayjs().format('YYYY-MM-DD') : date.endOf('year').format('YYYY-MM-DD');
      break;
    default:
      throw new Error('Invalid mode');
  }

  return { timePeriod: 'CUSTOM', startDate, endDate, mode, value: selectedDate };
}

export const MODES = {
  weekly: 'weekly',
  monthly: 'monthly',
  yearly: 'yearly',
}

export const PeriodPicker = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [hoveredDay, setHoveredDay] = React.useState(null);
  const [timePeriod, setTimePeriod] = useContext(DashBoardDateContext)

  const [mode, setMode] = useState(null)

  const currentMode = mode ? mode : timePeriod ? timePeriod.mode : MODES.monthly
  useEffect(() => {
    if (!timePeriod) {
      setTimePeriod(getPeriod(dayjs(), MODES.monthly))
    }
  }, [])

  const onPeriodSelect = (val) => {
    setAnchorEl(null)
    const selectedPeriod = getPeriod(val, currentMode)
    setTimePeriod(selectedPeriod)
  }

  const showPopover = Boolean(anchorEl);
  const calendarButtonRef = useRef(null)


  return (
    <div style={{ marginLeft: 20 }}>
      {timePeriod && <>

        <div ref={calendarButtonRef} className='row row-center period-picker' style={{ background: '#EDEDF2', borderRadius: '20px', padding: '6px 20px', cursor: 'pointer' }} onClick={(event) => {
          setAnchorEl(event.currentTarget)
        }}>
          <svg style={{ marginRight: 6 }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.125 6.875H16.875V3.75C16.875 3.58424 16.8092 3.42527 16.6919 3.30806C16.5747 3.19085 16.4158 3.125 16.25 3.125H3.75C3.58424 3.125 3.42527 3.19085 3.30806 3.30806C3.19085 3.42527 3.125 3.58424 3.125 3.75V6.875Z" fill="white" />
            <path d="M16.25 3.125H3.75C3.40482 3.125 3.125 3.40482 3.125 3.75V16.25C3.125 16.5952 3.40482 16.875 3.75 16.875H16.25C16.5952 16.875 16.875 16.5952 16.875 16.25V3.75C16.875 3.40482 16.5952 3.125 16.25 3.125Z" fill="white" stroke="#1B1B26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.75 1.875V4.375" stroke="#1B1B26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.25 1.875V4.375" stroke="#1B1B26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.125 6.875H16.875" stroke="#1B1B26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>

          <p style={{ fontWeight: 600 }}>{
            timePeriod.mode == MODES.yearly ? dayjs(timePeriod.value).year() == dayjs().year() ? 'This year' : dayjs(timePeriod.value).year() + ' year' :
              timePeriod.mode == MODES.monthly ? ((dayjs(timePeriod.value).month() == dayjs().month()) && (dayjs(timePeriod.value).year() == dayjs().year())) ? 'This month' : dayjs(timePeriod.value).format('MMMM YYYY') :
                timePeriod.mode == MODES.weekly ? `${dayjs(getPeriod(timePeriod.value, timePeriod.mode).startDate).format('DD MMM YYYY')} - ${dayjs(getPeriod(timePeriod.value, timePeriod.mode).endDate).format('DD MMM YYYY')}` : null
          }</p>

        </div>

        <Popover
          open={showPopover}
          onClose={() => {
            setAnchorEl(null)
            setMode(timePeriod.mode)
          }}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          sx={{ '& .MuiPaper-root': { borderRadius: '16px', boxShadow: '0px 35px 77px 0px #0000000D, 0px 139px 139px 0px #0000000A, 0px 557px 223px 0px #00000003, 0px 870px 243px 0px #00000000' } }}
        >
          <div style={{ padding: '20px 20px', width: '360px' }} className='column'>

            <div style={{ width: '100%' }} className='row row-center'>
              <p className='sato-24'>Filter period</p>
              <Spacer />
              <div style={{ cursor: 'pointer' }} onClick={() => {
                setAnchorEl(null)
                setMode(timePeriod.mode)
              }}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 18L12 12M12 12L6 6M12 12L18 6M12 12L6 18" stroke="#1B1B26" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

              </div>
            </div>
            <p className='secondary-text-14'>Select the period you want</p>

            <div className='row period-picker-mode'>
              <div onClick={() => { currentMode == MODES.weekly ? null : setMode(MODES.weekly) }} className={`period-picker-mode-item ${currentMode == MODES.weekly && 'active'}`}>
                <p>Weekly</p>
              </div>
              <div onClick={() => { currentMode == MODES.monthly ? null : setMode(MODES.monthly) }} className={`period-picker-mode-item ${currentMode == MODES.monthly && 'active'}`}>
                <p>Monthly</p>
              </div>
              <div onClick={() => { currentMode == MODES.yearly ? null : setMode(MODES.yearly) }} className={`period-picker-mode-item ${currentMode == MODES.yearly && 'active'}`}>
                <p>Yearly</p>
              </div>
            </div>

            <DateCalendar
              sx={{
                '& button.Mui-selected': {
                  backgroundColor: '#6123FF',
                }
              }}
              maxDate={dayjs()}
              value={timePeriod.value}
              onChange={onPeriodSelect}
              view={currentMode == MODES.monthly ? 'month' : currentMode == MODES.yearly ? 'year' : 'day'}
              slots={{ day: Day }}
              slotProps={{
                day: (ownerState) =>
                ({
                  selectedDay: timePeriod.value,
                  hoveredDay,
                  onPointerEnter: () => setHoveredDay(ownerState.day),
                  onPointerLeave: () => setHoveredDay(null),
                }),
              }}
            />
          </div>
        </Popover>
      </>}
    </div>
  )
}