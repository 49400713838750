import React, { useEffect, useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import Spacer from "../Spacer";

const RadioSelectObj = ({ options, onSelectOption, style, placeholder, value, disabled }) => {
  const [option, setOption] = useState(value ? options.find(el => el.id == value) : null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (!disabled) {
      setAnchorEl(event.currentTarget);
    }
  };

  useEffect(() => {
    setOption(value ? options.find(el => el.id == value) : null)
  }, [value])

  const handleSelect = (s) => {
    onSelectOption(s.id)
    setAnchorEl(null)
    setOption(s)
  }
  return (<>
    <div onClick={handleClick} style={{ ...style, opacity: disabled ? 0.7 : 1 }} className='row row-center select-type'>
      <p style={{ marginRight: '10px', fontSize: '13px', fontWeight: '600', textTransform: 'capitalize' }}>{option ? option.name : placeholder}</p>
      <Spacer />
      <svg style={{ transition: '0.4s', transform: open && 'rotate(180deg)' }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 5.33337L8 10.3334L13 5.33337" stroke="#1B1B26" strokeLinecap="round" strokeLinejoin="round" />
      </svg>


    </div>
    <Menu
      sx={{
        '& .MuiPaper-root': {
          // width: style?.width,
          color: '#1B1B26',
          borderRadius: '12px',
          maxHeight: '400px',
        },
        '& li': {
          fontFamily: 'Plus Jakarta Sans',
        },
        '& li:hover': {
        },
      }}

      anchorEl={anchorEl}
      open={open}
      onClose={() => setAnchorEl(null)}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {options.map(el =>
        <MenuItem sx={{ textTransform: 'capitalize', maxWidth: 500 }} className="text-shrink-1" key={el} onClick={() => handleSelect(el)}>
          <div style={{ marginRight: '8px' }}>
            {el.id == option?.id ? (
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10.0004" cy="10" r="6.36364" fill="#6123FF" />
                <circle cx="10" cy="10" r="9.5" fill="white" stroke="#6123FF" />
                <circle cx="10" cy="10" r="6" fill="#6123FF" />
              </svg>

            ) : (
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="10" r="9.5" fill="white" stroke="#A3A4AB" />
              </svg>

            )}

          </div>
          <div style={{ width: '100%', position: 'relative' }} className="text-shrink-1">
            <p>

              {el.name}
            </p>
          </div>
        </MenuItem>
      )}

    </Menu>
  </>
  )
}

export default RadioSelectObj