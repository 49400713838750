import React, { useState } from "react";
import { Menu, MenuItem } from "@mui/material";

import { THREAT_TYPES, ThreatIcon } from "src/pages/threats/components/ThreatListItem";
import { useEffect } from "react";

const MarkAsSelect = ({ option, onSelectOption }) => {
  // const [option, setOptionSelected] = useState(option);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // useEffect(() => {
  //   setOptionSelected(option)
  // }, [option])

  const handleClick = (event) => {
    if (option) {
      return
    }
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (s) => {
    onSelectOption(s)
    setAnchorEl(null)
    // setOptionSelected(s)
  }
  return (<>
    <div onClick={handleClick} className='row row-center select-type select-type-threat'>
      <div className={`threat-list-item-icon ${option && 'threat-list-item-icon-marked threat-item-icon-marked'}`}>
        <ThreatIcon type={option} />
      </div>

      <p style={{ marginRight: '10px', fontSize: '16px', fontWeight: '600' }}>{option ? `Marked as ${option?.toLowerCase()}` : 'Mark As'}</p>
      {!option && (
        <svg style={{ transition: '0.4s', transform: open && 'rotate(180deg)' }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3 5.33337L8 10.3334L13 5.33337" stroke="#1B1B26" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

      )}


    </div>
    <Menu
      sx={{
        '& .MuiPaper-root': {
          color: '#1B1B26',
          borderRadius: '12px'
        },
        '& li': {
          fontFamily: 'Plus Jakarta Sans',
        },
        '& li:hover': {
        },
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={() => setAnchorEl(null)}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >

      <MenuItem sx={{ color: '#B40020' }} onClick={() => handleSelect(THREAT_TYPES.fraudlent)}>
        <div style={{ marginRight: '8px' }}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.1" d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" fill="#B40020" />
            <path d="M12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25Z" stroke="#B40020" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 1.875V5.625" stroke="#B40020" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.875 12H5.625" stroke="#B40020" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 22.125V18.375" stroke="#B40020" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M22.125 12H18.375" stroke="#B40020" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#B40020" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        Mark as fraudulent
      </MenuItem>

      <MenuItem sx={{ color: '#E3A202' }} onClick={() => handleSelect(THREAT_TYPES.spam)}>
        <div style={{ marginRight: '8px' }}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.1" d="M20.25 9V18C20.25 18.1989 20.171 18.3897 20.0303 18.5303C19.8897 18.671 19.6989 18.75 19.5 18.75H4.5C4.30109 18.75 4.11032 18.671 3.96967 18.5303C3.82902 18.3897 3.75 18.1989 3.75 18V9H20.25Z" fill="#E3A202" />
            <path d="M21 5.25H3C2.58579 5.25 2.25 5.58579 2.25 6V8.25C2.25 8.66421 2.58579 9 3 9H21C21.4142 9 21.75 8.66421 21.75 8.25V6C21.75 5.58579 21.4142 5.25 21 5.25Z" stroke="#E3A202" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20.25 9V18C20.25 18.1989 20.171 18.3897 20.0303 18.5303C19.8897 18.671 19.6989 18.75 19.5 18.75H4.5C4.30109 18.75 4.11032 18.671 3.96967 18.5303C3.82902 18.3897 3.75 18.1989 3.75 18V9" stroke="#E3A202" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.75 12.75H14.25" stroke="#E3A202" strokeLinecap="round" strokeLinejoin="round" />
          </svg>


        </div>
        Mark as spam
      </MenuItem>

      <MenuItem sx={{ color: '#00BA97' }} onClick={() => handleSelect(THREAT_TYPES.safe)}>
        <div style={{ marginRight: '8px' }}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.1" d="M3.75 10V4.5C3.75 4.30109 3.82902 4.11032 3.96967 3.96967C4.11032 3.82902 4.30109 3.75 4.5 3.75H19.5C19.6989 3.75 19.8897 3.82902 20.0303 3.96967C20.171 4.11032 20.25 4.30109 20.25 4.5V10C20.25 17.8764 13.5651 20.486 12.2303 20.9285C12.0811 20.9798 11.9189 20.9798 11.7697 20.9285C10.4349 20.486 3.75 17.8764 3.75 10Z" fill="#00BC62" />
            <path d="M3.75 10V4.5C3.75 4.30109 3.82902 4.11032 3.96967 3.96967C4.11032 3.82902 4.30109 3.75 4.5 3.75H19.5C19.6989 3.75 19.8897 3.82902 20.0303 3.96967C20.171 4.11032 20.25 4.30109 20.25 4.5V10C20.25 17.8764 13.5651 20.486 12.2303 20.9285C12.0811 20.9798 11.9189 20.9798 11.7697 20.9285C10.4349 20.486 3.75 17.8764 3.75 10Z" stroke="#00BC62" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.125 9L10.625 14.25L7.875 11.625" stroke="#00BC62" strokeLinecap="round" strokeLinejoin="round" />
          </svg>

        </div>
        Mark as safe
      </MenuItem>


    </Menu>
  </>
  )
}

export default MarkAsSelect