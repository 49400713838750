import React from 'react'

const GlobeIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      opacity='0.2'
      d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z'
      fill='#76767F'
    />
    <path
      d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z'
      stroke='#76767F'
      strokeMiterlimit='10'
    />
    <path d='M3.51172 9H20.4866' stroke='#76767F' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M3.51367 15H20.4884' stroke='#76767F' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M12 20.759C14.0711 20.759 15.75 16.8377 15.75 12.0006C15.75 7.16346 14.0711 3.24219 12 3.24219C9.92893 3.24219 8.25 7.16346 8.25 12.0006C8.25 16.8377 9.92893 20.759 12 20.759Z'
      stroke='#76767F'
      strokeMiterlimit='10'
    />
  </svg>
)

export default GlobeIcon
