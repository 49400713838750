import React, { useEffect } from "react"
import { Box, Typography } from "@mui/material"
// import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'

const RewardPage = () => {
  // const router = useRouter()

  useEffect(
    () => {
      setTimeout(() => {
        // router.push('/user')
      }, 5000)
    }, []
  )

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', overflow: 'hidden' }}>
      <Confetti

      // width={width}
      // height={height}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <img width={'450px'} src="/images/rewards-stars.png" alt="" />
        <Typography sx={{ fontSize: '56px', mt: '30px' }}>Congratulations</Typography>
        <Typography sx={{ fontSize: '20px', mt: '30px' }}>You've passed first microtraininng</Typography>
      </Box>

    </Box>
  )
}

export default RewardPage