import axios from 'axios';
import authHeader from './auth-header';
import { compactObject } from 'src/heplers';

const API_URL = window._env_.REACT_APP_API_HOST;

class StatsService {
  getCurrentCompanyStats(filters = {}) {
    const params = new URLSearchParams(compactObject(filters))
    return axios.get(API_URL + '/v1/companies/current/stats', { headers: authHeader(), params });
  }

  getUserSimulationStats(id) {
    return axios.get(API_URL + '/v1/users/' + id + '/simulation-stats', { headers: authHeader() });
  }

  getUserPhishingEmailStats(id) {
    return axios.get(API_URL + '/v1/users/' + id + '/external-email-stats', { headers: authHeader() });
  }

  getUserSecurityTrainingStats(id) {
    return axios.get(API_URL + '/v1/users/' + id + '/training-package-stats', { headers: authHeader() });
  }

  getCurrentUserSimulationStats() {
    return axios.get(API_URL + '/v1/simulation-stats/users/current', { headers: authHeader() });
  }

  getTopPhishingThemes(filters = {}) {
    const params = new URLSearchParams(compactObject(filters))
    return axios.get(API_URL + '/v1/companies/current/simulation-stats/top-phishing-themes', { headers: authHeader(), params });
  }

  getTimeToReport(filters = {}) {
    const params = new URLSearchParams(compactObject(filters))
    return axios.get(API_URL + '/v1/companies/current/simulation-stats/time-to-report', { headers: authHeader(), params });
  }

  getRepeatFail(filters = {}) {
    const params = new URLSearchParams(compactObject(filters))
    return axios.get(API_URL + '/v1/companies/current/simulation-stats/repeat-fail', { headers: authHeader(), params });
  }

  getFrequency(filters = {}) {
    const params = new URLSearchParams(compactObject(filters))
    return axios.get(API_URL + '/v1/companies/current/simulation-stats/frequency', { headers: authHeader(), params });
  }

  getDepatrmentsSimulationStats(filters = {}) {
    const params = new URLSearchParams(compactObject(filters))
    return axios.get(API_URL + '/v1/companies/current/simulation-stats/departments', { headers: authHeader(), params });
  }
  getLocationSimulationStats(filters = {}) {
    const params = new URLSearchParams(compactObject(filters))
    return axios.get(API_URL + '/v1/companies/current/simulation-stats/countries', { headers: authHeader(), params });
  }

  getSimulationDailyStats(filters = {}) {
    const params = new URLSearchParams(compactObject(filters))
    return axios.get(API_URL + '/v1/companies/current/simulation-stats/daily', { headers: authHeader(), params });
  }
  getTopRisksStats(filters = {}) {
    const params = new URLSearchParams(compactObject(filters))
    return axios.get(API_URL + '/v1/companies/current/simulation-stats/types', { headers: authHeader(), params });
  }

  getCountriesSimulationStats() {
    return axios.get(API_URL + '/v1/simulation-stats/countries/companies/current', { headers: authHeader() });
  }

  getCompanySimulationStats(id) {
    return axios.get(API_URL + '/v1/simulation-stats/companies/' + id, { headers: authHeader() });
  }

  getCurrentCompanySimulationStats(filters = {}) {
    const params = new URLSearchParams(compactObject(filters))
    return axios.get(API_URL + `/v1/companies/current/simulation-stats`, { headers: authHeader(), params });
  }

  // security-training-stats-controller
  getUserSecTrainingStats(id) {
    return axios.get(API_URL + '/v1/training-package-stats/users/' + id, { headers: authHeader() });
  }

  getCurrentUserSecTrainingStats() {
    return axios.get(API_URL + '/v1/training-package-stats/users/current', { headers: authHeader() });
  }

  getCompanySecTrainingStats(id) {
    return axios.get(API_URL + '/v1/training-package-stats/companies/' + id, { headers: authHeader() });
  }

  getCurrentCompanySecTrainingStats() {
    return axios.get(API_URL + '/v1/companies/current/training-package-stats', { headers: authHeader() });
  }


  // micro-training-stats-controller

  getUserMicroTrainingStats(id) {
    return axios.get(API_URL + '/v1/micro-training-stats/users/' + id, { headers: authHeader() });
  }

  getCurrentUserMicroTrainingStats() {
    return axios.get(API_URL + '/v1/micro-training-stats/users/current', { headers: authHeader() });
  }

  getCompanyMicroTrainingStats(id) {
    return axios.get(API_URL + '/v1/micro-training-stats/companies/' + id, { headers: authHeader() });
  }

  getCurrentCompanyMicroTrainingStats(filters = {}) {
    const params = new URLSearchParams(compactObject(filters))
    return axios.get(API_URL + '/v1/companies/current/micro-training-stats', { headers: authHeader(), params });
  }

  getCurrentCompanyMicroTrainingDailyStats(filters = {}) {
    const params = new URLSearchParams(compactObject(filters))
    return axios.get(API_URL + '/v1/companies/current/micro-training-stats/daily', { headers: authHeader(), params });
  }

  // activity-controller

  getUserActivity(id) {
    return axios.get(API_URL + '/v1/activity/users/' + id, { headers: authHeader() });
  }

  getCurrentUserActivity() {
    return axios.get(API_URL + '/v1/activity/users/current', { headers: authHeader() });
  }

  getCurrentCompanySecurityTrainingStats(filters = {}) {
    const params = new URLSearchParams(compactObject(filters))
    return axios.get(API_URL + '/v1/companies/current/training-package-stats', { headers: authHeader(), params });
  }


  getCurrentCompanyExternalPhishing(filters = {}) {
    const params = new URLSearchParams(compactObject(filters))
    return axios.get(API_URL + `/v1/companies/current/external-email-stats`, { headers: authHeader(), params });
  }

  getCurrentCompanyExternalPhishingDaily(filters = {}) {
    const params = new URLSearchParams(compactObject(filters))
    return axios.get(API_URL + `/v1/companies/current/external-email-stats/daily`, { headers: authHeader(), params });
  }

  getCurrentCompanyExternalPhishingMonthly(filters = {}) {
    const params = new URLSearchParams(compactObject(filters))
    return axios.get(API_URL + `/v1/companies/current/external-email-stats/monthly`, { headers: authHeader(), params });
  }

  getCurrentCompanyOveralSecurityScore(filters = {}) {
    const params = new URLSearchParams(compactObject(filters))
    return axios.get(API_URL + `/v1/companies/current/overall-security-score`, { headers: authHeader(), params });
  }

  getCurrentCompanyOveralSecurityScoreMonthly(filters = {}) {
    const params = new URLSearchParams(compactObject(filters))
    return axios.get(API_URL + `/v1/companies/current/overall-security-score/monthly`, { headers: authHeader(), params });
  }

  getCurrentCompanyWorkflowStats(filters = {}) {
    const params = new URLSearchParams(compactObject(filters))
    return axios.get(API_URL + `/v1/companies/current/workflow-stats`, { headers: authHeader(), params });
  }

  getCurrentCompanyWorkflowStatsMonthly(filters = {}) {
    const params = new URLSearchParams(compactObject(filters))
    return axios.get(API_URL + `/v1/companies/current/workflow-stats/monthly`, { headers: authHeader(), params });
  }
}

export default new StatsService();
