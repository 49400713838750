import { Button, Drawer, IconButton, Menu, MenuItem, Snackbar } from '@mui/material'
import React, { useContext, useState } from 'react'
// import OpenFolderIcon from 'src/common/icons/openFolder'

import './styles.css'

// import ModuleView from 'src/views/ModuleView'
import { useEffect } from 'react'
import trainingsService from 'src/services/trainings.service'
import { useNavigate } from 'react-router-dom'
import Spacer from 'src/common/components/Spacer'
import TabPill from 'src/common/components/TabPill'
import { SearchInput } from 'src/common/components/DataGrid'
import useDebounce from 'src/hooks'
import Highlighter from 'react-highlight-words'
// import statsService from 'src/services/stats.service'
import PlusIcon from 'src/common/icons/plusIcon'
import { useWorkflows } from './hooks'
import LoadingIndicator from 'src/common/components/LoadingIndicator'
import CyberSwitch from 'src/common/components/Switch'
import { ValueAndUnit } from '../overview'
// import WorkflowCreate from 'src/views/WorkflowCreate'
import ViewWorkflow from './components/viewWorkflow'
import workflowsService from 'src/services/workflows.service'
import WorkflowCreate from './components/WorkflowCreate'
import DeleteDialog from 'src/common/components/DeleteDialog'
import { CloseDrawer } from 'src/common/icons/closeDrawer'
import { SnackBarContext } from 'src/App'


const WorkflowsListItem = ({
  item,
  onEdit = () => { },
  onView = () => { },
  onDelete = () => { },
  // handleUpdate = () => { },
  search
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [active, setActive] = useState(item.status == 'RUNNING');
  const [disabled, setDisabled] = useState(false);
  const openMenu = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleStatusUpdate = () => {
    setDisabled(true)
    workflowsService.updateStatus(item.id, active ? 'PAUSED' : 'RUNNING').then(resp => {
      setActive((prev) => !prev)
      setDisabled(false)
    }).catch(err => {
      console.error(err);
      setDisabled(false)
    })
  }

  let itemActions = []
  item.actions.findIndex(el => el.type == 'WEBHOOK') !== -1 && itemActions.push('Send webhook')
  item.actions.findIndex(el => el.type == 'TRAINING_PACKAGE') !== -1 && itemActions.push('Enroll to Training Package')
  item.actions.findIndex(el => el.type == 'NOTIFICATION') !== -1 && itemActions.push('Send notification')
  item.actions.findIndex(el => el.type == 'SLACK') !== -1 && itemActions.push('Send training in Slack')

  return (
    <div key={item.id} className={`training-package-item column ${active ? '' : ' workflow-inactive'}`}>
      <div className={`micro-training-item-rb  ${active && 'micro-training-item-rb-green'}`}></div>
      <div className='row'>
        <div className='column' style={{ paddingRight: '12px' }}>
          {item.trigger.service && item.trigger.service == 'CYBERLIFT' && <img src={"images/wf3.png"} width={32} alt="" />}
          {item.trigger.service && item.trigger.service == 'HAVE_I_BEEN_PWNED' && <img src={"images/wf4.png"} width={32} alt="" />}
          {item.trigger.service && item.trigger.service == 'GMAIL' && <img src={"images/wf5.png"} width={32} alt="" />}
          {item.trigger.service && item.trigger.service == 'OUTLOOK' && <img src={"images/wf6.png"} width={32} alt="" />}
        </div>
        <div className='column' style={{ width: '45%' }}>
          <p style={{ fontWeight: '600', marginBottom: '6px', cursor: 'pointer', marginRight: 20 }} className='package-description' onClick={() => onView(item.id)}>
            <Highlighter
              highlightClassName="highlight-text"
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={item.trigger.name}
            />
          </p>
          {/* <Tooltip title={item.description.length > 45 ? item.description : null} arrow> */}

          <p style={{ fontSize: '13px', marginRight: 20 }} className='package-description'>

            <Highlighter
              highlightClassName="highlight-text"
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={itemActions.join(', ')}//
            />
          </p>
          {/* </Tooltip> */}
        </div>
        <Spacer />
        <div className='row row-center' style={{ paddingRight: 60 }}>
          <ValueAndUnit val={item?.usersCount} unit={'users'} />
        </div>
        <div className='row row-center' >
          <CyberSwitch disabled={disabled} checked={active} onChange={handleStatusUpdate} />
        </div>

        <div style={{ justifyContent: 'flex-end' }} className='row row-center'>
          <IconButton onClick={handleOpenMenu}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 13.125C12.6213 13.125 13.125 12.6213 13.125 12C13.125 11.3787 12.6213 10.875 12 10.875C11.3787 10.875 10.875 11.3787 10.875 12C10.875 12.6213 11.3787 13.125 12 13.125Z" fill="#1B1B26" />
              <path d="M12 7.125C12.6213 7.125 13.125 6.62132 13.125 6C13.125 5.37868 12.6213 4.875 12 4.875C11.3787 4.875 10.875 5.37868 10.875 6C10.875 6.62132 11.3787 7.125 12 7.125Z" fill="#1B1B26" />
              <path d="M12 19.125C12.6213 19.125 13.125 18.6213 13.125 18C13.125 17.3787 12.6213 16.875 12 16.875C11.3787 16.875 10.875 17.3787 10.875 18C10.875 18.6213 11.3787 19.125 12 19.125Z" fill="#1B1B26" />
            </svg>
          </IconButton>
          <Menu
            sx={{
              '& .MuiPaper-root': {
                backgroundColor: '#303051',
                color: '#ffffff',
                width: '200px',
                borderRadius: '12px'
              },
              '& li': {
                opacity: '0.7',
                fontFamily: 'Plus Jakarta Sans',
                paddingLeft: '24px'
              },
              '& li:hover': {
                opacity: '1',
              },
            }}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={() => {
              onView(item.id)
              setAnchorEl(null)
            }}>
              <svg style={{ marginRight: '10px' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.76">
                  <path opacity="0.2" d="M12 5.25C4.5 5.25 1.5 12.0007 1.5 12.0007C1.5 12.0007 4.5 18.75 12 18.75C19.5 18.75 22.5 12.0007 22.5 12.0007C22.5 12.0007 19.5 5.25 12 5.25ZM12 15.7508C11.2583 15.7508 10.5333 15.5308 9.91661 15.1188C9.29993 14.7067 8.81928 14.1211 8.53545 13.4358C8.25162 12.7506 8.17736 11.9966 8.32205 11.2692C8.46675 10.5418 8.8239 9.87357 9.34835 9.34913C9.8728 8.82468 10.541 8.46753 11.2684 8.32283C11.9958 8.17814 12.7498 8.2524 13.4351 8.53623C14.1203 8.82006 14.706 9.30071 15.118 9.91739C15.5301 10.5341 15.75 11.2591 15.75 12.0008C15.75 12.9953 15.3549 13.9492 14.6516 14.6524C13.9484 15.3557 12.9946 15.7508 12 15.7508Z" fill="white" />
                  <path d="M12 5.25C4.5 5.25 1.5 12.0007 1.5 12.0007C1.5 12.0007 4.5 18.75 12 18.75C19.5 18.75 22.5 12.0007 22.5 12.0007C22.5 12.0007 19.5 5.25 12 5.25Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                </g>
              </svg>

              View workflow
            </MenuItem>
            <MenuItem onClick={() => {
              // onEdit(item.id)
              onEdit(item.id)
              setAnchorEl(null)
            }}>
              <svg style={{ marginRight: '10px' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.6">
                  <path opacity="0.2" d="M12.75 6.00184L18 11.2518L20.4697 8.78217C20.6103 8.64152 20.6893 8.45075 20.6893 8.25184C20.6893 8.05293 20.6103 7.86216 20.4697 7.72151L16.2803 3.53217C16.1397 3.39152 15.9489 3.3125 15.75 3.3125C15.5511 3.3125 15.3603 3.39152 15.2197 3.53217L12.75 6.00184Z" fill="white" />
                  <path d="M12.75 6L18 11.25" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M9 20.2518H4.5C4.30109 20.2518 4.11032 20.1728 3.96967 20.0322C3.82902 19.8915 3.75 19.7008 3.75 19.5018V15.3125C3.75 15.214 3.7694 15.1165 3.80709 15.0255C3.84478 14.9345 3.90003 14.8518 3.96967 14.7822L15.2197 3.53217C15.3603 3.39152 15.5511 3.3125 15.75 3.3125C15.9489 3.3125 16.1397 3.39152 16.2803 3.53217L20.4697 7.72151C20.6103 7.86216 20.6893 8.05293 20.6893 8.25184C20.6893 8.45075 20.6103 8.64152 20.4697 8.78217L9 20.2518Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                </g>
              </svg>

              Edit
            </MenuItem>
            <MenuItem onClick={() => {
              onDelete(item.id)
              setAnchorEl(null)
            }}>
              <svg style={{ marginRight: '10px' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 15.75L18 5.25" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M18 14.625V5.25H8.625" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3.75 20.25H20.25" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
              </svg>


              Delete
            </MenuItem>
          </Menu>
        </div>

      </div>
    </div>
  )
}

export const SortHeader = ({ name, sort, onSort, width }) => {
  return (
    <div onClick={() => onSort(name)} className='row row-center groups-list-table-sorter' style={{ width: width }}>
      <span style={{ color: name == sort?.name && '#6123FF', marginRight: '6px', lineHeight: '16px' }}>{name}</span>
      {sort?.name == name && (
        <svg style={{ transform: !sort.asc && 'rotate(180deg)', transition: '.3s' }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.83325 3.33594V12.8359" stroke="#6123FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M3.33325 8.33594L7.83325 12.8359L12.3333 8.33594" stroke="#6123FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      )}
    </div>
  )
}

const Workflows = () => {
  const { items, loading, handleUpdate } = useWorkflows()

  const [uncheckedTabs, setUncheckedtabs] = useState([])
  const [itemsFiltred, setItemsFiltred] = useState([])
  const [openShowPackageDrawer, setOpenShowPackageDrawer] = useState(false) ///
  const [openViewPackageDrawer, setOpenViewPackageDrawer] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const [selectedId, setSelectedId] = useState(null)


  const [search, setSearch] = useState('')
  const debouncedSearchTerm = useDebounce(search, 500);

  const onFilter = (criteria) => {
    if (criteria?.searchText) {
      setItemsFiltred(items
        .filter(el => (el.trigger.name.toLowerCase().includes(criteria.searchText.toLowerCase())))
        .filter(el => !uncheckedTabs.map(el => el.toUpperCase()).includes(el.status))
      )
    } else {
      setItemsFiltred(items.filter(el => !uncheckedTabs.map(el => el.toUpperCase()).includes(el.status)))
    }
  }

  useEffect(
    () => {
      if (debouncedSearchTerm) {
        onFilter({ searchText: search })
      } else {
        onFilter({ searchText: '' })
      }
    },
    [debouncedSearchTerm]
  );

  const { setSuccessMessage } = useContext(SnackBarContext)

  const handleSelectItem = (id) => {
    setSelectedId(id)
    setOpenViewPackageDrawer(false)
    setOpenShowPackageDrawer(true)
    setIsEdit(true)
  }

  const handleDeleteItem = (id) => {
    setSelectedId(id)
    setShowDeleteDialog(true)
  }

  const handleViewPackage = (id) => {
    setSelectedId(id)
    setOpenViewPackageDrawer(true)
  }

  const deleteWorkflow = () => {
    workflowsService.deleteWorkflow(selectedId).then(() => {
      setShowDeleteDialog(false)
      setSuccessMessage('Workflow deleted successfully')
      setSelectedId(null)
      handleUpdate()
    }).catch(err => {
      console.error(err);
      setSelectedId(null)
    })
  }

  useEffect(() => {
    onFilter()
  }, [items])

  const tabs = [
    { id: 1, text: 'Running', itemsCount: items.filter(el => el.status == 'RUNNING').length || 0 },
    { id: 2, text: 'Paused', itemsCount: items.filter(el => el.status == 'PAUSED').length || 0 },
  ]

  useEffect(() => {
    setItemsFiltred(items
      .filter(el => (el.trigger.name.toLowerCase().includes(search.toLowerCase())))
      .filter(el => !uncheckedTabs.map(el => el.toUpperCase()).includes(el.status))
    )
  }, [uncheckedTabs])

  const handleTabPillSelect = (name) => {
    setUncheckedtabs(uncheckedTabs.includes(name) ? uncheckedTabs.filter(el => el !== name) : [...uncheckedTabs, name])

  }

  return (
    <>
      <div style={{ marginBottom: '32px', padding: '24px 24px 0px 24px' }} className='row row-center'>
        <h1 style={{ marginBottom: '0' }}>Workflows</h1>
        <Spacer />
        <SearchInput sx={{ width: '400px', margin: '0' }} onChange={(e) => setSearch(e.target.value)} value={search} size='small' placeholder='Search' />
        <Spacer />
        {/* <div className='spacer'></div> */}
        <Button
          variant={'contained'}
          sx={{ background: '#6123FF', borderRadius: '12px', fontFamily: 'Plus Jakarta Sans', color: '#fff', textTransform: 'none', width: 270 }}
          startIcon={<PlusIcon color={'#fff'} />}
          onClick={() => { setOpenShowPackageDrawer(true) }}
        >
          Create New Workflow
        </Button>
      </div>

      <div className='row row-center' style={{ marginTop: '24px', marginBottom: '36px', padding: '0px 24px' }}>
        {tabs.map(el => (
          <TabPill key={el.id} checked={!uncheckedTabs.includes(el.text)} onSelect={handleTabPillSelect} text={el.text} amount={el.itemsCount} />
        ))}
      </div>

      <div
        className='training-packages-content'
        style={{
          background: '#F8F8FB',
          borderTop: '1px solid #E7E7EE',
          padding: '20px',
          height: 'calc(100vh - 171px)',
          overflow: 'auto'
        }}>
        {loading && <LoadingIndicator />}
        {items.length == 0 &&
          <div style={{ alignItems: 'center', marginTop: '100px' }} className='column'>
            <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.25 31.875C21.25 37.743 26.007 42.5 31.875 42.5C37.743 42.5 42.5 37.743 42.5 31.875C42.5 26.007 37.743 21.25 31.875 21.25C26.007 21.25 21.25 26.007 21.25 31.875Z" stroke="#E7E7EE" strokeWidth="5" />
              <path d="M88.125 45C80.8763 45 75 39.1237 75 31.875C75 24.6263 80.8763 18.75 88.125 18.75C95.3737 18.75 101.25 24.6263 101.25 31.875C101.25 39.1237 95.3737 45 88.125 45Z" fill="white" />
              <path d="M60 101.25C52.7513 101.25 46.875 95.3737 46.875 88.125C46.875 80.8763 52.7513 75 60 75C67.2487 75 73.125 80.8763 73.125 88.125C73.125 95.3737 67.2487 101.25 60 101.25Z" fill="white" stroke="#E7E7EE" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M31.875 45C24.6263 45 18.75 39.1237 18.75 31.875C18.75 24.6263 24.6263 18.75 31.875 18.75C39.1237 18.75 45 24.6263 45 31.875C45 39.1237 39.1237 45 31.875 45Z" fill="#F8F8FB" stroke="#E7E7EE" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M88.125 45C80.8763 45 75 39.1237 75 31.875C75 24.6263 80.8763 18.75 88.125 18.75C95.3737 18.75 101.25 24.6263 101.25 31.875C101.25 39.1237 95.3737 45 88.125 45Z" stroke="#E7E7EE" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M88.125 45V48.7509C88.125 50.2283 87.834 51.6913 87.2686 53.0562C86.7032 54.4211 85.8746 55.6613 84.8299 56.706C83.7852 57.7506 82.545 58.5793 81.18 59.1446C79.8151 59.71 78.3522 60.001 76.8748 60.0009L43.1248 60.0003C40.1411 60.0002 37.2797 58.8149 35.17 56.7051C33.0602 54.5953 31.875 51.7339 31.875 48.7503V45" stroke="#E7E7EE" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M59.9989 60L60 75" stroke="#E7E7EE" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <p style={{ color: '#76767F', textAlign: 'center' }}>Here all the workflows you have created<br /> will be displayed</p>
          </div>}
        {(search && itemsFiltred.length == 0) &&
          <div style={{ alignItems: 'center', marginTop: '100px' }} className='column'>
            <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.25 31.875C21.25 37.743 26.007 42.5 31.875 42.5C37.743 42.5 42.5 37.743 42.5 31.875C42.5 26.007 37.743 21.25 31.875 21.25C26.007 21.25 21.25 26.007 21.25 31.875Z" stroke="#E7E7EE" strokeWidth="5" />
              <path d="M88.125 45C80.8763 45 75 39.1237 75 31.875C75 24.6263 80.8763 18.75 88.125 18.75C95.3737 18.75 101.25 24.6263 101.25 31.875C101.25 39.1237 95.3737 45 88.125 45Z" fill="white" />
              <path d="M60 101.25C52.7513 101.25 46.875 95.3737 46.875 88.125C46.875 80.8763 52.7513 75 60 75C67.2487 75 73.125 80.8763 73.125 88.125C73.125 95.3737 67.2487 101.25 60 101.25Z" fill="white" stroke="#E7E7EE" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M31.875 45C24.6263 45 18.75 39.1237 18.75 31.875C18.75 24.6263 24.6263 18.75 31.875 18.75C39.1237 18.75 45 24.6263 45 31.875C45 39.1237 39.1237 45 31.875 45Z" fill="#F8F8FB" stroke="#E7E7EE" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M88.125 45C80.8763 45 75 39.1237 75 31.875C75 24.6263 80.8763 18.75 88.125 18.75C95.3737 18.75 101.25 24.6263 101.25 31.875C101.25 39.1237 95.3737 45 88.125 45Z" stroke="#E7E7EE" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M88.125 45V48.7509C88.125 50.2283 87.834 51.6913 87.2686 53.0562C86.7032 54.4211 85.8746 55.6613 84.8299 56.706C83.7852 57.7506 82.545 58.5793 81.18 59.1446C79.8151 59.71 78.3522 60.001 76.8748 60.0009L43.1248 60.0003C40.1411 60.0002 37.2797 58.8149 35.17 56.7051C33.0602 54.5953 31.875 51.7339 31.875 48.7503V45" stroke="#E7E7EE" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M59.9989 60L60 75" stroke="#E7E7EE" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <p style={{ color: '#76767F', textAlign: 'center' }}>The search did not return any results,<br /> try another query</p>
          </div>}

        {
          itemsFiltred && itemsFiltred.length !== 0 && itemsFiltred.map(item => (
            <WorkflowsListItem search={search} key={item.id} item={item} onDelete={handleDeleteItem} onEdit={handleSelectItem} onView={handleViewPackage} handleUpdate={handleUpdate} />
          ))
        }
      </div >

      <Drawer sx={{ '& .MuiDrawer-paper': { background: '#F8F8FB' } }} anchor='right' open={openShowPackageDrawer} onClose={() => { setOpenShowPackageDrawer(false); setIsEdit(false) }}>
        <div
          style={{ position: 'fixed', top: '24px', right: '982px', cursor: 'pointer' }}
          onClick={() => { setOpenShowPackageDrawer(false); setIsEdit(false) }}
        >
          <CloseDrawer />
        </div>
        <WorkflowCreate
          handleClose={(event) => {
            setOpenShowPackageDrawer(false);
            setIsEdit(false);
            handleUpdate();
            if (event) {
              setSuccessMessage('Workflow ' + event)
            }
          }}
          selectedId={selectedId}
          isEdit={isEdit} />
      </Drawer>

      <Drawer anchor='right' open={openViewPackageDrawer} onClose={() => {
        setOpenViewPackageDrawer(false)
        handleUpdate()
      }}>
        <div
          style={{ position: 'fixed', top: '24px', right: '1202px', cursor: 'pointer' }}
          onClick={() => {
            setOpenViewPackageDrawer(false)
            handleUpdate()
          }}
        >
          <CloseDrawer />
        </div>
        <ViewWorkflow onEdit={handleSelectItem} id={selectedId} />
      </Drawer>
      <DeleteDialog onClose={() => setShowDeleteDialog(false)} onAction={deleteWorkflow} str={' ' + items.find(el => el?.id == selectedId)?.trigger.name} open={showDeleteDialog}></DeleteDialog>

    </>
  )
}

export default Workflows
