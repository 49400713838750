import React from 'react'

const statusText = {
  SENT: 'Sent',
  IN_PROGRESS: 'In Progress',
  OPENED: 'Opened',
  REPORTED: 'Reported',
  COMPLETED: 'Completed',
  PHISHED: 'Phished',
  MISSED: 'Missed',
}
const statusColors = {
  SENT: '#274AF2',
  IN_PROGRESS: '#274AF2',
  OPENED: '#8b8b8b',
  REPORTED: '#00BC62',
  COMPLETED: '#00BC62',
  PHISHED: '#B40020',
  MISSED: '#E3A202',
}

const CampaignUsersStatus = ({ status }) => {
  return (
    <div
      style={{
        background: statusColors[status] + '20',
        alignItems: 'center',
        padding: '6px 16px',
        borderRadius: '8px',
        width: 130
      }}
      className='row row-center'
    >
      {status == 'CREATED'
        && <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path opacity='0.2' d='M13 3L8 8L3 3H13Z' fill={statusColors[status]} />
          <path d='M13 8L8 13L3 8' stroke={statusColors[status]} strokeLinecap='round' strokeLinejoin='round' />
          <path d='M13 3L8 8L3 3H13Z' stroke={statusColors[status]} strokeLinecap='round' strokeLinejoin='round' />
        </svg>
      }
      {status == 'OPENED'
        && <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.2" d="M6.50775 12H11.0229C11.171 12 11.3157 11.9562 11.439 11.874L13.311 10.626C13.4343 10.5438 13.579 10.5 13.7271 10.5H19.5V8.25C19.5 8.15151 19.4806 8.05398 19.4429 7.96298C19.4052 7.87199 19.35 7.78931 19.2803 7.71966C19.2107 7.65002 19.128 7.59477 19.037 7.55708C18.946 7.51939 18.8485 7.5 18.75 7.5H12.25C12.0877 7.5 11.9298 7.44736 11.8 7.34999L9.2 5.40001C9.07018 5.30264 8.91229 5.25 8.75002 5.25H3.75C3.65151 5.25 3.55398 5.26939 3.46298 5.30708C3.37199 5.34477 3.28931 5.40002 3.21966 5.46966C3.15002 5.53931 3.09477 5.62199 3.05708 5.71298C3.01939 5.80398 3 5.90151 3 6V19.5L5.8114 12.4715C5.86707 12.3323 5.96317 12.213 6.08729 12.129C6.21141 12.0449 6.35786 12 6.50775 12Z" fill={statusColors[status]} />
          <path d="M3 19.5V6C3 5.80109 3.07902 5.61032 3.21967 5.46967C3.36032 5.32902 3.55109 5.25 3.75 5.25H8.75C8.91228 5.25 9.07018 5.30263 9.2 5.4L11.8 7.35C11.9298 7.44737 12.0877 7.5 12.25 7.5H18.75C18.9489 7.5 19.1397 7.57902 19.2803 7.71967C19.421 7.86032 19.5 8.05109 19.5 8.25V10.5" stroke={statusColors[status]} strokeLinecap="round" strokeLinejoin="round" />
          <path d="M3 19.5L5.81142 12.4715C5.86709 12.3323 5.96318 12.213 6.0873 12.1289C6.21143 12.0449 6.35788 12 6.50777 12H11.0229C11.171 12 11.3157 11.9562 11.4389 11.874L13.3111 10.626C13.4343 10.5438 13.579 10.5 13.7271 10.5H21.4594C21.5783 10.5 21.6954 10.5282 21.8012 10.5824C21.907 10.6365 21.9984 10.7151 22.0679 10.8115C22.1373 10.9079 22.1829 11.0194 22.2009 11.1369C22.2188 11.2544 22.2085 11.3744 22.1709 11.4872L19.5 19.5H3Z" stroke={statusColors[status]} strokeLinecap="round" strokeLinejoin="round" />
        </svg>

      }

      {['IN_PROGRESS', 'SENT'].includes(status) &&
        <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.2" d="M21.3889 11.359L7.8911 3.11004C7.77741 3.04056 7.64726 3.00263 7.51404 3.00013C7.38083 2.99764 7.24935 3.03068 7.13314 3.09585C7.01692 3.16103 6.92017 3.25598 6.85283 3.37095C6.7855 3.48592 6.75 3.61676 6.75 3.75V20.2479C6.75 20.3811 6.7855 20.5119 6.85283 20.6269C6.92017 20.7419 7.01692 20.8368 7.13314 20.902C7.24935 20.9672 7.38083 21.0002 7.51404 20.9977C7.64726 20.9952 7.77741 20.9573 7.8911 20.8878L21.3889 12.6389C21.4985 12.5719 21.5891 12.4779 21.652 12.3658C21.7148 12.2537 21.7478 12.1274 21.7478 11.9989C21.7478 11.8705 21.7148 11.7441 21.652 11.6321C21.5891 11.52 21.4985 11.426 21.3889 11.359Z" fill={statusColors[status]} />
          <path d="M21.3889 11.359L7.8911 3.11004C7.77741 3.04056 7.64726 3.00263 7.51404 3.00013C7.38083 2.99764 7.24935 3.03068 7.13314 3.09585C7.01692 3.16103 6.92017 3.25598 6.85283 3.37095C6.7855 3.48592 6.75 3.61676 6.75 3.75V20.2479C6.75 20.3811 6.7855 20.5119 6.85283 20.6269C6.92017 20.7419 7.01692 20.8368 7.13314 20.902C7.24935 20.9672 7.38083 21.0002 7.51404 20.9977C7.64726 20.9952 7.77741 20.9573 7.8911 20.8878L21.3889 12.6389C21.4985 12.5719 21.5891 12.4779 21.652 12.3658C21.7148 12.2537 21.7478 12.1274 21.7478 11.9989C21.7478 11.8705 21.7148 11.7441 21.652 11.6321C21.5891 11.52 21.4985 11.426 21.3889 11.359Z" stroke={statusColors[status]} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      }

      {['COMPLETED', 'REPORTED'].includes(status) &&
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.25 6.75L9.75 17.2495L4.5 12" stroke={statusColors[status]} strokeLinecap="round" strokeLinejoin="round" />
        </svg>

      }

      {status == 'PHISHED' &&
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.2" d="M15.4173 3H8.58274C8.48425 3 8.38672 3.0194 8.29573 3.05709C8.20473 3.09478 8.12205 3.15003 8.05241 3.21967L3.21967 8.05241C3.15003 8.12205 3.09478 8.20473 3.05709 8.29573C3.0194 8.38672 3 8.48425 3 8.58274V15.4173C3 15.5158 3.0194 15.6133 3.05709 15.7043C3.09478 15.7953 3.15003 15.8779 3.21967 15.9476L8.05241 20.7803C8.12205 20.85 8.20473 20.9052 8.29573 20.9429C8.38672 20.9806 8.48425 21 8.58274 21H15.4173C15.5158 21 15.6133 20.9806 15.7043 20.9429C15.7953 20.9052 15.8779 20.85 15.9476 20.7803L20.7803 15.9476C20.85 15.8779 20.9052 15.7953 20.9429 15.7043C20.9806 15.6133 21 15.5158 21 15.4173V8.58274C21 8.48425 20.9806 8.38672 20.9429 8.29573C20.9052 8.20473 20.85 8.12205 20.7803 8.05241L15.9476 3.21967C15.8779 3.15003 15.7953 3.09478 15.7043 3.05709C15.6133 3.0194 15.5158 3 15.4173 3Z" fill={statusColors[status]} />
          <path d="M12 7.5V12.75" stroke={statusColors[status]} strokeLinecap="round" strokeLinejoin="round" />
          <path d="M15.4173 3H8.58274C8.48425 3 8.38672 3.0194 8.29573 3.05709C8.20473 3.09478 8.12205 3.15003 8.05241 3.21967L3.21967 8.05241C3.15003 8.12205 3.09478 8.20473 3.05709 8.29573C3.0194 8.38672 3 8.48425 3 8.58274V15.4173C3 15.5158 3.0194 15.6133 3.05709 15.7043C3.09478 15.7953 3.15003 15.8779 3.21967 15.9476L8.05241 20.7803C8.12205 20.85 8.20473 20.9052 8.29573 20.9429C8.38672 20.9806 8.48425 21 8.58274 21H15.4173C15.5158 21 15.6133 20.9806 15.7043 20.9429C15.7953 20.9052 15.8779 20.85 15.9476 20.7803L20.7803 15.9476C20.85 15.8779 20.9052 15.7953 20.9429 15.7043C20.9806 15.6133 21 15.5158 21 15.4173V8.58274C21 8.48425 20.9806 8.38672 20.9429 8.29573C20.9052 8.20473 20.85 8.12205 20.7803 8.05241L15.9476 3.21967C15.8779 3.15003 15.7953 3.09478 15.7043 3.05709C15.6133 3.0194 15.5158 3 15.4173 3Z" stroke={statusColors[status]} strokeMiterlimit="10" />
          <path d="M12 17.25C12.6213 17.25 13.125 16.7463 13.125 16.125C13.125 15.5037 12.6213 15 12 15C11.3787 15 10.875 15.5037 10.875 16.125C10.875 16.7463 11.3787 17.25 12 17.25Z" fill={statusColors[status]} />
        </svg>

      }

      {status == 'MISSED' &&
        <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.2" d="M5.03217 18.9697C4.92728 19.0746 4.85585 19.2082 4.82691 19.3537C4.79797 19.4992 4.81282 19.65 4.86959 19.787C4.92636 19.9241 5.02249 20.0412 5.14582 20.1236C5.26916 20.206 5.41416 20.25 5.5625 20.25H18.4412C18.5895 20.25 18.7345 20.206 18.8579 20.1236C18.9812 20.0412 19.0773 19.9241 19.1341 19.787C19.1909 19.65 19.2057 19.4992 19.1768 19.3537C19.1478 19.2082 19.0764 19.0746 18.9715 18.9697L16.5018 16.5H7.50184L5.03217 18.9697Z" fill={statusColors[status]} />
          <path d="M5.5625 3.75H18.4412C18.5895 3.75 18.7345 3.79399 18.8579 3.8764C18.9812 3.95881 19.0773 4.07594 19.1341 4.21299C19.1909 4.35003 19.2057 4.50083 19.1768 4.64632C19.1478 4.7918 19.0764 4.92544 18.9715 5.03033L12.0018 12L5.03217 5.03033C4.92728 4.92544 4.85585 4.7918 4.82691 4.64632C4.79797 4.50083 4.81282 4.35003 4.86959 4.21299C4.92636 4.07594 5.02249 3.95881 5.14582 3.8764C5.26916 3.79399 5.41416 3.75 5.5625 3.75Z" stroke={statusColors[status]} strokeLinecap="round" strokeLinejoin="round" />
          <path d="M5.5625 20.25H18.4412C18.5895 20.25 18.7345 20.206 18.8579 20.1236C18.9812 20.0412 19.0773 19.9241 19.1341 19.787C19.1909 19.65 19.2057 19.4992 19.1768 19.3537C19.1478 19.2082 19.0764 19.0746 18.9715 18.9697L12.0018 12L5.03217 18.9697C4.92728 19.0746 4.85585 19.2082 4.82691 19.3537C4.79797 19.4992 4.81282 19.65 4.86959 19.787C4.92636 19.9241 5.02249 20.0412 5.14582 20.1236C5.26916 20.206 5.41416 20.25 5.5625 20.25Z" stroke={statusColors[status]} strokeLinecap="round" strokeLinejoin="round" />
          <path d="M16.5 16.5H7.5" stroke={statusColors[status]} strokeLinecap="round" strokeLinejoin="round" />
        </svg>


      }

      <p style={{ marginLeft: '6px', fontSize: 14 }}>
        {statusText[status]}

      </p>
    </div>
  )
}

export default CampaignUsersStatus
