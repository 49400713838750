import React, { useState } from "react";

import './styles.css'
import { Menu, MenuItem } from "@mui/material";

const LanguageSelect = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    // setAnchorEl(event.currentTarget);
  };

  return (
    <div style={{ position: 'relative', marginLeft: '8px' }}>
      <div
        onClick={handleClick}
        className={`row row-center lang-select 
          ${open && 'lang-select-open'}
          `}
      >
        <p style={{ marginRight: '8px' }}>English</p>
        {/* <svg style={{}} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.5">
            <path d="M3 5.33331L8 10.3333L13 5.33331" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </g>
        </svg> */}
      </div>

      <Menu
        sx={{
          '& .MuiPaper-root': {
            color: '#1B1B26',
            borderRadius: '12px'
          },
          '& li': {
            opacity: '0.7',
            fontFamily: 'Plus Jakarta Sans',
          },
          '& li:hover': {
            opacity: '1',
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => setAnchorEl(null)}>
          English
        </MenuItem>
      </Menu>


    </div>
  )
}


const Copyright = () => {
  return (
    <div className='copyright row row-center'>
      <p>© 2024 Cyberlift, Inc. •</p>
      <LanguageSelect />
    </div>
  )
}

export default Copyright