import React from 'react'

const SettingsIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g>
      <path
        opacity='0.2'
        d='M21.5988 10.2921C21.5613 10.0671 21.4207 9.87957 21.2238 9.76707L19.0957 8.58582C18.927 8.22957 18.7301 7.89207 18.5051 7.56395L18.5426 5.12645C18.5426 4.90145 18.4488 4.68582 18.2801 4.53582C17.3895 3.78582 16.3863 3.21395 15.3176 2.82957C15.102 2.75457 14.8676 2.77332 14.6707 2.8952L12.5801 4.15145C12.1863 4.12332 11.7926 4.12332 11.3988 4.15145L9.3176 2.8952C9.12072 2.7827 8.88635 2.75457 8.6801 2.82957C7.5926 3.22332 6.58947 3.80457 5.7176 4.53582C5.54885 4.68582 5.44572 4.90145 5.44572 5.12645L5.48322 7.56395C5.25822 7.89207 5.06135 8.22957 4.8926 8.58582L2.76447 9.76707C2.5676 9.87957 2.42697 10.0671 2.38947 10.2921C2.18322 11.4358 2.1926 12.5889 2.38947 13.7046C2.42697 13.9296 2.5676 14.1171 2.76447 14.2296L4.8926 15.4108C5.06135 15.7671 5.25822 16.1046 5.48322 16.4327L5.44572 18.8702C5.44572 19.0952 5.53947 19.3108 5.70822 19.4608C6.59885 20.2108 7.60197 20.7827 8.67072 21.1671C8.88635 21.2421 9.12072 21.2233 9.3176 21.1014L11.4082 19.8452C11.802 19.8733 12.1957 19.8733 12.5895 19.8452L14.6801 21.1014C14.877 21.2139 15.1113 21.2421 15.3176 21.1671C16.4051 20.7733 17.4082 20.1921 18.2801 19.4514C18.4488 19.3014 18.552 19.0858 18.552 18.8608L18.5145 16.4233C18.7395 16.0952 18.9363 15.7577 19.1051 15.4014L21.2332 14.2202C21.4301 14.1077 21.5707 13.9202 21.6082 13.6952C21.8051 12.5608 21.7957 11.4077 21.5988 10.2921ZM11.9988 16.4983C9.51447 16.4983 7.49885 14.4827 7.49885 11.9983C7.49885 9.51395 9.51447 7.49832 11.9988 7.49832C14.4832 7.49832 16.4988 9.51395 16.4988 11.9983C16.4988 14.4827 14.4832 16.4983 11.9988 16.4983Z'
        fill='#1B1B26'
      />
      <path
        d='M4.90236 8.58739C5.07111 8.23114 5.26799 7.89364 5.49299 7.56551L5.45549 5.12801C5.45549 4.90301 5.54924 4.68739 5.72736 4.53739C6.59924 3.80614 7.59299 3.21551 8.68986 2.82176C8.90549 2.74676 9.13986 2.76551 9.32736 2.88739L11.418 4.14364C11.8117 4.11551 12.2055 4.11551 12.5992 4.14364L14.6899 2.88739C14.8867 2.77489 15.1211 2.74676 15.3367 2.82176C16.4055 3.20614 17.4086 3.77801 18.2992 4.52801C18.468 4.66864 18.5711 4.89364 18.5617 5.11864L18.5242 7.55614C18.7492 7.88426 18.9461 8.22176 19.1149 8.57801L21.243 9.75926C21.4399 9.87176 21.5805 10.0593 21.618 10.2843C21.8149 11.3999 21.8242 12.5624 21.618 13.6968C21.5805 13.9218 21.4399 14.1093 21.243 14.2218L19.1149 15.403C18.9461 15.7593 18.7492 16.0968 18.5242 16.4249L18.5617 18.8624C18.5617 19.0874 18.468 19.303 18.2899 19.453C17.418 20.1843 16.4242 20.7749 15.3274 21.1686C15.1117 21.2436 14.8774 21.2249 14.6899 21.103L12.5992 19.8468C12.2055 19.8749 11.8117 19.8749 11.418 19.8468L9.32736 21.103C9.13049 21.2155 8.89611 21.2436 8.68049 21.1686C7.61174 20.7843 6.60861 20.2124 5.71799 19.4624C5.54924 19.3218 5.44611 19.0968 5.45549 18.8718L5.49299 16.4343C5.26799 16.1061 5.07111 15.7686 4.90236 15.4124L2.77424 14.2311C2.57736 14.1186 2.43674 13.9311 2.39924 13.7061C2.20236 12.5905 2.19299 11.428 2.39924 10.2936C2.43674 10.0686 2.57736 9.88114 2.77424 9.76864L4.90236 8.58739Z'
        stroke='#1B1B26'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 16.5C14.4853 16.5 16.5 14.4853 16.5 12C16.5 9.51472 14.4853 7.5 12 7.5C9.51472 7.5 7.5 9.51472 7.5 12C7.5 14.4853 9.51472 16.5 12 16.5Z'
        stroke='#1B1B26'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
)

export default SettingsIcon
