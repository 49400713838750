import { Button as MuiButton, IconButton, TextField as MuiTextField } from "@mui/material";
import React, { useContext, useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Info from "src/common/components/Info";
import RadioSelect from "src/common/components/RadioSelect";
import Spacer from "src/common/components/Spacer";
import CyberSwitch from "src/common/components/Switch";
import TextField from "src/common/components/TextField";
import PlusIcon from "src/common/icons/plusIcon";
import groupsService from "src/services/groups.service";
import styled from "styled-components";
import { SortHeader } from "..";
import CustomCheckbox from "src/common/components/CustomCheckbox";
import userService from "src/services/user.service";
import { toggleElement } from "src/heplers";
import LoadingIndicator from "src/common/components/LoadingIndicator";
import useDebounce from "src/hooks";
import Highlighter from "react-highlight-words";
import RadioSelectObj from "src/common/components/RadioSelectObj";
import UserAvatar from "src/common/components/UserAvatar";
import { SnackBarContext } from "src/App";

const Button = styled(MuiButton)(() => ({
  textTransform: 'none',
  fontFamily: 'Plus Jakarta Sans',
  border: '1px solid #D0D0D8',
  borderRadius: '14px',
  color: '#1B1B26',
  '&.MuiButton-contained': {
    color: '#ffffff',
    border: '1px solid #6123FF',
    backgroundColor: '#6123FF',
  },
  '&.Mui-disabled': {
    backgroundColor: 'rgba(219, 226, 255, 0.5)',

  }
}))

const UsersList = ({ search, users, selectedIds, onSelect = () => { }, sortBy, handleSort }) => {
  return (
    <div className='mb20'>
      <div className='row row-center groups-list-heading'>
        <SortHeader name='Name' sort={sortBy} onSort={handleSort} width={'50%'} />
        <SortHeader name='Email' sort={sortBy} onSort={handleSort} width={'50%'} />
      </div>

      {users.length ? users.map(item => (
        <div key={item.id} className='groups-list-item row row-center'>
          <div style={{ width: '50%' }} className='row row-center'>
            <CustomCheckbox checked={selectedIds.includes(item.id)} onChange={() => onSelect(item.id)} />
            <UserAvatar firstName={item?.firstName} lastName={item?.lastName} />
            <p style={{ fontWeight: 600 }}>
              <Highlighter
                highlightClassName="highlight-text"
                searchWords={[search]}
                autoEscape={true}
                textToHighlight={(item?.firstName || 'unknown') + ' ' + (item?.lastName || 'unknown')}
              />

            </p>
          </div>
          <div style={{ width: '50%' }} >
            <Highlighter
              highlightClassName="highlight-text"
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={item?.email || ''}
            />
          </div>
        </div>
      )) : <div style={{ minHeight: 'calc(100vh - 730px)', justifyContent: 'center' }} className="column column-center"><p>No users found</p></div>}
    </div>
  )
}

const SearchInput = (props) => {
  return <MuiTextField {...props} size='small' placeholder='Search' />
}

const rulesAreValid = (rules = []) => {
  let res = false
  for (let index = 0; index < rules.length; index++) {
    const rule = rules[index];
    if ((rule.criteria && rule.operator && rule.value) || rule.criteria == 'ALL_EMPLOYEES') {
      res = true
    } else {
      return false
    }
  }
  return res
}

const defaultFilters = {
  page: 0,
  size: 10,
  status: 'ACTIVE'
}

const AddNewGroupFrom = ({ onClose, onCreate, selectedId = null }) => {
  const [form, setForm] = useState({
    name: '',
  })
  const [isDynamic, setIsDynamic] = useState(true)
  const [rules, setRules] = useState([
    {
      criteria: '',
      operator: '',
      value: '',
    },
  ])
  const [errors, setErrors] = useState(null)
  const [allRules, setAllRules] = useState([])
  const [userIds, setUserIds] = useState([])
  const [userIdsByRules, setUserIdsByRules] = useState([])
  const [users, setUsers] = useState([])
  const [loadingUsers, setLoadingUsers] = useState(false)
  // const [totalUsersCount, setTotalUsersCount] = useState([])

  const [search, setSearch] = useState('')
  const [filters, setFilters] = useState(defaultFilters)

  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const [sortBy, setSortBy] = useState(null)
  const handleSort = (field) => {
    const fieldset = {
      'name': 'firstName',
      'email': 'email',
    }
    const fieldName = fieldset[field.toLowerCase()]
    const asc = field == sortBy?.name ? !sortBy.asc : true
    setSortBy({ name: field, asc: asc })
    setFilters({ ...filters, sort: fieldName + ',' + (asc ? 'asc' : 'desc') })
  }

  const { setErrorMessage } = useContext(SnackBarContext)
  const blockRef = useRef(null)


  const possibleToAddRule = rulesAreValid(rules)

  const debouncedSearchTerm = useDebounce(search, 500);

  const handleFilter = (param) => {
    setFilters({ ...filters, ...param })
  }

  const handlePageChange = pageNum => {
    setPage(pageNum)
    handleFilter({ page: pageNum - 1 })
  }

  useEffect(
    () => {
      if (debouncedSearchTerm) {
        // setIsSearching(true);
        handleFilter({ searchText: search })
      } else {
        handleFilter({ searchText: '' })
      }
    },
    [debouncedSearchTerm]
  );

  const handleAddRule = () => {
    setRules([
      ...rules,
      {
        criteria: '',
        operator: '',
        value: '',
      },

    ])
  }

  const handleDeleteRule = (index) => {
    if (rules.length > 1) {
      setRules(rules.filter((el, i) => i !== index))
    }
  }

  const fromIsValid = () => {
    if (!form.name) {
      return false
    }

    if (isDynamic) {
      if (rules.length == 0) {
        return false
      } else {
        if (rules.some(el => (el.criteria !== 'ALL_EMPLOYEES' && !el.value))) {
          return false
        }
      }
    } else {
      if (!userIds.length) {
        return false
      }
    }

    return true
  }

  const isValid = fromIsValid()

  const handleSave = () => {

    if (!isValid) {
      setErrors({
        ...errors,
        ...(!form.name && { name: 'Name can not be empty' }),
        ...(!isDynamic && !form.users && { users: 'Please add at least one user' }),
      })
      return
    }

    let newGroup = {
      name: form.name.trim(),
      type: isDynamic ? 'DYNAMIC' : 'STATIC',
      ...(isDynamic ? {
        rules: rules,
        userIds: userIdsByRules
      } : {
        userIds: userIds
      })
    }
    if (selectedId) {
      groupsService.updateGroup({ id: selectedId, ...newGroup }).then(() => {
        clearForm()
        onCreate(true)
      }).catch(err => {
        console.error(err)
        if (err.response.data.code == "GROUP_ERROR_004") {
          setErrors({ ...errors, name: err.response.data.description })
          setErrorMessage(err.response.data.description)
          setForm({ ...form, name: form.name.trim() })
          blockRef.current.scrollTo(0, 0)
        }
      })

    } else {
      groupsService.addGroup(newGroup).then(() => {
        clearForm()
        onCreate()
      }).catch(err => {
        console.error(err)
        if (err.response.data.code == "GROUP_ERROR_004") {
          setErrors({ ...errors, name: err.response.data.description })
          setErrorMessage(err.response.data.description)
          setForm({ ...form, name: form.name.trim() })
          blockRef.current.scrollTo(0, 0)
        }
      })

    }
  }

  const handleSelectUser = (userId) => {
    setUserIds(toggleElement(userIds, userId))
    setErrors({ ...errors, users: '' })
  }

  function clearForm() {
    setForm({
      name: '',
    })
    setIsDynamic(true)
    setRules([
      {
        criteria: '',
        operator: '',
        value: '',
      },
    ])
    setUserIds([])
    setUserIdsByRules([])
    setUsers([])
    setErrors(null)

  }

  useEffect(() => {
    if (selectedId) {
      groupsService.getGroup(selectedId).then(resp => {
        setForm({
          name: resp.data.name,
        })
        setIsDynamic(resp.data.type == "DYNAMIC")
        if (resp.data.type == "DYNAMIC") {
          setRules(resp.data.rules)
        } else {
          setRules([])
          setUserIds(resp.data.userIds)
        }
        setRules(resp.data.rules)

      })

    } else {
      clearForm()
    }
    setLoadingUsers(true)
    Promise.all([
      groupsService.getRules(),
    ]).then(values => {
      const [rusesResp] = values
      setAllRules(rusesResp.data)
      setUsers([])
      setLoadingUsers(false)
    }).catch(err => {
      setLoadingUsers(false)
      console.error(err);
    })
  }, [])

  useEffect(() => {
    if (rulesAreValid(rules)) {
      setLoadingUsers(true)
      groupsService.getUsersByRules(rules).then((res) => {
        setUserIdsByRules(res.data);
        userService.getUsersByIds(res.data).then(usersResp => {
          setLoadingUsers(false)
          setTotalPages(Math.ceil(parseInt(usersResp.headers['x-total-count']) / defaultFilters.size))
          setUsers(usersResp.data)
        }).catch(err => {
          console.error(err);
          setLoadingUsers(false)
        })
      })
    }
  }, [rules])

  useEffect(() => {
    if (!isDynamic) {
      userService.getUsers(filters).then(resp => {
        setTotalPages(Math.ceil(parseInt(resp.headers['x-total-count']) / defaultFilters.size))
        setUsers(resp.data)
      })
    }
  }, [filters])

  useEffect(() => {
    if (isDynamic) {
      setUsers([])
      setUserIds([])
    } else {
      setLoadingUsers(true)
      userService.getUsers(defaultFilters).then((res) => {
        setLoadingUsers(false)
        setTotalPages(Math.ceil(parseInt(res.headers['x-total-count']) / defaultFilters.size))
        setUsers(res.data)
      })
      setRules([
        {
          criteria: '',
          operator: '',
          value: '',
        },
      ])
    }
  }, [isDynamic])

  const isPercentRate = (criteria) => { return ['SIMULATIONS_SUCCESS_RATE', 'SIMULATIONS_FAIL_RATE', 'SIMULATIONS_MISS_RATE'].includes(criteria) }

  return (
    <div style={{ width: '1047px', height: '100%', padding: '32px' }} className='column'>
      <div ref={blockRef} style={{ flex: 1, position: 'relative', overflow: 'auto', marginBottom: '20px' }} className="column">
        <div style={{
          marginBottom: '20px'
        }} className='row row-center'>
          <p className='micro-drawer-title'>{selectedId ? 'Edit ' : 'Add New '} Group</p>
          <Spacer />
          <CyberSwitch
            disabled={!!selectedId}

            checked={isDynamic}
            onChange={() => { setIsDynamic(!isDynamic) }}
          />
          <p style={{ marginLeft: '16px' }}>Dynamic group</p>
          <Info text={'Dynamic groups auto-update member lists based on pre-set criteria. For example, any new members that meet these specified rules will automatically be added to the group.'} />
        </div>

        <div style={{ width: '100%' }}>
          <TextField
            label='Name'
            variant='standard'
            fullWidth
            error={errors?.name}
            value={form?.name || ''}
            onChange={(e) => {
              setForm({ ...form, name: e.target.value.trimStart() })
              setErrors({ ...errors, name: '' })
            }
            }
          />
          {errors?.name && <p className="error-text">{errors?.name}</p>}
        </div>

        <div style={{ transition: '300ms', position: 'relative' }} className="column">
          {!isDynamic && <div style={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            width: '100%',
            height: '100%',
            backgroundColor: '#ffffff80',
            zIndex: '10',
          }}></div>}
          <p style={{
            fontFamily: 'Satoshi',
            fontSize: '24px',
            marginTop: '36px',
            marginBottom: '20px',
          }}>
            Rules for employees
          </p>
          {errors?.rules && <p className="error-text" style={{ margin: '-8px 0px 16px 16px' }}>{errors?.rules}</p>}

          {allRules.length ? rules?.map && rules.map((rule, i) => (
            <div key={i} style={{ marginBottom: '20px' }} className="row row-center">
              <span style={{
                fontWeight: '600',
                marginRight: '16px'
              }}>Who</span>

              <RadioSelect style={{
                width: '300px',
                backgroundColor: '#fff',
                border: '1px solid #E7E7EE',
                borderRadius: '14px',
                marginRight: '16px',
                height: '48px'
              }}
                // disabled
                value={rule.criteria}
                placeholder={'Select criteria'}
                options={allRules.map(el => el.criteria)}
                onSelectOption={(opt) => {
                  const newCriteria = rules.map((el, index) => index == i ? { ...el, criteria: opt, operator: '', value: '' } : el)
                  setRules(newCriteria)
                }}
              />
              <RadioSelect style={{
                width: '140px',
                backgroundColor: '#fff',
                border: '1px solid #E7E7EE',
                borderRadius: '14px',
                marginRight: '16px',
                height: '48px'
              }}
                disabled={!rule?.criteria || rule.criteria == 'ALL_EMPLOYEES'}
                placeholder={'Operator'}
                value={rule.operator}
                options={rule?.criteria ? allRules.find(el => el.criteria == rules[i].criteria)?.operators.map(el => el.operator) : []}
                onSelectOption={(opt) => {

                  const newRules = rules.map((el, index) => index == i ? { ...el, operator: opt, value: '' } : el)
                  setRules(newRules)
                }}
              />

              {allRules.find(el => el.criteria == rules[i].criteria)?.operators.find(operator => operator.operator == rules[i].operator)?.type == 'LIST'
                ? <RadioSelect style={{
                  width: '280px',
                  backgroundColor: '#fff',
                  border: '1px solid #E7E7EE',
                  borderRadius: '14px',
                  marginRight: '16px',
                  height: '48px',
                  overflow: 'hidden'
                }}
                  options={rules[i].operator ? allRules.find(el => el.criteria == rules[i].criteria).operators.find(operator => operator.operator == rules[i].operator).values : []}
                  placeholder={'Value'}
                  value={rule.value}

                  onSelectOption={(opt) => {
                    setErrors({ ...errors, rules: '' })
                    const newRules = rules.map((el, index) => index == i ? { ...el, value: opt } : el)
                    setRules(newRules)
                  }}
                />
                : allRules.find(el => el.criteria == rules[i].criteria)?.operators.find(operator => operator.operator == rules[i].operator)?.type == 'OBJECT_LIST'
                  ? <RadioSelectObj style={{
                    width: '280px',
                    backgroundColor: '#fff',
                    border: '1px solid #E7E7EE',
                    borderRadius: '14px',
                    marginRight: '16px',
                    height: '48px',
                    overflow: 'hidden'
                  }}
                    options={rules[i].operator ? allRules.find(el => el.criteria == rules[i].criteria).operators.find(operator => operator.operator == rules[i].operator).values : []}
                    placeholder={'Value'}
                    value={rule.value}

                    onSelectOption={(opt) => {
                      setErrors({ ...errors, rules: '' })
                      const newRules = rules.map((el, index) => index == i ? { ...el, value: opt } : el)
                      setRules(newRules)
                    }}
                  />
                  : <div className="row row-center">
                    <MuiTextField
                      disabled={rule.criteria == 'ALL_EMPLOYEES' || rule.operator == ''}
                      sx={{ width: '280px', '& .MuiOutlinedInput-root': { borderRadius: '14px', border: '1px solid rgb(231, 231, 238)' }, '& .MuiInputBase-input': { height: '32px', padding: '8px 12px', } }}
                      type="number"
                      placeholder="Enter the number"
                      InputProps={{
                        inputProps: {
                          max: 100, min: 1
                        }
                      }}

                      value={rule.value}
                      onChange={(e => {
                        if (isPercentRate(rule.criteria)) {
                          if (e.target.value >= 0 && e.target.value <= 100) {
                            const newRules = rules.map((el, index) => index == i ? { ...el, value: e.target.value } : el)
                            setRules(newRules)
                          }
                        } else {
                          const newRules = rules.map((el, index) => index == i ? { ...el, value: e.target.value } : el)
                          setRules(newRules)
                        }
                      })} />
                    {rule.criteria == 'ADDED_TO_SYSTEM' && <p style={{ marginLeft: 10, fontWeight: 600 }}>days</p>}
                  </div>


              }
              {isPercentRate(rule.criteria) &&
                <p style={{ marginLeft: 10 }}>%</p>
              }

              <Spacer />

              <IconButton disabled={rules.length == 1} onClick={() => handleDeleteRule(i)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.2" d="M18.75 5.25V19.5C18.75 19.6989 18.671 19.8897 18.5303 20.0303C18.3897 20.171 18.1989 20.25 18 20.25H6C5.80109 20.25 5.61032 20.171 5.46967 20.0303C5.32902 19.8897 5.25 19.6989 5.25 19.5V5.25L18.75 5.25Z" fill="#8D8D94" />
                  <path d="M20.25 5.25L3.75 5.25001" stroke="#8D8D94" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M8.25 2.25H15.75" stroke="#8D8D94" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M18.75 5.25V19.5C18.75 19.6989 18.671 19.8897 18.5303 20.0303C18.3897 20.171 18.1989 20.25 18 20.25H6C5.80109 20.25 5.61032 20.171 5.46967 20.0303C5.32902 19.8897 5.25 19.6989 5.25 19.5V5.25" stroke="#8D8D94" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </IconButton>
            </div>

          )) : <></>}
          <div className="row row-center" style={{ alignItems: 'flex-start' }}>
            <Button disabled={!possibleToAddRule} onClick={handleAddRule} sx={{ color: '#6123FF' }} startIcon={<PlusIcon style={{ transition: '300ms', opacity: (!isDynamic && !!selectedId) && '0.5' }} />} variant={'outlined'}>Add Rule</Button>
          </div>
        </div>

        <div style={{ transition: '300ms', position: 'relative' }} className="column">
          {isDynamic && <div style={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            width: '100%',
            height: '100%',
            backgroundColor: '#ffffff80',
            zIndex: '10',
          }}></div>}

          <p style={{
            fontFamily: 'Satoshi',
            fontSize: '24px',
            marginTop: '36px',
            marginBottom: '20px',
          }}>Users</p>
          {errors?.users && <p className="error-text" style={{ margin: '-8px 0px 16px 16px' }}>{errors?.users}</p>}

          <SearchInput size='small' placeholder='Search' onChange={(e) => setSearch(e.target.value)} value={search} variant='outlined' />

          {/* <div className='row row-center' style={{ marginTop: '24px', marginBottom: '36px' }}>
            <TabPill checked text={'Select Users'} amount={0} />
            <TabPill text={'Unselect Users'} amount={0} />
          </div> */}

          {loadingUsers
            ? <LoadingIndicator />
            : <UsersList search={search} selectedIds={userIds} users={users} onSelect={handleSelectUser} sortBy={sortBy} handleSort={handleSort} />
          }
          <div className="row row-center">
            <p>Page <strong>{page}</strong> of <strong>{totalPages}</strong></p>
            <Spacer />
            <Button
              onClick={() => handlePageChange(page - 1)}
              disabled={page == 1}
              variant='outlined'
              sx={{
                border: '1px solid #D0D0D8',
                color: '#1B1B26',
                textTransform: 'unset',
                mt: '18px',
                mb: '24px',
                mr: '24px',
                borderRadius: '12px',
                width: '148px'
              }}
            >
              Previous
            </Button>
            <Button
              onClick={() => handlePageChange(page + 1)}
              variant='outlined'
              disabled={page == totalPages || !totalPages}
              sx={{
                border: '1px solid #D0D0D8',
                color: '#1B1B26',
                textTransform: 'unset',
                mt: '18px',
                mb: '24px',
                borderRadius: '12px',
                width: '148px'
              }}
            >
              Next
            </Button>
          </div>
        </div>





      </div>

      <div className="row row-center">
        <Button onClick={() => {
          onClose()
          clearForm()
        }} size={'large'} fullWidth variant={'outlined'}>Cancel</Button>
        <div style={{ width: '24px' }}></div>
        {/* <Button size={'large'} onClick={handleSave} disabled={!form.name || (!userIds.length && !rulesAreValid(rules))} fullWidth variant={'contained'}>Save Rules and Group</Button> */}
        <MuiButton
          size={'large'}
          onClick={handleSave}
          disabled={!isValid}
          fullWidth
          variant={'contained'}
          sx={{ color: '#fff', backgroundColor: '#6123FF', textTransform: 'none', height: '42px', width: '100%', borderRadius: '14px', '&:hover': { backgroundColor: '#7843ff' } }}
        >Save Rules and Group</MuiButton>
      </div>
    </div>

  )
}

export default AddNewGroupFrom
