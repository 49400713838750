import React from 'react'
import './styles.css'
import DifficultyChip from 'src/common/components/DifficultyChip'
import { Divider, Tooltip } from '@mui/material'


const BreachView = ({ item, onShowUser }) => {
  return (
    <div className='row template-view' style={{ width: '450px' }}>
      <div className='template-details'>
        <p className='template-details-title'>{item?.name}</p>
        <Tooltip title={item?.description} arrow>
          {/* to add show more */}
          <p className='template-details-description text-shrink-6'>{item?.description}</p>
        </Tooltip>
        <div className='column template-details-group'>
          <p className='template-details-group-title'>Criticality</p>
          <DifficultyChip level={item?.criticality} />
        </div>
        <div className='column template-details-group'>
          <p className='template-details-group-title'>Impact</p>
          <p className='template-details-group-text'>{item?.impact.join(', ')}</p>
        </div>
        <p className='template-details-title' style={{ fontSize: 20, marginBottom: 20 }}>User info</p>
        <div onClick={() => {
          onShowUser(item)
        }} className='column template-details-group breach-user' style={{ cursor: 'pointer' }}>
          <p className='template-details-group-title'>Name</p>
          <p className='template-details-group-text'>{item?.user.firstName} {item?.user.lastName}</p>
        </div>
        <div className='column template-details-group'>
          <p className='template-details-group-title'>Email</p>
          {/* to remove after demo/ */}
          <p className='template-details-group-text'>{item?.user.email}</p>
        </div>
        <div className='column template-details-group'>
          <p className='template-details-group-title'>Department</p>
          <p className='template-details-group-text'>{item?.user.department}</p>
        </div>

        <Divider sx={{ mb: '24px' }} />

        {/* <div className='column template-details-group'>
          <p className='template-details-group-title'>Phishing email pretended sender hashtag</p>
          <p className='template-details-group-text'>
            {item?.pretendedSenders.join(', ')}
          </p>
        </div> */}
      </div>
    </div>
  )
}

export default BreachView
