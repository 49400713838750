import axios from 'axios';
import authHeader from './auth-header';
import api from './api.service';

const API_URL = window._env_.REACT_APP_API_HOST;

class ThreatsService {
  getThreats() {
    return api.get(API_URL + '/v1/threats', { headers: authHeader() });
  }
  getThreat(id) {
    return axios.get(API_URL + '/v1/threats/' + id, { headers: authHeader() });
  }
  delete(id) {
    return axios.delete(API_URL + '/v1/threats/' + id, { headers: authHeader() });
  }
  update(id, payload) {
    return axios.put(API_URL + '/v1/threats/' + id, payload, { headers: authHeader() });
  }

}

export default new ThreatsService();
