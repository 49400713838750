import React, { useContext } from "react";
import { SortHeader } from "..";
import UserAvatar from "src/common/components/UserAvatar";
import { useState } from "react";
import { useEffect } from "react";
import groupsService from "src/services/groups.service";
import dayjs from "dayjs";
import { PhishingSimStatus } from "./phishingSimStatus";
import { Tooltip } from "@mui/material";
import { UserViewContext } from "src/App";
import utc from "dayjs/plugin/utc"

const PhishingSimTab = ({ groupId }) => {
  dayjs.extend(utc)

  const [sortBy, setSortBy] = useState(null)

  const [simulationsStats, setSimulationsStats] = useState(null)
  const [simulations, setSimulations] = useState(null)

  const [sortedItems, setSortedItems] = useState(null)

  const [, setUserViewId] = useContext(UserViewContext)


  const handleSort = (field) => {
    const fieldset = {
      'name & subject': 'name',
      'status': 'status',
      'date & time': 'actionAt',
    }
    const fieldName = fieldset[field.toLowerCase()]
    const asc = field == sortBy?.name ? !sortBy.asc : true

    setSortBy({ name: field, asc: asc })

    setSortedItems(simulations.map(el => ({ ...el, name: el.firstName + (el?.lastName || '') + el?.subject || '' })).sort((a, b) => {
      if (fieldName == 'actionAt') {
        if (dayjs(a[fieldName]).unix() < dayjs(b[fieldName]).unix()) {
          return (asc) ? -1 : 1;
        }
        if (dayjs(a[fieldName]).unix() > dayjs(b[fieldName]).unix()) {
          return (asc) ? 1 : -1;
        }
        return 0;
      } else {
        if (a[fieldName].toLowerCase().replaceAll(' ', '') < b[fieldName].toLowerCase().replaceAll(' ', '')) {
          return (asc) ? -1 : 1;
        }
        if (a[fieldName].toLowerCase().replaceAll(' ', '') > b[fieldName].toLowerCase().replaceAll(' ', '')) {
          return (asc) ? 1 : -1;
        }
        return 0;
      }
    }))
  }

  useEffect(() => {
    if (groupId) {
      Promise.all([
        groupsService.getGroupsSimulationsStats(groupId),
        groupsService.getGroupsSimulations(groupId),

      ]).then(results => {
        const [statsResp, resp] = results
        setSimulationsStats(statsResp.data)
        setSimulations(resp.data.map((e, i) => ({ ...e, index: i }))) //todo: get rid of index
        setSortedItems(resp.data)
      }).catch(err => console.error(err))
    }
  }, [])

  return (
    <>
      <h2 style={{ fontSize: '24px', fontFamily: 'Satoshi', marginBottom: '20px' }}>Phishing simulations</h2>

      <div style={{ background: '#fff', borderRadius: '10px', padding: '18px 32px', marginBottom: '32px' }} className="row row-center">
        <div className="column" style={{ marginRight: '36px' }}>
          <p style={{ color: '#76767F', fontSize: '14px', marginBottom: '8px' }}>
            Reported
          </p>
          <div style={{ alignItems: 'flex-end' }} className="row">
            <p style={{ color: '#00BC62', fontSize: '24px', fontFamily: 'Satoshi', marginRight: '6px' }}>{simulationsStats && (simulationsStats?.reportedCount === 0 ? 0 : Math.round((simulationsStats?.reportedCount / simulationsStats?.totalCount) * 100))}%</p>
            <p style={{ color: '#76767F', fontSize: '16px' }}>{simulationsStats?.reportedCount}</p>
          </div>
        </div>

        <div className="column" style={{ marginRight: '36px' }}>
          <p style={{ color: '#76767F', fontSize: '14px', marginBottom: '8px' }}>
            Phished
          </p>
          <div style={{ alignItems: 'flex-end' }} className="row">
            <p style={{ fontSize: '24px', fontFamily: 'Satoshi', marginRight: '6px' }}>{simulationsStats && (simulationsStats?.phishedCount === 0 ? 0 : Math.round((simulationsStats?.phishedCount / simulationsStats?.totalCount) * 100))}%</p>
            <p style={{ color: '#76767F', fontSize: '16px' }}>{simulationsStats?.phishedCount}</p>
          </div>
        </div>

        <div className="column" style={{ marginRight: '36px' }}>
          <p style={{ color: '#76767F', fontSize: '14px', marginBottom: '8px' }}>
            Opened
          </p>
          <div style={{ alignItems: 'flex-end' }} className="row">
            <p style={{ fontSize: '24px', fontFamily: 'Satoshi', marginRight: '6px' }}>{simulationsStats?.openedCount ? (simulationsStats?.openedCount === 0 ? 0 : Math.round((simulationsStats?.openedCount / simulationsStats?.totalCount) * 100)) : 0}%</p>
            <p style={{ color: '#76767F', fontSize: '16px' }}>{simulationsStats?.openedCount || 0}</p>
          </div>
        </div>

        <div style={{ flex: '1', position: 'relative' }}>
          <div style={{ background: '#E7E7EE', height: '16px', flex: '1', borderRadius: '16px', overflow: 'hidden' }} className="row row-center">
            {simulationsStats?.reportedCount !== 0 && <div style={{ width: (simulationsStats?.reportedCount === 0 ? 0 : Math.round((simulationsStats?.reportedCount / simulationsStats?.totalCount) * 100)) + '%', background: '#59D399', height: '21px', borderRadius: '16px', position: 'relative', border: '3px solid #fff' }}></div>}
            {simulationsStats?.phishedCount !== 0 && <div style={{ width: (simulationsStats?.phishedCount === 0 ? 0 : Math.round((simulationsStats?.phishedCount / simulationsStats?.totalCount) * 100)) + '%', background: '#EF5E3A', height: '21px', borderRadius: '16px', position: 'relative', border: '3px solid #fff', marginLeft: '-16px' }}></div>}
          </div>
        </div>

      </div>

      <div className='row row-center groups-list-heading'>
        <SortHeader name='Name & Subject' sort={sortBy} onSort={handleSort} width={'55%'} />
        <SortHeader name='Status' sort={sortBy} onSort={handleSort} width={'20%'} />
        <SortHeader name='Date & Time' sort={sortBy} onSort={handleSort} width={'15%'} />
      </div>
      <div style={{ height: 'calc(100vh - 360px)', overflow: 'auto' }}>
        {(sortedItems && sortedItems.length == 0) && <div className="column column-center" style={{ height: '80%', justifyContent: 'center' }} >
          <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M105 33.75H72.4999C71.6886 33.75 70.8991 33.4868 70.25 33L57.25 23.25C56.6009 22.7632 55.8114 22.5 55.0001 22.5H33.75C33.2575 22.5 32.7699 22.597 32.3149 22.7854C31.86 22.9739 31.4466 23.2501 31.0983 23.5983C30.7501 23.9466 30.4739 24.36 30.2854 24.8149C30.097 25.2699 30 25.7575 30 26.25V37.5H40.0001C40.8114 37.5 41.6009 37.7632 42.25 38.25L55.25 48C55.8991 48.4868 56.6886 48.75 57.4999 48.75H90C90.4925 48.75 90.9801 48.847 91.4351 49.0354C91.89 49.2239 92.3034 49.5001 92.6517 49.8483C92.9999 50.1966 93.2761 50.61 93.4646 51.0649C93.653 51.5199 93.75 52.0075 93.75 52.5V82.5H105.417C105.854 82.5 106.288 82.4138 106.692 82.2463C107.097 82.0788 107.464 81.8333 107.774 81.5237C108.083 81.2142 108.329 80.8467 108.496 80.4423C108.664 80.0379 108.75 79.6044 108.75 79.1667V37.5C108.75 37.0075 108.653 36.5199 108.465 36.0649C108.276 35.61 108 35.1966 107.652 34.8483C107.303 34.5001 106.89 34.2239 106.435 34.0354C105.98 33.847 105.492 33.75 105 33.75Z" fill="#E7E7EE" />
            <path d="M93.75 52.5V94.1667C93.75 95.0507 93.3988 95.8986 92.7737 96.5237C92.1486 97.1488 91.3007 97.5 90.4167 97.5H18.75C17.7554 97.5 16.8016 97.1049 16.0984 96.4017C15.3951 95.6984 15 94.7446 15 93.75V41.25C15 40.2554 15.3951 39.3016 16.0984 38.5984C16.8016 37.8951 17.7554 37.5 18.75 37.5H40C40.8114 37.5 41.6009 37.7632 42.25 38.25L55.25 48C55.8991 48.4868 56.6886 48.75 57.5 48.75H90C90.9946 48.75 91.9484 49.1451 92.6517 49.8484C93.3549 50.5516 93.75 51.5054 93.75 52.5Z" stroke="#E7E7EE" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M30 37.5V26.25C30 25.2554 30.3951 24.3016 31.0984 23.5984C31.8016 22.8951 32.7554 22.5 33.75 22.5H55C55.8114 22.5 56.6009 22.7632 57.25 23.25L70.25 33C70.8991 33.4868 71.6886 33.75 72.5 33.75H105C105.995 33.75 106.948 34.1451 107.652 34.8484C108.355 35.5516 108.75 36.5054 108.75 37.5V79.1667C108.75 79.6044 108.664 80.0379 108.496 80.4423C108.329 80.8467 108.083 81.2142 107.774 81.5237C107.464 81.8332 107.097 82.0787 106.692 82.2463C106.288 82.4138 105.854 82.5 105.417 82.5H93.75" stroke="#E7E7EE" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <p style={{ fontSize: 18, opacity: 0.5, fontWeight: 600 }}>No simulations yet</p>
        </div>}

        {sortedItems && sortedItems.map(el => {
          const {
            index,
            actionAt,
            firstName,
            lastName,
            status,
            subject,
            userId,
          } = el

          return (
            <div key={index} className="groups-list-item row row-center">
              <div style={{ width: '55%' }} className="row row-center">
                <UserAvatar onClick={() => {
                  setUserViewId(userId)
                }} style={{ background: "#fff" }} firstName={firstName} lastName={lastName} />
                <div className="column" style={{ maxWidth: '320px' }}>
                  <p onClick={() => setUserViewId(userId)} style={{ color: '#76767F', fontSize: '14px', marginBottom: '6px', cursor: 'pointer' }}>{(firstName || 'unknown') + ' ' + (lastName || 'unknown')}</p>
                  <Tooltip title={subject} arrow>
                    <p className="one-sthing" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{subject}</p>

                  </Tooltip>
                </div>
              </div>

              <PhishingSimStatus status={status} />
              <div style={{ width: '15%', color: '#76767F' }}>{dayjs.utc(actionAt).local().format('D MMM HH:mm')}</div>
            </div>

          )
        })}
      </div>

    </>
  )
}

export default PhishingSimTab