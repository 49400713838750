import React from 'react'

const DifficultyChip = ({ level }) => {
  return (
    <div
      style={{
        background: level == 'HIGH' ? '#FAF0F2' : level == 'MEDIUM' ? 'rgba(228, 163, 1, 0.15)' : '#EBEFFF',
        alignItems: 'center',
        padding: '6px 16px',
        borderRadius: '8px'
      }}
      className='row'
    >
      {level == 'HIGH' ? (
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path opacity='0.2' d='M2 7.5L8 1.5L14 7.5H11V11.5H5V7.5H2Z' fill='#B40020' />
          <path
            d='M2 7.5L8 1.5L14 7.5H11V11.5H5V7.5H2Z'
            stroke='#B40020'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path d='M11 13.5H5' stroke='#B40020' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
      ) : level == 'MEDIUM' ? (
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M2.5 10H13.5' stroke='#E3A202' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M2.5 6H13.5' stroke='#E3A202' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
      ) : (
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path opacity='0.2' d='M13 3L8 8L3 3H13Z' fill='#274AF2' />
          <path d='M13 8L8 13L3 8' stroke='#274AF2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M13 3L8 8L3 3H13Z' stroke='#274AF2' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
      )}
      <p style={{ marginLeft: '8px' }}>{level == 'HIGH' ? 'High' : level == 'MEDIUM' ? 'Medium' : 'Low'}</p>
    </div>
  )
}

export default DifficultyChip
