import { useState } from "react";
import { useEffect } from "react";
import workflowsService from "src/services/workflows.service";

export function useWorkflows() {
  const [item, setItem] = useState(null)
  const [items, setItems] = useState([])
  const [openCreate, setOpenCreate] = useState(false)
  const [loading, setLoading] = useState(true)

  const handleUpdate = () => {
    workflowsService.getWorkflows().then((resp) => {
      setLoading(false)
      setItems(resp.data)
    }).catch(err => {
      console.error(err)
      setLoading(false)
    })
  }

  useEffect(() => {
    handleUpdate()
  }, [])




  return {
    item,
    setItem,
    loading,
    openCreate,
    setOpenCreate,
    items,
    setItems,
    handleUpdate,
  }
}