import React from 'react'

const ExpandButton = () => (
  <svg width='36' height='104' viewBox='0 0 36 104' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      opacity='1'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.2203 23.7847C6.39143 25.8342 0 30.9765 0 38.1063V52V65.8937C0 73.0235 6.39143 78.1658 13.2203 80.2153C22.0692 82.8711 29.4981 90.0813 36 104V52V0C29.4981 13.9187 22.0692 21.1289 13.2203 23.7847Z'
      fill='rgba(150, 150, 197, 0.2)'
    />
  </svg>
)

export default ExpandButton
