import React, { useEffect, useState } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import './App.css'

import Templates from './pages/templates'
import Team from './pages/team'
import DashBoard from './pages/dashboard'

import Nav from './common/nav/Nav'
import LoginPage from './pages/login'
import Microtraining from './pages/microtraining'
import TrainingCards from './pages/training-cards'
import RewardPage from './pages/reward'
import Threats from './pages/threats'
import Overview from './pages/overview'
import TrainingPackages from './pages/training-packages'

import Settings from './pages/settings'
import settingsService from './services/settings.service'
import AuthLinkPage from './pages/auth-link'
import NotFoundPage from './pages/not-found';
import Groups from './pages/groups';
import GoogleRedirectPage from './pages/google-redirect';
import OutlookRedirectPage from './pages/outlook-redirect';
import { parseJwt } from './heplers';
import GoogleRedirectSsoPage from './pages/google-redirect-sso';
import OutlookRedirectSsoPage from './pages/outlook-redirect-sso';
// import Onboarding from './common/components/Onboarding';
import { useQuery } from './hooks';
import authService from './services/auth.service';
import LoadingIndicator from './common/components/LoadingIndicator';
import Workflows from './pages/workflows';
import Breaches from './pages/breaches';
import { Workspace } from './pages/settings/components/workspace';
import { PhishingSimulations } from './pages/settings/components/phishingSimulations';
import { Integrations } from './pages/settings/components/integrations';
import { Members } from './pages/settings/components/members';
import { Alerts } from './pages/settings/components/alerts';
import OutlookWorkflowsRedirectPage from './pages/outlook-workflows-redirect';
import { Drawer } from '@mui/material';
import UserView from './views/UserView';
import SlackRedirectPage from './pages/slack-redirect';
import ManualSimulation from './pages/manual-simulation';
import RegistrationPage from './pages/registration';
import GetStartedPage from './pages/get-started';
import SnackbarCustom from './common/components/SnackbarCustom';
import NewCampaignPage from './pages/new-campaign';
import { EditCampaign } from './pages/edit-campaign';
import { PluginsAndBot } from './pages/settings/components/plugins';
import DashBoardNew from './pages/dashboard-new';
import { PhishingClickRate } from './pages/dashboard-new/pages/phishingClickRate';
import { PhishingMicroTrainingFollowed } from './pages/dashboard-new/pages/phishingMicroTrainingFollowed';
import { RiskyIndividuals } from './pages/dashboard-new/pages/riskyIndividuals';
import { RiskyDepartments } from './pages/dashboard-new/pages/riskyDepartments';

const AppContent = ({ children }) => <div className='app-content'>{children}</div>

export const SettingsContext = React.createContext();
export const UserViewContext = React.createContext();
export const OnboardingContext = React.createContext();
export const SnackBarContext = React.createContext();
export const DashBoardDateContext = React.createContext();

const AppLayout = () => {
  const [settings, setSettings] = useState({ languages: [] });
  const [onboarding, setOnboarding] = useState({ activeStep: 1, done: true, importUsersDone: false, showImport: false });


  const updateOnboarding = (o) => {
    settingsService.setOnboardingStatus({ currentStep: o.activeStep, completedSteps: [] }).then(() => {
      // console.log(resp);
    })
    setOnboarding(o)
  }

  const [userViewId, setUserViewId] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [warningMessage, setWarningMessage] = useState(null)
  const [timePeriod, setTimePeriod] = useState(null)

  useEffect(() => {
    Promise.all([
      settingsService.getlocations(),
      settingsService.getlanguages(),
      settingsService.getdepartments(),
      settingsService.getOnboardingStatus()
    ])
      .then((values) => {
        const [countriesData, languagesData, departmentsData, onboardingData] = values
        const countries = countriesData.data
        const languages = languagesData.data
        const departments = departmentsData.data
        const onboardingStatus = onboardingData.data
        setSettings({ countries, languages, departments })
        setOnboarding({ ...onboarding, activeStep: onboardingStatus.currentStep < 1 ? 1 : onboardingStatus.currentStep, done: onboardingStatus.currentStep == 4 })
      })

  }, [])

  return (
    <div className='app-layout'>
      <SettingsContext.Provider value={settings}>
        <OnboardingContext.Provider value={[onboarding, updateOnboarding]}>
          <UserViewContext.Provider value={[userViewId, setUserViewId]}>
            <SnackBarContext.Provider value={{ setSuccessMessage, setErrorMessage, setWarningMessage }}>
              {/* <Onboarding /> */}
              <Nav />
              <DashBoardDateContext.Provider value={[timePeriod, setTimePeriod]}>
                <AppContent>
                  <Outlet />
                </AppContent>
              </DashBoardDateContext.Provider>
              <Drawer anchor='right' open={!!userViewId} sx={{ zIndex: 1300 }}
                onClose={() => {
                  setUserViewId(null)
                  // setShowUsersDialog(true)
                }}>
                <div
                  style={{ position: 'fixed', top: '24px', right: '1042px', cursor: 'pointer' }}
                  onClick={() => {
                    setUserViewId(null)
                    // setShowUsersDialog(true)
                  }}
                >
                  <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M37.5 10.5L10.5 37.5'
                      stroke='white'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M37.5 37.5L10.5 10.5'
                      stroke='white'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
                {userViewId &&
                  <UserView userID={userViewId} />
                }
              </Drawer>
            </SnackBarContext.Provider>
          </UserViewContext.Provider>

        </OnboardingContext.Provider>

        <SnackbarCustom
          successMessage={successMessage}
          warningMessage={warningMessage}
          errorMessage={errorMessage}
          onClose={() => {
            setSuccessMessage(null)
            setWarningMessage(null)
            setErrorMessage(null)
          }}
        />
      </SettingsContext.Provider>
    </div >
  )
}



function RequireAuth({ children }) {
  let auth = localStorage.getItem('user');
  // const userRole = auth ? parseJwt(JSON.parse(auth).accessToken).role : ''
  const exp = auth ? parseJwt(JSON.parse(auth).accessToken).exp : ''

  const currentDate = new Date();
  const timestamp = currentDate.getTime();

  let location = useLocation();

  const query = useQuery()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(!auth)
  const [authirized, setAuthirized] = useState(true)

  useEffect(() => {
    if (query.get('token')) {
      setLoading(true)
      authService.validateToken(query.get('token')).then(() => {
        setLoading(false)
        setAuthirized(true)
        navigate(location.pathname)
      }).catch((err) => {
        setLoading(false)
        console.error(err);
        setAuthirized(false);
      })
    }

    if (exp ? exp < timestamp / 1000 : !exp) {
      localStorage.removeItem('user')
      setAuthirized(false);
      navigate('/login')

    }

  }, [])

  return (<>
    {loading ? <LoadingIndicator /> : <>
      {authirized && children}
      {!authirized && authirized !== null && <Navigate to="/login" state={{ from: location }} replace />}
    </>}
  </>);
}

function App() {
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Routes>
          <Route path='*' element={<NotFoundPage />} />
          <Route path='/' element={<RequireAuth><AppLayout /></RequireAuth>}>
            <Route index element={<DashBoardNew />} />
            <Route path='/get-started' element={<GetStartedPage />} />
            <Route path='/phishing-click-rate' element={<PhishingClickRate />} />
            <Route path='/risky-individuals' element={<RiskyIndividuals />} />
            <Route path='/risky-departments' element={<RiskyDepartments />} />
            <Route path='/phishing-micro-training-followed' element={<PhishingMicroTrainingFollowed />} />
            <Route path='/team' element={<Team />} />
            <Route path='/groups' element={<Groups />} />
            <Route path='/threats' element={<Threats />} />
            <Route path='/workflows' element={<Workflows />} />

            <Route path='/overview' element={<Overview />} />
            <Route path='/templates' element={<Templates />} />
            <Route path='/manual-simulation' element={<ManualSimulation />} />

            <Route path='/new-campaign' element={<NewCampaignPage />} />
            <Route path='/edit-campaign' element={<EditCampaign />} />

            <Route path='/breaches' element={<Breaches />} />

            <Route path='/microtraining' element={<Microtraining />} />
            <Route path='/training-packages' element={<TrainingPackages />} />
            <Route path='/settings' element={<Settings />} >
              <Route index element={<Workspace />} />
              <Route path='/settings/phishing-simulations' element={<PhishingSimulations />} />
              <Route path='/settings/integrations' element={<Integrations />} />
              <Route path='/settings/plugins' element={<PluginsAndBot />} />
              <Route path='/settings/alerts' element={<Alerts />} />
              <Route path='/settings/members' element={<Members />} />
            </Route>
          </Route>

          <Route path='/login' element={<LoginPage />} />
          <Route path='/registration' element={<RegistrationPage />} />

          <Route path='/auth-link' element={<AuthLinkPage />} />

          <Route path='/google-redirect' element={<GoogleRedirectPage />} />
          <Route path='/outlook-redirect' element={<OutlookRedirectPage />} />
          <Route path='/slack-redirect' element={<SlackRedirectPage />} />

          <Route path='/outlook-workflows-redirect' element={<OutlookWorkflowsRedirectPage />} />

          <Route path='/google-sso-redirect' element={<GoogleRedirectSsoPage />} />
          <Route path='/outlook-sso-redirect' element={<OutlookRedirectSsoPage />} />

          <Route path='/cards' element={<TrainingCards />} />
          <Route path='/reward' element={<RewardPage />} />
          <Route path='/user' element={<LoginPage />} />
        </Routes>
      </LocalizationProvider>

    </div >
  )
}

export default App
