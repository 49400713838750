import React from 'react'

const EditIcon = ({ disabled, color = '#6123FF', size = '25' }) => (
  <svg width={size} height={size} viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      opacity='0.2'
      d='M13.25 6.00184L18.5 11.2518L20.9697 8.78217C21.1103 8.64152 21.1893 8.45075 21.1893 8.25184C21.1893 8.05293 21.1103 7.86216 20.9697 7.72151L16.7803 3.53217C16.6397 3.39152 16.4489 3.3125 16.25 3.3125C16.0511 3.3125 15.8603 3.39152 15.7197 3.53217L13.25 6.00184Z'
      fill={disabled ? '#a1a1a1' : color}
    />
    <path d='M13.25 6L18.5 11.25' stroke={disabled ? '#a1a1a1' : color} strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M9.5 20.2518H5C4.80109 20.2518 4.61032 20.1728 4.46967 20.0322C4.32902 19.8915 4.25 19.7008 4.25 19.5018V15.3125C4.25 15.214 4.2694 15.1165 4.30709 15.0255C4.34478 14.9345 4.40003 14.8518 4.46967 14.7822L15.7197 3.53217C15.8603 3.39152 16.0511 3.3125 16.25 3.3125C16.4489 3.3125 16.6397 3.39152 16.7803 3.53217L20.9697 7.72151C21.1103 7.86216 21.1893 8.05293 21.1893 8.25184C21.1893 8.45075 21.1103 8.64152 20.9697 8.78217L9.5 20.2518Z'
      stroke={disabled ? '#a1a1a1' : color}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default EditIcon
