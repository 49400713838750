export const xlsAttach = (name = '') => {
  return (`<a title="${name}.xlsx" data-filename="${name}.xlsx.end_file_name" style="color: #464646; text-decoration: none;" href="[phishingLink]">
  <table border="0" cellpadding="0" cellspacing="0" width="260px">
    <tr>
      <td style="border: 1px solid #f0f0f0; border-radius: 4px;">
        <table border="0" cellpadding="0" cellspacing="0" width="100%">
          <tr>
          <td style="padding: 10px; width: 20px">
          <img width="20px" src="https://storage.googleapis.com/prod_simulation-templates-images/common/email-attach-file-xlsx.png" alt="xls-icon" style="vertical-align: middle;" />

          </td>
            <td style="padding: 10px;">
              <span style="font-size: 14px; color: #464646; display: inline-block; max-width: 143px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">${name}.xlsx</span>
              <span style="font-size: 10px; font-weight: 600; color: #808080; display: block;">0.3 MB</span>
            </td>
            <td style="padding: 10px; text-align: center;">
              <img width="24px" src="https://storage.googleapis.com/prod_simulation-templates-images/common/email-attach-file-action.png" alt="actions" style="vertical-align: middle;" />
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</a>
`)
}


export const pdfAttach = (name = '') => {
  return (`<a title="${name}.pdf" data-filename="${name}.pdf.end_file_name" style="color: #464646; text-decoration: none;" href="[phishingLink]">
  <table border="0" cellpadding="0" cellspacing="0" width="260px">
    <tr>
      <td style="border: 1px solid #f0f0f0; border-radius: 4px;">
        <table border="0" cellpadding="0" cellspacing="0" width="100%">
          <tr>
          <td style="padding: 10px; width: 20px">
          <img width="20px" src="https://storage.googleapis.com/prod_simulation-templates-images/common/email-attach-file-pdf.png" alt="xls-icon" style="vertical-align: middle;" />

          </td>
            <td style="padding: 10px;">
              <span style="font-size: 14px; color: #464646; display: inline-block; max-width: 143px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">${name}.pdf</span>
              <span style="font-size: 10px; font-weight: 600; color: #808080; display: block;">0.3 MB</span>
            </td>
            <td style="padding: 10px; text-align: center;">
              <img width="24px" src="https://storage.googleapis.com/prod_simulation-templates-images/common/email-attach-file-action.png" alt="actions" style="vertical-align: middle;" />
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</a>
`)
}