import React from "react";

export const FailRateIcon = ({ variant }) => {
  if (variant >= 50) {
    return (
      <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_2359_200525" maskUnits="userSpaceOnUse" x="2" y="0" width="16" height="17">
          <path fillRule="evenodd" clipRule="evenodd" d="M14.2808 0.582032H5.70237H5.70236C5.23233 0.58202 4.83203 0.58201 4.50336 0.606332C4.15812 0.631879 3.82216 0.687828 3.50106 0.836057C3.01682 1.0596 2.62358 1.41625 2.37706 1.8546C2.21348 2.14547 2.15171 2.44971 2.1235 2.76246C2.09666 3.06016 2.09667 3.42274 2.09668 3.84858V3.84858V7.06346C2.09668 12.7853 6.89183 15.3346 8.84076 16.1339L8.84407 16.1352C9.05724 16.2227 9.23118 16.294 9.5564 16.3446C9.69534 16.3662 9.86452 16.3715 9.99174 16.3715C10.119 16.3715 10.2881 16.3662 10.4271 16.3446C10.7509 16.2942 10.924 16.2232 11.135 16.1367L11.1385 16.1352L11.1418 16.1339C13.0907 15.3347 17.8862 12.7853 17.8862 7.06347V3.84596V3.84591C17.8862 3.42096 17.8862 3.05895 17.8594 2.76165C17.8312 2.44927 17.7694 2.14527 17.606 1.8546C17.3592 1.41577 16.9651 1.05935 16.4814 0.836057C16.1604 0.68787 15.8247 0.631894 15.4795 0.606335C15.151 0.58201 14.7509 0.58202 14.2808 0.582032Z" fill="white" />
        </mask>
        <g mask="url(#mask0_2359_200525)">
          <path fillRule="evenodd" clipRule="evenodd" d="M14.2808 0.582032H5.70237H5.70236C5.23233 0.58202 4.83203 0.58201 4.50336 0.606332C4.15812 0.631879 3.82216 0.687828 3.50106 0.836057C3.01682 1.0596 2.62358 1.41625 2.37706 1.8546C2.21348 2.14547 2.15171 2.44971 2.1235 2.76246C2.09666 3.06016 2.09667 3.42274 2.09668 3.84858V3.84858V7.06346C2.09668 12.7853 6.89183 15.3346 8.84076 16.1339L8.84407 16.1352C9.05724 16.2227 9.23118 16.294 9.5564 16.3446C9.69534 16.3662 9.86452 16.3715 9.99174 16.3715C10.119 16.3715 10.2881 16.3662 10.4271 16.3446C10.7509 16.2942 10.924 16.2232 11.135 16.1367L11.1385 16.1352L11.1418 16.1339C13.0907 15.3347 17.8862 12.7853 17.8862 7.06347V3.84596V3.84591C17.8862 3.42096 17.8862 3.05895 17.8594 2.76165C17.8312 2.44927 17.7694 2.14527 17.606 1.8546C17.3592 1.41577 16.9651 1.05935 16.4814 0.836057C16.1604 0.68787 15.8247 0.631894 15.4795 0.606335C15.151 0.58201 14.7509 0.58202 14.2808 0.582032Z" fill="#F42525" />
          <path fillRule="evenodd" clipRule="evenodd" d="M14.2808 0.582032H5.70237H5.70236C5.23233 0.58202 4.83203 0.58201 4.50336 0.606332C4.15812 0.631879 3.82216 0.687828 3.50106 0.836057C3.01682 1.0596 2.62358 1.41625 2.37706 1.8546C2.21348 2.14547 2.15171 2.44971 2.1235 2.76246C2.09666 3.06016 2.09667 3.42274 2.09668 3.84858V3.84858V7.06346C2.09668 12.7853 6.89183 15.3346 8.84076 16.1339L8.84407 16.1352C9.05724 16.2227 9.23118 16.294 9.5564 16.3446C9.69534 16.3662 9.86452 16.3715 9.99174 16.3715C10.119 16.3715 10.2881 16.3662 10.4271 16.3446C10.7509 16.2942 10.924 16.2232 11.135 16.1367L11.1385 16.1352L11.1418 16.1339C13.0907 15.3347 17.8862 12.7853 17.8862 7.06347V3.84596V3.84591C17.8862 3.42096 17.8862 3.05895 17.8594 2.76165C17.8312 2.44927 17.7694 2.14527 17.606 1.8546C17.3592 1.41577 16.9651 1.05935 16.4814 0.836057C16.1604 0.68787 15.8247 0.631894 15.4795 0.606335C15.151 0.58201 14.7509 0.58202 14.2808 0.582032Z" fill="url(#paint0_linear_2359_200525)" />
          <path fillRule="evenodd" clipRule="evenodd" d="M14.2808 0.582032H5.70237H5.70236C5.23233 0.58202 4.83203 0.58201 4.50336 0.606332C4.15812 0.631879 3.82216 0.687828 3.50106 0.836057C3.01682 1.0596 2.62358 1.41625 2.37706 1.8546C2.21348 2.14547 2.15171 2.44971 2.1235 2.76246C2.09666 3.06016 2.09667 3.42274 2.09668 3.84858V3.84858V7.06346C2.09668 12.7853 6.89183 15.3346 8.84076 16.1339L8.84407 16.1352C9.05724 16.2227 9.23118 16.294 9.5564 16.3446C9.69534 16.3662 9.86452 16.3715 9.99174 16.3715C10.119 16.3715 10.2881 16.3662 10.4271 16.3446C10.7509 16.2942 10.924 16.2232 11.135 16.1367L11.1385 16.1352L11.1418 16.1339C13.0907 15.3347 17.8862 12.7853 17.8862 7.06347V3.84596V3.84591C17.8862 3.42096 17.8862 3.05895 17.8594 2.76165C17.8312 2.44927 17.7694 2.14527 17.606 1.8546C17.3592 1.41577 16.9651 1.05935 16.4814 0.836057C16.1604 0.68787 15.8247 0.631894 15.4795 0.606335C15.151 0.58201 14.7509 0.58202 14.2808 0.582032Z" fill="url(#paint1_linear_2359_200525)" />
          <path fillRule="evenodd" clipRule="evenodd" d="M14.2808 0.582032H5.70237H5.70236C5.23233 0.58202 4.83203 0.58201 4.50336 0.606332C4.15812 0.631879 3.82216 0.687828 3.50106 0.836057C3.01682 1.0596 2.62358 1.41625 2.37706 1.8546C2.21348 2.14547 2.15171 2.44971 2.1235 2.76246C2.09666 3.06016 2.09667 3.42274 2.09668 3.84858V3.84858V7.06346C2.09668 12.7853 6.89183 15.3346 8.84076 16.1339L8.84407 16.1352C9.05724 16.2227 9.23118 16.294 9.5564 16.3446C9.69534 16.3662 9.86452 16.3715 9.99174 16.3715C10.119 16.3715 10.2881 16.3662 10.4271 16.3446C10.7509 16.2942 10.924 16.2232 11.135 16.1367L11.1385 16.1352L11.1418 16.1339C13.0907 15.3347 17.8862 12.7853 17.8862 7.06347V3.84596V3.84591C17.8862 3.42096 17.8862 3.05895 17.8594 2.76165C17.8312 2.44927 17.7694 2.14527 17.606 1.8546C17.3592 1.41577 16.9651 1.05935 16.4814 0.836057C16.1604 0.68787 15.8247 0.631894 15.4795 0.606335C15.151 0.58201 14.7509 0.58202 14.2808 0.582032Z" fill="url(#paint2_linear_2359_200525)" />
          <g opacity="0.3">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.0383 10.5318L2.15759 -11.2617H-10.5337L5.1257 26.2383H18.94L12.0383 10.5318Z" fill="url(#paint3_linear_2359_200525)" />
          </g>
        </g>
        <defs>
          <linearGradient id="paint0_linear_2359_200525" x1="15.8431" y1="-13.0693" x2="4.138" y2="-12.0282" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F5C51B" />
            <stop offset="1" stopColor="#F57D1B" />
          </linearGradient>
          <linearGradient id="paint1_linear_2359_200525" x1="15.8431" y1="-13.0693" x2="4.138" y2="-12.0282" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF942E" />
            <stop offset="1" stopColor="#FF4D2E" />
          </linearGradient>
          <linearGradient id="paint2_linear_2359_200525" x1="15.8431" y1="-13.0693" x2="4.138" y2="-12.0282" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F5C51B" />
            <stop offset="1" stopColor="#F57D1B" />
          </linearGradient>
          <linearGradient id="paint3_linear_2359_200525" x1="15.1263" y1="-43.6836" x2="-6.78899" y2="-42.1515" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F5C51B" />
            <stop offset="1" stopColor="#F57D1B" />
          </linearGradient>
        </defs>
      </svg>

    )
  } else {
    return (
      <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_2359_200327" maskUnits="userSpaceOnUse" x="2" y="0" width="16" height="17">
          <path fillRule="evenodd" clipRule="evenodd" d="M14.2808 0.582032H5.70237H5.70236C5.23233 0.58202 4.83203 0.58201 4.50336 0.606332C4.15812 0.631879 3.82216 0.687828 3.50106 0.836057C3.01682 1.0596 2.62358 1.41625 2.37706 1.8546C2.21348 2.14547 2.15171 2.44971 2.1235 2.76246C2.09666 3.06016 2.09667 3.42274 2.09668 3.84858V3.84858V7.06346C2.09668 12.7853 6.89183 15.3346 8.84076 16.1339L8.84407 16.1352C9.05724 16.2227 9.23118 16.294 9.5564 16.3446C9.69534 16.3662 9.86452 16.3715 9.99174 16.3715C10.119 16.3715 10.2881 16.3662 10.4271 16.3446C10.7509 16.2942 10.924 16.2232 11.135 16.1367L11.1385 16.1352L11.1418 16.1339C13.0907 15.3347 17.8862 12.7853 17.8862 7.06347V3.84596V3.84591C17.8862 3.42096 17.8862 3.05895 17.8594 2.76165C17.8312 2.44927 17.7694 2.14527 17.606 1.8546C17.3592 1.41577 16.9651 1.05935 16.4814 0.836057C16.1604 0.68787 15.8247 0.631894 15.4795 0.606335C15.151 0.58201 14.7509 0.58202 14.2808 0.582032Z" fill="white" />
        </mask>
        <g mask="url(#mask0_2359_200327)">
          <path fillRule="evenodd" clipRule="evenodd" d="M14.2808 0.582032H5.70237H5.70236C5.23233 0.58202 4.83203 0.58201 4.50336 0.606332C4.15812 0.631879 3.82216 0.687828 3.50106 0.836057C3.01682 1.0596 2.62358 1.41625 2.37706 1.8546C2.21348 2.14547 2.15171 2.44971 2.1235 2.76246C2.09666 3.06016 2.09667 3.42274 2.09668 3.84858V3.84858V7.06346C2.09668 12.7853 6.89183 15.3346 8.84076 16.1339L8.84407 16.1352C9.05724 16.2227 9.23118 16.294 9.5564 16.3446C9.69534 16.3662 9.86452 16.3715 9.99174 16.3715C10.119 16.3715 10.2881 16.3662 10.4271 16.3446C10.7509 16.2942 10.924 16.2232 11.135 16.1367L11.1385 16.1352L11.1418 16.1339C13.0907 15.3347 17.8862 12.7853 17.8862 7.06347V3.84596V3.84591C17.8862 3.42096 17.8862 3.05895 17.8594 2.76165C17.8312 2.44927 17.7694 2.14527 17.606 1.8546C17.3592 1.41577 16.9651 1.05935 16.4814 0.836057C16.1604 0.68787 15.8247 0.631894 15.4795 0.606335C15.151 0.58201 14.7509 0.58202 14.2808 0.582032Z" fill="url(#paint0_linear_2359_200327)" />
          <g opacity="0.4">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.5729 10.7935L1.69226 -11H-10.999L4.66037 26.5H18.4747L11.5729 10.7935Z" fill="url(#paint1_linear_2359_200327)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.5729 10.7935L1.69226 -11H-10.999L4.66037 26.5H18.4747L11.5729 10.7935Z" fill="url(#paint2_linear_2359_200327)" />
          </g>
        </g>
        <defs>
          <linearGradient id="paint0_linear_2359_200327" x1="16.001" y1="-6" x2="6.5395" y2="-5.82789" gradientUnits="userSpaceOnUse">
            <stop stopColor="#31C682" />
            <stop offset="1" stopColor="#03DB74" />
          </linearGradient>
          <linearGradient id="paint1_linear_2359_200327" x1="14.661" y1="-43.4219" x2="-7.25432" y2="-41.8898" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F5C51B" />
            <stop offset="1" stopColor="#CDF2E2" />
          </linearGradient>
          <linearGradient id="paint2_linear_2359_200327" x1="14.661" y1="-43.4219" x2="-7.25432" y2="-41.8898" gradientUnits="userSpaceOnUse">
            <stop stopColor="#00BA97" />
            <stop offset="1" stopColor="#00BC62" />
          </linearGradient>
        </defs>
      </svg>

    )
  }
}