import React from 'react'

const StatusChip = ({ value, style }) => {
  return (
    <div
      style={{
        alignItems: 'center',
        padding: '6px 16px',
        borderRadius: '8px',
        ...style
      }}
      className='row'
    >
      {value === 'ACTIVE' && (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            opacity='0.2'
            d='M12 16.5C14.4853 16.5 16.5 14.4853 16.5 12C16.5 9.51472 14.4853 7.5 12 7.5C9.51472 7.5 7.5 9.51472 7.5 12C7.5 14.4853 9.51472 16.5 12 16.5Z'
            fill='#00BC62'
          />
          <path d='M12 12L21 3' stroke='#00BC62' strokeLinecap='round' strokeLinejoin='round' />
          <path
            d='M18.3641 5.63499C16.7698 4.04388 14.6364 3.10909 12.386 3.01557C10.1355 2.92205 7.93192 3.67661 6.21114 5.12996C4.49037 6.58331 3.37776 8.62958 3.09343 10.864C2.80911 13.0983 3.37377 15.3581 4.67571 17.1961C5.97765 19.034 7.92203 20.3164 10.1242 20.7895C12.3263 21.2626 14.6258 20.8919 16.5678 19.7508C18.5097 18.6097 19.9526 16.7812 20.611 14.6272C21.2695 12.4732 21.0954 10.1505 20.1234 8.11868'
            stroke='#00BC62'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M15.1817 8.81792C14.4378 8.07399 13.4566 7.61426 12.4089 7.51863C11.3611 7.42301 10.313 7.69754 9.44665 8.2945C8.5803 8.89147 7.95052 9.77313 7.66676 10.7862C7.383 11.7994 7.4632 12.8799 7.89343 13.84C8.32366 14.8001 9.07671 15.5791 10.0217 16.0417C10.9667 16.5042 12.0439 16.6209 13.066 16.3717C14.0882 16.1224 14.9907 15.5229 15.6166 14.6772C16.2426 13.8316 16.5525 12.7934 16.4924 11.743'
            stroke='#00BC62'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      )}

      {value === 'INACTIVE' && (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z'
            stroke='#B40020'
            strokeMiterlimit='10'
          />
          <path d='M15 9L9 15' stroke='#B40020' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M15 15L9 9' stroke='#B40020' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
      )}

      <p style={{ marginLeft: '8px' }}>
        {value === 'ACTIVE' && 'Active'}
        {value === 'INACTIVE' && 'Inactive'}
      </p>
    </div >
  )
}

export default StatusChip
