import React from "react";
import Spacer from "src/common/components/Spacer";

import './styles.css'
import { highlightSearch } from "src/heplers";
import Highlighter from "react-highlight-words";
import { Tooltip } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

export const THREAT_TYPES = {
  spam: 'SPAM',
  fraudlent: 'FRAUDULENT',
  safe: 'SAFE',
}

export const ThreatIcon = ({ type }) => {
  switch (type) {
    case THREAT_TYPES.spam:
      return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.1" d="M25.3125 11.25V22.5C25.3125 22.7486 25.2137 22.9871 25.0379 23.1629C24.8621 23.3387 24.6236 23.4375 24.375 23.4375H5.625C5.37636 23.4375 5.1379 23.3387 4.96209 23.1629C4.78627 22.9871 4.6875 22.7486 4.6875 22.5V11.25H25.3125Z" fill="#E3A202" />
          <path d="M26.25 6.5625H3.75C3.23223 6.5625 2.8125 6.98223 2.8125 7.5V10.3125C2.8125 10.8303 3.23223 11.25 3.75 11.25H26.25C26.7678 11.25 27.1875 10.8303 27.1875 10.3125V7.5C27.1875 6.98223 26.7678 6.5625 26.25 6.5625Z" stroke="#E3A202" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M25.3125 11.25V22.5C25.3125 22.7486 25.2137 22.9871 25.0379 23.1629C24.8621 23.3387 24.6236 23.4375 24.375 23.4375H5.625C5.37636 23.4375 5.1379 23.3387 4.96209 23.1629C4.78627 22.9871 4.6875 22.7486 4.6875 22.5V11.25" stroke="#E3A202" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M12.1875 15.9375H17.8125" stroke="#E3A202" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

      )
    case THREAT_TYPES.fraudlent:
      return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.1" d="M15 18.75C17.0711 18.75 18.75 17.0711 18.75 15C18.75 12.9289 17.0711 11.25 15 11.25C12.9289 11.25 11.25 12.9289 11.25 15C11.25 17.0711 12.9289 18.75 15 18.75Z" fill="#B40020" />
          <path d="M15 25.3125C20.6954 25.3125 25.3125 20.6954 25.3125 15C25.3125 9.30456 20.6954 4.6875 15 4.6875C9.30456 4.6875 4.6875 9.30456 4.6875 15C4.6875 20.6954 9.30456 25.3125 15 25.3125Z" stroke="#B40020" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M15 2.34375V7.03125" stroke="#B40020" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M2.34375 15H7.03125" stroke="#B40020" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M15 27.6562V22.9688" stroke="#B40020" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M27.6562 15H22.9688" stroke="#B40020" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M15 18.75C17.0711 18.75 18.75 17.0711 18.75 15C18.75 12.9289 17.0711 11.25 15 11.25C12.9289 11.25 11.25 12.9289 11.25 15C11.25 17.0711 12.9289 18.75 15 18.75Z" stroke="#B40020" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

      )
    case THREAT_TYPES.safe:
      return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.1" d="M4.6875 12.5V5.625C4.6875 5.37636 4.78627 5.1379 4.96209 4.96209C5.1379 4.78627 5.37636 4.6875 5.625 4.6875H24.375C24.6236 4.6875 24.8621 4.78627 25.0379 4.96209C25.2137 5.1379 25.3125 5.37636 25.3125 5.625V12.5C25.3125 22.3455 16.9563 25.6074 15.2878 26.1606C15.1013 26.2248 14.8987 26.2248 14.7122 26.1606C13.0437 25.6074 4.6875 22.3455 4.6875 12.5Z" fill="#00BC62" />
          <path d="M4.6875 12.5V5.625C4.6875 5.37636 4.78627 5.1379 4.96209 4.96209C5.1379 4.78627 5.37636 4.6875 5.625 4.6875H24.375C24.6236 4.6875 24.8621 4.78627 25.0379 4.96209C25.2137 5.1379 25.3125 5.37636 25.3125 5.625V12.5C25.3125 22.3455 16.9563 25.6074 15.2878 26.1606C15.1013 26.2248 14.8987 26.2248 14.7122 26.1606C13.0437 25.6074 4.6875 22.3455 4.6875 12.5Z" stroke="#00BC62" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M20.1562 11.25L13.2812 17.8125L9.84375 14.5312" stroke="#00BC62" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      )

    default:
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.1" d="M5.10571 18.8943C4.24283 18.0314 4.81514 16.2198 4.37595 15.1584C3.92066 14.058 2.25 13.1723 2.25 12C2.25 10.8276 3.92067 9.942 4.37595 8.84164C4.81515 7.78015 4.24283 5.96858 5.10571 5.10571C5.96858 4.24283 7.78016 4.81514 8.84165 4.37595C9.94203 3.92066 10.8277 2.25 12 2.25C13.1724 2.25 14.058 3.92067 15.1584 4.37595C16.2199 4.81515 18.0314 4.24283 18.8943 5.10571C19.7572 5.96858 19.1849 7.78016 19.6241 8.84165C20.0793 9.94203 21.75 10.8277 21.75 12C21.75 13.1724 20.0793 14.058 19.624 15.1584C19.1848 16.2199 19.7572 18.0314 18.8943 18.8943C18.0314 19.7572 16.2198 19.1849 15.1584 19.6241C14.058 20.0793 13.1723 21.75 12 21.75C10.8276 21.75 9.942 20.0793 8.84164 19.624C7.78015 19.1848 5.96858 19.7572 5.10571 18.8943Z" fill="#76767F" />
          <path d="M5.10571 18.8943C4.24283 18.0314 4.81514 16.2198 4.37595 15.1584C3.92066 14.058 2.25 13.1723 2.25 12C2.25 10.8276 3.92067 9.942 4.37595 8.84164C4.81515 7.78015 4.24283 5.96858 5.10571 5.10571C5.96858 4.24283 7.78016 4.81514 8.84165 4.37595C9.94203 3.92066 10.8277 2.25 12 2.25C13.1724 2.25 14.058 3.92067 15.1584 4.37595C16.2199 4.81515 18.0314 4.24283 18.8943 5.10571C19.7572 5.96858 19.1849 7.78016 19.6241 8.84165C20.0793 9.94203 21.75 10.8277 21.75 12C21.75 13.1724 20.0793 14.058 19.624 15.1584C19.1848 16.2199 19.7572 18.0314 18.8943 18.8943C18.0314 19.7572 16.2198 19.1849 15.1584 19.6241C14.058 20.0793 13.1723 21.75 12 21.75C10.8276 21.75 9.942 20.0793 8.84164 19.624C7.78015 19.1848 5.96858 19.7572 5.10571 18.8943Z" stroke="#76767F" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M12 18C12.6213 18 13.125 17.4963 13.125 16.875C13.125 16.2537 12.6213 15.75 12 15.75C11.3787 15.75 10.875 16.2537 10.875 16.875C10.875 17.4963 11.3787 18 12 18Z" fill="#76767F" />
          <path d="M12 13.5V12.75C12.5192 12.75 13.0267 12.596 13.4584 12.3076C13.8901 12.0192 14.2265 11.6092 14.4252 11.1295C14.6239 10.6499 14.6758 10.1221 14.5746 9.61289C14.4733 9.10369 14.2233 8.63596 13.8562 8.26885C13.489 7.90173 13.0213 7.65173 12.5121 7.55044C12.0029 7.44915 11.4751 7.50114 10.9955 7.69982C10.5158 7.8985 10.1058 8.23495 9.81739 8.66663C9.52895 9.09831 9.375 9.60583 9.375 10.125" stroke="#76767F" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

      )
  }
}

const ThreatListItem = ({ item, selected, onSelect, search }) => {
  dayjs.extend(utc)
  const { id, subject, markAs } = item
  return (
    <div
      onClick={() => onSelect(item.id)}
      className={`row row-center threat-list-item ${selected && 'threat-list-item-selected'}`}
    >
      <div className={`threat-list-item-icon ${markAs && 'threat-list-item-icon-marked'}`}>
        <ThreatIcon type={markAs} />
      </div>
      <div className="column" style={{ maxWidth: 'calc(100% - 160px)' }}>
        <Tooltip arrow title={subject}>
          <p className="threat-list-item-title">
            <Highlighter
              highlightClassName="highlight-text"
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={subject}
            />

          </p>
        </Tooltip>
        {markAs &&
          <p className={
            `threat-list-item-marked_as 
            ${markAs == THREAT_TYPES.fraudlent && 'marked_as_fraudlent'}
            ${markAs == THREAT_TYPES.safe && 'marked_as_safe'}
            `}>Marked as {markAs.toLowerCase()}</p>
        }
      </div>
      <Spacer style={{ minWidth: '10px' }} />
      <p className="threat-list-item-date">{dayjs.utc(item?.createdAt).local().format('D MMM HH:mm')}</p>
    </div>
  )
}

export default ThreatListItem